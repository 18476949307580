import "@app/products/animals/components/forms/components/form-element/_index.scss";
import { ComboboxDDSearchAPI } from "@app/products/direct-debit/components/fields/combobox-search-api";
import { IntegrationModuleItemType } from "@app/products/direct-debit/system-admin/model";
import { DDModuleItemTypeLOVs } from "@app/products/direct-debit/system-admin/module-item-types/model";
import { REGISTER_LOOKUP_API_URL } from "@app/products/direct-debit/system-admin/module-item-types/[id]/components/general/components/form-element/constant";
import { stringLengthValidator } from "@app/products/direct-debit/system-admin/util";
import { APIResponse } from "@common/apis/model";
import { NUMBER_FORMAT } from "@common/constants/common-format";
import { ResponsePacket } from "@common/models/identityPacket";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import {
  Field,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React from "react";

export interface IDDModuleItemTypeFormElementProps {
  isInactive?: boolean;
  formRenderProps: FormRenderProps;
}

const nameOf = nameOfFactory<IntegrationModuleItemType>();

const nameValidator = (value: any) => stringLengthValidator(value, 1, 50);

const itemTypeIdValidator = (value: any) => {
  const SQL_SERVER_SHORT_MAX = 32767;

  if (isNil(value) || value?.length === 0) {
    return "This field is required.";
  }
  if (value <= 0 || value > SQL_SERVER_SHORT_MAX) {
    return "Invalid item Type ID and Maximum is 32767.";
  }
  return "";
};

export const DDModuleItemTypeFormElement = observer(
  ({
    formRenderProps,
    isInactive = false,
  }: IDDModuleItemTypeFormElementProps) => {
    const { valueGetter, onChange } = formRenderProps;
    const getValue = (nameOfField: keyof IntegrationModuleItemType) =>
      valueGetter(nameOf(nameOfField));
    const onChangeField = (nameOfField: string, value: any) => {
      onChange(nameOf(nameOfField as keyof IntegrationModuleItemType), {
        value: value,
      });
    };

    const { pushNotificationPortal } = useNotificationPortalStore();
    const { dataForms } = useFlexibleFormStore();
    const ddAccountsLOVs = dataForms?.GeneralFormLovs as DDModuleItemTypeLOVs;

    const handleModuleTypeChange = async (event: ComboBoxChangeEvent) => {
      onChangeField("Integration_Module_Type_ENUM", event.target.value?.Key);
      onChangeField("Integration_Module_Type_Name", event.target.value?.Value);
    };

    const handleRegisterIdChange = async (event: ComboBoxChangeEvent) => {
      onChangeField("Register_ID", event.target.value?.Register_Id);
    };

    return (
      <FormElement id="cc-form-manage-page-general">
        <section className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <CCLabel title="Name" isMandatory />
              <Field
                component={CCInput}
                name={nameOf("Name")}
                disabled={isInactive}
                validator={nameValidator}
              />
            </div>
            <div className="cc-field">
              <CCLabel title="Integration module type" isMandatory />
              <Field
                name={nameOf("Integration_Module_Type_ENUM")}
                dataItemKey="Key"
                textField="Value"
                data={ddAccountsLOVs?.IntegrationModuleTypes ?? []}
                value={getDropdownValue(
                  getValue("Integration_Module_Type_ENUM"),
                  ddAccountsLOVs?.IntegrationModuleTypes ?? []
                )}
                onChange={handleModuleTypeChange}
                component={CCSearchComboBox}
                disabled={isInactive}
                validator={requiredValidator}
              />
            </div>
            <div className="cc-field">
              <CCLabel title="Register ID" />
              <Field
                name={nameOf("Register_ID")}
                placeholder={"Register ID"}
                component={ComboboxDDSearchAPI}
                urlAPI={REGISTER_LOOKUP_API_URL}
                keySearch="searchString"
                isFilterable={false}
                dataItemKey="Finance_Customer_No"
                textField="RegisterName"
                value={String(getValue("Register_ID") || "")}
                onChange={handleRegisterIdChange}
                allowCustom
                disabled={isInactive}
                onError={(error: APIResponse<ResponsePacket>) => {
                  pushNotificationPortal({
                    title:
                      error?.data?.Errors?.length > 0
                        ? error?.data?.Errors
                        : "There is an error when searching register ID",
                    type: "error",
                  });
                }}
              />
            </div>
            <div className="cc-field">
              <CCLabel title="Item type ID" isMandatory />
              <Field
                component={CCNumericTextBox}
                name={nameOf("Item_Type_ID")}
                disabled={isInactive}
                validator={itemTypeIdValidator}
                format={NUMBER_FORMAT.NUMBER2}
              />
            </div>
          </div>
        </section>
      </FormElement>
    );
  }
);
