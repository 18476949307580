import { LocalLawsFormElement } from "@app/products/local-laws/[id]/components/general/components/form-element/_index";
import { useLocalLawsStore } from "@app/products/local-laws/[id]/store";
import { localLawsFormValidator } from "@app/products/local-laws/util";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import { Form, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

type IData = {
  [name: string]: any;
};
const LocalLawsFormPermit = observer(() => {
  const { localLaws } = useLocalLawsStore();
  const { setInitialDataForms } = useFlexibleFormStore();
  const handleSubmit = (data: IData) => {
    setInitialDataForms({ GeneralForm: data });
  };

  return (
    <div className="cc-form cc-form-submit-manage-page">
      <Form
        onSubmit={handleSubmit}
        initialValues={localLaws}
        validator={localLawsFormValidator}
        render={(formRenderProps: FormRenderProps) => {
          return <LocalLawsFormElement formRenderProps={formRenderProps} />;
        }}
      />
    </div>
  );
});

export const GeneralLocalLaws = () => {
  return <CCGeneralPanel component={<LocalLawsFormPermit />} />;
};
