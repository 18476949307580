import { FormAction, FormIdentifier } from "@app/products/crms/model";
import { alertDeleteRecord } from "@app/products/direct-debit/constant";
import { TitleDirectDebitBankAccountManagePage } from "@app/products/direct-debit/system-admin/bank-accounts/constant";
import { getDirectDebitBankAccountById } from "@app/products/direct-debit/system-admin/bank-accounts/[id]/api";
import { ExistManageDirectDebitBankAccount } from "@app/products/direct-debit/system-admin/bank-accounts/[id]/existed/_index";
import { NewManageDirectDebitBankAccount } from "@app/products/direct-debit/system-admin/bank-accounts/[id]/new/_index";
import { checkIsInactiveDirectDebitBankAccount } from "@app/products/direct-debit/system-admin/bank-accounts/[id]/util";
import { DirectDebitBankAccount } from "@app/products/direct-debit/system-admin/model";
import { APIResponse } from "@common/apis/model";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { useFlexibleFetchData } from "@common/hooks/flexible-fetch-data/useFlexibleFetchData";
import { useCancelRequest } from "@common/hooks/useCancelRequest";
import { useIsNew } from "@common/hooks/useIsNew";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { EWatchStatusLoading } from "@common/stores/route/model";
import { routeDataStoreInstance } from "@common/stores/route/store";
import { CCCheckPermissionWrap } from "@components/cc-check-permission-wrap/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

const ManageDirectDebitBankAccount = () => {
  //#region STORE ========/
  const {
    listenNotificationPortal,
    pushNotificationPortal,
    clearNotifications,
  } = useNotificationPortalStore();
  const history = useHistory();
  const { setInitialDataForms, resetDataForm, isLoadingForm } =
    useFlexibleFormStore();
  useEffectOnce(() => {
    return () => {
      resetDataForm();
    };
  });
  //#endregion STORE =====/

  //#region HOOK ========/
  const { cancelToken } = useCancelRequest();
  const isNew = useIsNew();
  //#endregion HOOK =====/

  //#region Variable ========/
  let { id } = useParams<{ id: string }>();
  const bankAccountId = useMemo(() => {
    return isNew ? 0 : +id;
  }, [isNew, id]);
  //#endregion Variable =====/

  const manageDirectDebitBankAccountSlider = useFlexibleFetchData({
    alias: "manageDirectDebitBankAccountSlider",
    slides: [
      {
        fetch: () =>
          getDirectDebitBankAccountById(bankAccountId, cancelToken()),
        handleSuccess: ({ dataFromApi }) => {
          const response: APIResponse<DirectDebitBankAccount> = dataFromApi;
          const ddBankAccountFormData = response?.data;
          //#region save data to store ========/
          setInitialDataForms({
            GeneralForm: ddBankAccountFormData,
          });
          //#endregion save data to store =====/

          //#region Notification ========/
          listenNotificationPortal();

          if (checkIsInactiveDirectDebitBankAccount()) {
            pushNotificationPortal(alertDeleteRecord, true);
          }
          //#endregion Notification =====/
        },
      },
    ],
  });
  const [hasAllowNewPermission, setHasAllowNewPermission] = useState(false);

  useEffect(() => {
    if (!isNew || (isNew && hasAllowNewPermission)) {
      manageDirectDebitBankAccountSlider.fetchApi();
    }
    return () => clearNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location, hasAllowNewPermission]);

  return (
    <>
      <Loading
        isLoading={
          manageDirectDebitBankAccountSlider.isFetching ||
          isLoadingForm ||
          routeDataStoreInstance.isLoadingFor(
            EWatchStatusLoading.IsLoadingPermission
          )
        }
        isFullScreen
      />
      <FormNavigation title={TitleDirectDebitBankAccountManagePage} />
      {manageDirectDebitBankAccountSlider.errorComponent ? (
        manageDirectDebitBankAccountSlider.errorComponent
      ) : isNew ? (
        <CCCheckPermissionWrap
          onChangeHasPermission={() => setHasAllowNewPermission(true)}
          permission={{
            formIdentifier: FormIdentifier.DirectDebit_Dialog_BankAccount,
            productType: PRODUCT_TYPE_NUMBER.DirectDebit,
            formAction: [FormAction.CORE_ALLOW_NEW, FormAction.CORE_ALLOW_SAVE],
          }}
        >
          <NewManageDirectDebitBankAccount />
        </CCCheckPermissionWrap>
      ) : (
        <ExistManageDirectDebitBankAccount />
      )}
    </>
  );
};

export default observer(ManageDirectDebitBankAccount);
