import { loadSearchComboboxWithoutFilter } from "@app/products/direct-debit/accounts/components/fields/combobox-search-api/api";
import {
  ComboboxSearchDDMessage,
  searchDDConfig,
} from "@app/products/direct-debit/accounts/components/fields/combobox-search-api/config";
import { loadSearchCombobox } from "@app/products/property/contacts-central-names/list/components/dialogs/components/form-elements/postal-and-physical-address/components/combobox-search-api/api";
import { isSuccessResponse } from "@common/apis/util";
import {
  ComboBox,
  ComboBoxChangeEvent,
  ComboBoxFilterChangeEvent,
  ComboBoxProps,
} from "@progress/kendo-react-dropdowns";
import { Error } from "@progress/kendo-react-labels";
import axios, { CancelTokenSource } from "axios";
import { isString } from "lodash";
import React, { useMemo, useRef, useState } from "react";
import { useEffectOnce } from "react-use";

export interface IEventOnChangeComboBox extends ComboBoxChangeEvent {
  data?: any;
}
interface IComboboxDDSearchAPIProps extends Omit<ComboBoxProps, "onChange"> {
  visited?: boolean;
  onError?: (value: any) => void;
  textProduce?: (value: any) => string | undefined;
  isLoadingParent?: boolean;
  urlAPI: string;
  keySearch: string;
  onChange: (event: IEventOnChangeComboBox) => void;
  isFilterable?: boolean;
}
export const ComboboxDDSearchAPI = <T extends {}>(
  props: IComboboxDDSearchAPIProps
) => {
  const {
    visited,
    validationMessage,
    onChange,
    className,
    value,
    onError,
    textProduce,
    isLoadingParent,
    itemRender,
    urlAPI,
    dataItemKey,
    keySearch,
    data,
    allowCustom = false,
    textField = "Name", //By default "Name",
    isFilterable = true,
    ...others
  } = props;
  const cancelRequest = useRef<CancelTokenSource>();
  const refTimeOut = useRef<NodeJS.Timeout | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dataSearch, setDataSearch] = useState<any>(data);

  //TODO: refactor later
  const comboBoxValue = useMemo(() => {
    if (dataItemKey && dataSearch?.length) {
      return dataSearch?.find((obj: any) => obj[dataItemKey] === value) ??
        (allowCustom && isString(value))
        ? {
            [textField]: value,
            [dataItemKey]: value,
          }
        : null;
    }
    if (dataItemKey && allowCustom && isString(value)) {
      return {
        [textField]: value,
        [dataItemKey]: value,
      };
    }
    return value;
  }, [textField, dataItemKey, dataSearch, value, allowCustom]);

  const handleSearch = (event: ComboBoxFilterChangeEvent) => {
    const searchText = event.filter.value;
    setDataSearch([]);
    if (searchText.length < searchDDConfig.minCharacters) return;
    if (refTimeOut.current) clearTimeout(refTimeOut.current);
    refTimeOut.current = setTimeout(async () => {
      cancelRequest.current?.cancel(ComboboxSearchDDMessage);
      cancelRequest.current = axios.CancelToken.source();
      setIsLoading(true);
      await (isFilterable
        ? loadSearchCombobox
        : loadSearchComboboxWithoutFilter)<T>(
        urlAPI,
        event.filter.value,
        keySearch,
        cancelRequest.current
      ).then((response: any) => {
        if (isSuccessResponse(response)) {
          setDataSearch(response?.data as any);
          setIsLoading(false);
        } else if (response?.error !== ComboboxSearchDDMessage) {
          if (onError) onError(response);
          setIsLoading(false);
        }
      });
    }, searchDDConfig.typeSpeed);
  };

  const handleOnChange = (event: ComboBoxChangeEvent) => {
    if (!onChange) return;
    const newValue = event.value;
    if (!newValue) return onChange({ ...event, value: null });
    return onChange({
      ...event,
      value: newValue,
      data: dataSearch,
    } as any);
  };

  useEffectOnce(() => {
    return () => {
      if (refTimeOut.current) clearTimeout(refTimeOut.current);
    };
  });

  return (
    <>
      <div className={`${className ?? ""}`}>
        <ComboBox
          {...others}
          allowCustom={allowCustom}
          textField={textField}
          filterable
          suggest
          data={dataSearch}
          itemRender={itemRender}
          loading={isLoading || isLoadingParent}
          onFilterChange={handleSearch}
          value={comboBoxValue}
          onChange={handleOnChange}
          popupSettings={{ className: "cc-picker-search-api" }}
        />
      </div>
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </>
  );
};
