import {
  SVC_FeeCalculator_NonRegistrationFee_RuleType,
  SVC_FeeCalculator_RegistrationFee_AdditionalCharge_AdjustmentType,
  SVC_FeeCalculator_RegistrationFee_AdditionalCharge_RuleType,
  SVC_FeeCalculator_RegistrationFee_RuleType,
} from "@app/products/animals/system-admin/animal-types/[id]/model";
import { Address } from "@app/products/waste-water/[id]/model";
import { AddressBookSection } from "@common/constants/enumerations";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { Keyword_ProductType, Keyword_Tags } from "@common/models/keyword";

//#region External DTO Animals
export class AnimalResponse {
  _SaveTriggers: string[] = [];
  Sys_TimeStamp: string = "";
  Registration_ID: number = 0;
  AnimalType_ID: number = 0;
  AnimalType?: AnimalType;
  Registration_Number: string = "";
  Animal_Number: number = 0;
  Status_ENUM: string = "";
  Gender_ENUM?: string = "";
  DueStatus: string = "";
  DebtorNumber: string = "";
  FileNumber: string = "";
  ReferenceNumber: string = "";
  AnimalName: string = "";
  MicrochipNumber: string = "";
  PensionNumber: string = "";
  TagNumber: string = "";
  BreedDisplay: string = "";
  ClubNumber: string = "";
  Flag_TransferFromOtherCouncil: boolean = false;
  Flag_Microchipped: boolean = false;
  Flag_Obedience: boolean = false;
  Flag_Dangerous: boolean = false;
  Flag_GuideDog: boolean = false;
  Flag_Customs: boolean = false;
  Flag_Farm: boolean = false;
  Flag_Organisation: boolean = false;
  Flag_Pensioner: boolean = false;
  Flag_HealthCareCard: boolean = false;
  Flag_Sterilised: boolean = false;
  Flag_ClubMember: boolean = false;
  Flag_Menacing: boolean = false;
  Flag_RenewalNoticeSent: boolean = false;
  Flag_RenewalFeeCreated: boolean = false;
  Contact?: Contact;
  Contact_RID: number = 0;
  Delegate?: Contact;
  Delegate_RID: number = 0;
  Address?: Address;
  Address_ID: number = 0;
  Breed_KWD: number = 0;
  Breed_Name: string = "";
  DeactivateReason_KWD: number = 0;
  DeactivateReason_Name: string = "";
  CrossBreed_KWD: number = 0;
  CrossBreed_Name: string = "";
  TagColour_KWD: number = 0;
  TagColour_Name: string = "";
  Colour_KWD: number = 0;
  Colour_Name: string = "";
  SterlisedProof_KWD: number = 0;
  SterlisedProof_Name: string = "";
  MicrochipProof_KWD: number = 0;
  MicrochipProof_Name: string = "";
  ObedienceProof_KWD: number = 0;
  ObedienceProof_Name: string = "";
  Date_DOB?: Date;
  Date_DOD?: Date;
  Age: string = "";
  TagHistory: string = "";
  TagHistoryCount: number = 0;
  Date_Recorded?: Date;
  Date_Lodged?: Date;
  Date_EffectiveFrom?: Date;
  Date_EffectiveTo?: Date;
  Flag_IsWebRegistration: boolean = false;
  Flag_IsWebRegistrationProcessed: boolean = false;
  WebRegistration_Date?: Date;
  PortalFee_ReferenceNumber: string = "";
  Portal_FeeAmount: number = 0;
  Fee_PaidInPortal: boolean = false;
  PhotoAttachment?: Attachment;
  PhotoAttachment_ID: number = 0;
  IsPureBreed: boolean = false;
  IsGuardDog: boolean = false;
  IsDogHomeAdoption: boolean = false;
  SecondaryColour_KWD: number = 0;
  SecondaryColour_Name: string = "";
  Flag_PenaltyFeeCreated: boolean = false;
  Flag_QuickRegistration: boolean = false;
  PoundRegister_ID: number = 0;
  IsHuntingDog: boolean = false;
  IsGreyhound: boolean = false;
  IsBreedingCat: boolean = false;
  PureBreedProof_KWD: number = 0;
  PureBreedProof_Name: string = "";
  GuideOrAssistanceDogProof_KWD: number = 0;
  GuideOrAssistanceDogProof_Name: string = "";
  WorkingProof_KWD: number = 0;
  WorkingProof_Name: string = "";
  CustomsDogProof_KWD: number = 0;
  CustomsDogProof_Name: string = "";
  GuardDogProof_KWD: number = 0;
  GuardDogProof_Name: string = "";
  DogHomeAdoptionProof_KWD: number = 0;
  DogHomeAdoptionProof_Name: string = "";
  HuntingDogProof_KWD: number = 0;
  HuntingDogProof_Name: string = "";
  GreyhoundProof_KWD: number = 0;
  GreyhoundProof_Name: string = "";
  Date_PensionerExpiry?: Date;
  IsTransferred: boolean = false;
  Sys_EnterpriseKey: string = "";
  Sys_CreatedDate?: Date;
  Sys_CreatedBy: string = "";
  Sys_CreatedBy_ID: number = 0;
  Sys_DBRowState: string = "";
  Sys_TransactionDate?: Date;
  Sys_ModifiedDate?: Date;
  Sys_ModifiedBy: string = "";
  Sys_ModifiedBy_ID: number = 0;
  Sys_ReferenceNumber: string = "";
  Sys_StatClockStatus_ENUM: string = "";
  Sys_FileNumber: string = "";
  Sys_DebtorNumber: string = "";
  Sys_ExternalReference: string = "";
}

export class AnimalRegisterRequest extends AnimalResponse {}

export class AnimalRegisterForm extends AnimalResponse {
  RegisteredAddress: string = "";
  PostalAddress: string = "";
  AnimalOwner: string = "";
  FeeOS: number = 0;
}
//#endregion

export interface BubbleUp {
  PKID: number;
  SourceIdentifier: SourceIdentifier;
  BubbleUpType_ENUM: string;
  Sys_CreatedDate: Date;
  Sys_DeactivationDate: Date;
}

export interface SourceIdentifier {
  _RecordSource_ID: number;
  _RecordSourceType_ENUM: string;
  _RecordSource_ColumnName: string;
  _RecordSource_StringID: string;
}

export interface AnimalType {
  Sys_TimeStamp: string;
  AnimalType_ID: number;
  Name: string;
  Description: string;
  MaxNoOfAnimals: number;
  MaxNoOfAnimals_Large: number;
  Fee_StandardAmount: number;
  Fee_GvtAmount: number;
  Fee_MinAmount: number;
  Fee_ReplacementTagAmount: number;
  Fee_TransferAmount: number;
  Fee_PensionerDiscount: number;
  Fee_HealthCareCardDiscount: number;
  Fee_SterilisedDiscount: number;
  Fee_AgeOverTenDiscount: number;
  Fee_AgeUnderOneDiscount: number;
  Fee_ClubMemberDiscount: number;
  Fee_MicrochipDiscount: number;
  Fee_ObedienceSchoolDiscount: number;
  Fee_GuideDogDiscount: number;
  Fee_CustomsDiscount: number;
  Fee_FarmDiscount: number;
  Fee_OrganisationDiscount: number;
  Sequence_ENUM: string;
  TagPrefix: string;
  Flag_LifeTimeTag: boolean;
  Flag_AutoTagNo: boolean;
  FeeType_ID: number;
  FeeType_Name: string;
  FeeCalculationType_ID: number;
  FeeCalculationType_Name: string;
  PenalityFeeType_ID: number;
  PenalityFeeType_Name: string;
  FeeRulesList: FeeRulesList;
  Flag_EnableProrata: boolean;
  Flag_AnimalType: boolean;
  Flag_BreedingCat: boolean;
  RenewalPeriod_ID: number;
  RenewalPeriod_Name: string;
  BreedingCatFeeType_ID: number;
  BreedingCatFeeType_Name: string;
  Flag_Discount: boolean;
  Fee_SpecialDiscountPercent: number;
  Sys_EnterpriseKey: string;
  Sys_CreatedDate: Date;
  Sys_CreatedBy: string;
  Sys_CreatedBy_ID: number;
  Sys_DBRowState: string;
  Sys_TransactionDate: Date;
  Sys_ModifiedDate: Date;
  Sys_ModifiedBy: string;
  Sys_ModifiedBy_ID: number;
  Sys_ReferenceNumber: string;
  Sys_StatClockStatus_ENUM: string;
  Sys_FileNumber: string;
  Sys_DebtorNumber: string;
  Sys_ExternalReference: string;
}

export enum SVC_FeeCalculator_RegistrationFee_MatchMethod {
  Default,
  FirstMatch,
  LowestPrice,
  HighestPrice,
}
export interface FeeRulesList {
  Method_ENUM: SVC_FeeCalculator_RegistrationFee_MatchMethod;
  Method_Name: string;
  RegistrationFeeRules: RegistrationFeeRule[];
  RegistrationFeeAdditionalChargeRules: RegistrationFeeAdditionalChargeRule[];
  NonRegistrationFeeRules: NonRegistrationFeeRule[];
}

export interface NonRegistrationFeeRule {
  NonRegistrationFeeRuleType_ENUM: SVC_FeeCalculator_NonRegistrationFee_RuleType;
  NonRegistrationFeeRuleType_Name: string;
  Amount: number;
  AddGST: boolean;
  FeeType_Name: string;
  FeeType_ID: number;
  FeeType_CostCode: string;
  Description: string;
}

export interface RegistrationFeeAdditionalChargeRule {
  AdditionalChargeRuleType_ENUM: SVC_FeeCalculator_RegistrationFee_AdditionalCharge_RuleType;
  AdditionalChargeRuleType_Name: string;
  AdjustmentType_ENUM: SVC_FeeCalculator_RegistrationFee_AdditionalCharge_AdjustmentType;
  AdjustmentType_Name: string;
  Amount: number;
  Description: string;
}

export enum SVC_FeeCalculator_RegistrationFee_AdjustmentType {
  Default,
  DateAfter,
  Add,
  Deduct,
  PercentAddition,
  PercentDeduction,
  PensionerOverride,
}

export interface RegistrationFeeAdjustment {
  AdjustmentType_ENUM: SVC_FeeCalculator_RegistrationFee_AdjustmentType;
  AdjustmentType_Name: string;
  Adjustment_Label: string;
  Adjustment_Date: Date | null;
  Adjustment_Amount: number | null;
  Description: string;
}

export interface RegistrationFeeRule {
  RuleType_ENUM: SVC_FeeCalculator_RegistrationFee_RuleType;
  RuleType_Name: string;
  BaseAmount: number;
  Adjustment1: RegistrationFeeAdjustment;
  Adjustment2: RegistrationFeeAdjustment;
  FeeType_Name: string;
  FeeType_ID: number;
  FeeType_CostCode: string;
  SortOrder: number;
  IsPriority: boolean;
  IsOnlyFirstYear: boolean;
  EnableRVP: boolean;
  RVPStartDay: number;
  RVPStartMon: number;
  RVPEndDay: number;
  RVPEndMon: number;
  Description: string;
}

export interface Adjustment {
  AdjustmentType_ENUM: string;
  Adjustment_Label: string;
  Adjustment_Date: Date;
  Adjustment_Amount: number;
}

export interface Contact {
  _BubbleUps: BubbleUp[];
  _ExtensionType: string[];
  _KeywordTags: Keyword_Tags[];
  _SaveTriggers: string[];
  Sys_TimeStamp: string;
  RID: number;
  ContactRelationshipType_ENUM: string;
  Contact: ContactContact;
  Extension: DelegateExtension;
  PersonRole_KWD: number;
  PersonRole: PersonRole;
  RelationshipComment: string;
  Sys_EnterpriseKey: string;
  Sys_CreatedDate: Date;
  Sys_CreatedBy: string;
  Sys_CreatedBy_ID: number;
  Sys_DBRowState: string;
  Sys_TransactionDate: Date;
  Sys_ModifiedDate: Date;
  Sys_ModifiedBy: string;
  Sys_ModifiedBy_ID: number;
  Sys_ReferenceNumber: string;
  Sys_StatClockStatus_ENUM: string;
  Sys_FileNumber: string;
  Sys_DebtorNumber: string;
  Sys_ExternalReference: string;
}

export interface ContactContact {
  _PreferredMethod_Formatted: string;
  _WorkPhone: string;
  _HomePhone: string;
  _Fax: string;
  _Mobile: string;
  _ExtensionType: string[];
  _SaveTriggers: string[];
  Sys_TimeStamp: string;
  Contact_ID: number;
  DisplayName: string;
  FirstName: string;
  LastName: string;
  ContactClassification_ENUM: string;
  PreferredMethod_ENUM: string;
  NARNumber: string;
  NARUpdated: boolean;
  Flag_SendSMSAlerts: boolean;
  Queued_Sys_DBRowState: string;
  Custom_Tags: string;
  Email: string;
  Website: string;
  ABN: string;
  ACN: string;
  VIP: boolean;
  VIPTypeID: number;
  VIPType: string;
  VIPRelationshipOwnerID: number;
  VIPRelationshipOwner: string;
  ContactRoles_Count: number;
  PreferredAddress: Address;
  Organisation: string;
  Preferred_ContactAddressType_KWD: number;
  Preferred_ContactAddressType_Name: string;
  PostalAddress: Address;
  Postal_ContactAddressType_KWD: number;
  Postal_ContactAddressType_Name: string;
  AlternateAddress: Address;
  Alternate_ContactAddressType_KWD: number;
  Alternate_ContactAddressType_Name: string;
  AddressBook: AddressBook;
  AddressBookFlag_ENUM: string;
  CopiedFrom_Contact_ID: number;
  SynchDate: Date;
  Extension: PurpleExtension;
  Contact_CopiedFrom_PortalUser_MemberGUID: string;
  Contact_CopiedFrom_PortalUser_MemberUserName: string;
  ContactAlert: string;
  MergedContact_ID: number;
  Flag_Duplicate: boolean;
  IsContactCopied: boolean;
  IDCardNumber: string;
  UserType_ENUM: string;
  Photo_Attachment_ID: number;
  Photo_Attachment: Attachment;
  Sign_Attachment_ID: number;
  Sign_Attachment: Attachment;
  AuthorisedActs_KWDs: string;
  Qualification_KWD: number;
  Qualification_Name: string;
  Date_IoA: Date;
  Has_IoA_Location: boolean;
  Date_DeliveryLGIS: Date;
  Signed_IoA_Attachment_ID: number;
  Signed_IoA_Attachment: Attachment;
  Date_CardOrginalIssue: Date;
  Date_CardProduced: Date;
  Date_CardReceivedSigned: Date;
  Date_CardReIssue: Date;
  Date_CardReIssueReceivedSigned: Date;
  AQN: string;
  PortalStatus: string;
  ABNValid: boolean;
  ACNValid: boolean;
  Sys_EnterpriseKey: string;
  Sys_CreatedDate: Date;
  Sys_CreatedBy: string;
  Sys_CreatedBy_ID: number;
  Sys_DBRowState: string;
  Sys_TransactionDate: Date;
  Sys_ModifiedDate: Date;
  Sys_ModifiedBy: string;
  Sys_ModifiedBy_ID: number;
  Sys_ReferenceNumber: string;
  Sys_StatClockStatus_ENUM: string;
  Sys_FileNumber: string;
  Sys_DebtorNumber: string;
  Sys_ExternalReference: string;
}

export interface AddressBook {
  Sys_TimeStamp: string;
  _ExtensionType: string[];
  Sys_EnterpriseKey: string;
  Sys_TransactionDate: Date;
  AddressBook_ID: number;
  AddressBook_Name: string;
  AddressBookSection_ENUM: AddressBookSection;
  ProductType_ENUM: string;
  IsSystemRecord: boolean;
}

export interface PurpleExtension {
  _TTY: string;
  DateOfBirth: Date;
  VerificationQuestion: string;
  VerificationResponse: string;
  JobTitle: string;
  Organisation: string;
  ContactAttention: string;
  ShowContactAttention: boolean;
  FSSCertificate: string;
  FSSCertificateExpiryDate: Date;
  LicenceNo: string;
  FSSCertificateNo: string;
  CM_PlaceOfBirth: string;
  CM_Contactable: boolean;
  CM_Deceased: boolean;
  DebtorNo: string;
  CM_LastKnownAddress_Id: number;
  CM_DatePassedAway: Date;
  CM_LastKnownAddress: Address;
  AgeAtDeath: number;
  Language_ID: number;
  Language: PersonRole;
  SecondLanguage: string;
  DisabilityType_KWD: number;
  DisabilityDetails: string;
  Lst_Disability_Category: number[];
  Lst_Disability_SubCategory: number[];
  Prisoner: boolean;
  CRNNumber: string;
  AboriginalStatus_KWD: number;
  LanguageAtHome: boolean;
  LanguageAtHome_KWD: number;
}

export interface PersonRole {
  _SaveTriggers: SaveTrigger[];
  Sys_TimeStamp: string;
  IsSystemRecord: boolean;
  IsProdAdminRecord: boolean;
  IsHiddenRecord: boolean;
  Keyword_ID: number;
  Parent_KWD: number[];
  Name: string;
  Description: string;
  FullDescription: string;
  KeywordType_ENUM: string;
  ProductType_ENUM: string;
  SortOrderIndex: number;
  Enumerated_ID: number;
  SystemFilter_ENUMS: string;
  SystemFilter_Names: string;
  TargetDays: number;
  Code: string;
  FSRCode_ENUM: FSRCodeENUM;
  lstKeywordProductType: Keyword_ProductType[];
  KeywordReaders: KeywordReader[];
  URL: string;
  IsRestricted: boolean;
  IsPublishToWeb: boolean;
  ExpiryMonths: number;
  Category: string;
  Sys_EnterpriseKey: string;
  Sys_CreatedDate: Date;
  Sys_CreatedBy: string;
  Sys_CreatedBy_ID: number;
  Sys_DBRowState: string;
  Sys_TransactionDate: Date;
  Sys_ModifiedDate: Date;
  Sys_ModifiedBy: string;
  Sys_ModifiedBy_ID: number;
  Sys_ReferenceNumber: string;
  Sys_StatClockStatus_ENUM: string;
  Sys_FileNumber: string;
  Sys_DebtorNumber: string;
  Sys_ExternalReference: string;
}

export enum FSRCodeENUM {
  Na = "NA",
}

export interface KeywordReader {
  Sys_TimeStamp: string;
  ACID: number;
  RecordSource: SourceIdentifier;
  ACRecordType_ENUM: string;
  ACSource_ENUM: string;
  AccessRights_ENUM: string;
  EntityType_ENUM: string;
  Contact_ID: number;
  Contact_DisplayName: string;
  OrgUnit_ID: number;
  OrgUnit_Hierarchy: string;
  Flag_Upchannel: boolean;
  Sys_EnterpriseKey: string;
  Sys_CreatedDate: Date;
  Sys_CreatedBy: string;
  Sys_CreatedBy_ID: number;
  Sys_DBRowState: string;
  Sys_TransactionDate: Date;
  Sys_ModifiedDate: Date;
  Sys_ModifiedBy: string;
  Sys_ModifiedBy_ID: number;
  Sys_ReferenceNumber: string;
  Sys_StatClockStatus_ENUM: string;
  Sys_FileNumber: string;
  Sys_DebtorNumber: string;
  Sys_ExternalReference: string;
}

export enum SaveTrigger {
  UpdateAccessControl = "UpdateAccessControl",
}

export interface Attachment {
  _BubbleUps: BubbleUp[];
  _CustomFieldList: List[];
  _AvailableFieldsList: List[];
  _SaveTriggers: string[];
  Sys_TimeStamp: string;
  Attachment_ID: number;
  Tag: string;
  Title: string;
  FileEntry: FileEntry;
  TRIMUri: string;
  RecordType: string;
  Legacy_RecordSourceID: number;
  Legacy_RecordSourceTypeID: number;
  Category_KWD: number;
  Category: PersonRole;
  DocumentTemplate_ID: number;
  SortOrder: number;
  JournalPOI_ENUM: string;
  AddToRecords: boolean;
  UseParentFileNumber: boolean;
  FileNumber: string;
  Records_Notes: string;
  Records_ParentRecordId: string;
  PreferredAuthor_ID: number;
  PreferredAuthor_DisplayName: string;
  PreferredAuthor_UserName: string;
  Manager_DisplayName: string;
  Manager_UserName: string;
  Offline_Ref: string;
  FormattedAuthorName: string;
  AssessmentNumber: string;
  Status_ENUM: string;
  DocumentClassification_ENUM: string;
  ExternalDocumentType_KWD: number;
  Sys_EnterpriseKey: string;
  Sys_CreatedDate: Date;
  Sys_CreatedBy: string;
  Sys_CreatedBy_ID: number;
  Sys_DBRowState: string;
  Sys_TransactionDate: Date;
  Sys_ModifiedDate: Date;
  Sys_ModifiedBy: string;
  Sys_ModifiedBy_ID: number;
  Sys_ReferenceNumber: string;
  Sys_StatClockStatus_ENUM: string;
  Sys_FileNumber: string;
  Sys_DebtorNumber: string;
  Sys_ExternalReference: string;
}

export interface FileEntry {
  FileName: string;
  ContentType: string;
  FileSize: number;
  FileAttachment: string;
}

export interface List {
  Key: string;
  Value: string;
}

export interface DelegateExtension {}
export class IAnimalRecordsState {
  actions: number = 0;
  comments: number = 0;
  contacts: number = 0;
  documents: number = 0;
  fees: number = 0;
  inspections: number = 0;
  samples: number = 0;
}

export const AnimalsFieldMapping = {
  TagNo: "TagNo",
  MicrochipNo: "MicrochipNo",
  RegistrationNumber: "RegistrationNumber",
  AnimalOwner: "AnimalOwner",
  AnimalOwnerContactNo: "AnimalOwnerContactNo",
  SiteAddress: "SiteAddress",
  Locality: "Locality",
  Type: "Type",
  Breed: "Breed",
  AnimalName: "AnimalName",
  Colour: "Colour",
  Gender: "Gender",
  Age: "Age",
  Sterilised: "Sterilised",
  AmountOS: "AmountOS",
  StatusDueStatus: "StatusDueStatus",
  EffectiveTo: "EffectiveTo",
  DebtorNo: "DebtorNo",
  DOD: "DOD",
  DeactiveReason: "DeactiveReason",
  OwnerpostalAddress: "OwnerpostalAddress",
  OwnerLocality: "OwnerLocality",
  OwnerPostCode: "OwnerPostCode",
  Microchipped: "Microchipped",
  DOB: "DOB",
  Area: "Area",
  Address: "Address",
  InspectionFrequency: "InspectionFrequency",
  Priority: "Priority",
  Officer: "Officer",
  LastInspection: "LastInspection",
  DateRecorded: "DateRecorded",
  StartDate: "StartDate",
  NextInspection: "NextInspection",
  DateInspected: "DateInspected",
  ReferenceNumber: "ReferenceNumber",
  ContactName: "ContactName",
  InspectionType: "InspectionType",
  Compliance: "Compliance",
  Reason: "Reason",
  RefNo: "RefNo",
  Description: "Description",
  DateTarget: "DateTarget",
  Source: "Source",
  Category: "Category",
  Location: "Location",
  RequestedBy: "RequestedBy",
  ContactDetails: "ContactDetails",
  Status: "Status",
  DueStatus: "DueStatus",
  RecordedBy: "RecordedBy",
  OrgStructure: "OrgStructure",
  ActionOfficer: "ActionOfficer",
  Coordinator: "Coordinator",
  DateWork: "DateWork",
  FileNumber: "FileNumber",
  EnquiryRefNo: "EnquiryRefNo",
  Event_ReferenceNoAdditional: "Event_ReferenceNoAdditional",
  OnBehalfOf: "OnBehalfOf",
  Flag_ITSupport: "Flag_ITSupport",
  Flag_Safety: "Flag_Safety",
  Flag_Risk: "Flag_Risk",
  Flag_VIP: "Flag_VIP",
  DateServed: "DateServed",
  DateDue: "DateDue",
  DateRevoked: "DateRevoked",
  CreatedDate: "CreatedDate",
  FeeType: "FeeType",
  AmountIssued: "AmountIssued",
  AmountOutstanding: "AmountOutstanding",
  CostCode: "CostCode",
  InvoiceNumber: "InvoiceNumber",
  DebtorNumber: "DebtorNumber",
  RegisterNo: "RegisterNo",
  AnimalsRegNo: "AnimalsRegNo",
  CRMSEventNo: "CRMSEventNo",
  MicrochipNumber: "MicrochipNumber",
  DateEntered: "DateEntered",
  ImpoundedBy: "ImpoundedBy",
  PickUpLocation: "PickUpLocation",
  Outcome: "Outcome",
  DateClosure: "DateClosure",
  AnimalType: "AnimalType",
  KennelRegNo: "KennelRegNo",
  Owner: "Owner",
  DateLodged: "DateLodged",
  DateEffectiveFrom: "DateEffectiveFrom",
  DateEffectiveTo: "DateEffectiveTo",
  Name: "Name",
  MaxNo: "MaxNo",
  MaxNoLarge: "MaxNoLarge",
  StandardFee: "StandardFee",
  ReplacementTagFee: "ReplacementTagFee",
};

export enum AnimalsRoute {
  ANIMALS_REGISTER_ACTIVE = "/animals/register/active",
  ANIMALS_REGISTER_DOGSBYBREED = "/animals/register/dogsbybreed",
  ANIMALS_REGISTER_CATSBYBREED = "/animals/register/catsbybreed",
  ANIMALS_REGISTER_OTHERBYBREED = "/animals/register/otherbybreed",
  ANIMALS_REGISTER_ANIMALSBYTYPE = "/animals/register/animalsbytype",
  ANIMALS_REGISTER_DECEASED = "/animals/register/deceased",
  ANIMALS_REGISTER_INACTIVE = "/animals/register/inactive",
  ANIMALS_REGISTER_LOSTTAGS = "/animals/register/losttags",
  ANIMALS_REGISTER_LIFETIME = "/animals/register/lifetime",
  ANIMALS_REGISTER_REGISTER = "/animals/register/all",
  ANIMALS_REGISTER_QUICKREGISTER = "/animals/register/quickregister",
  ANIMALS_REGISTER_DOGREGISTER = "/animals/register/dogregister",
  ANIMALS_REGISTER_CATREGISTER = "/animals/register/catregister",
  ANIMALS_REGISTER_CATBREEDERS = "/animals/register/catbreeders",
  ANIMALS_REGISTER_DANGEROUSDOGS = "/animals/register/dangerousdogs",
  ANIMALS_RENEWALS_ALLBYDUESTATUS = "/animals/renewals/allbyduestatus",
  ANIMALS_RENEWALS_REQUIRESRENEWALFEE = "/animals/renewals/requiresrenewalfee",
  ANIMALS_RENEWALS_REQUIRESRENEWALNOTICE = "/animals/renewals/requiresrenewalnotice",
  ANIMALS_RENEWALS_NOTICESENT = "/animals/renewals/noticesent",
  ANIMALS_INSPECTIONPLANNER_INSPECTIONPLANS = "/animals/inspectionplanner/inspectionplans",
  ANIMALS_INSPECTIONPLANNER_BYAREA = "/animals/inspectionplanner/byarea",
  ANIMALS_INSPECTIONPLANNER_BYPRIORITY = "/animals/inspectionplanner/bypriority",
  ANIMALS_INSPECTIONPLANNER_BYOFFICER = "/animals/inspectionplanner/byofficer",
  ANIMALS_INSPECTIONPLANNER_OVERDUE = "/animals/inspectionplanner/overdue",
  ANIMALS_INSPECTIONS_BYDATE = "/animals/inspections/bydate",
  ANIMALS_INSPECTIONS_BYTYPE = "/animals/inspections/bytype",
  ANIMALS_INSPECTIONS_BYOFFICER = "/animals/inspections/byofficer",
  ANIMALS_INSPECTIONS_BYMONTH = "/animals/inspections/bymonth",
  ANIMALS_INSPECTIONS_BYAREA = "/animals/inspections/byarea",
  ANIMALS_COMPLAINTS_BYREFNO = "/animals/view-crms-event/byrefno",
  ANIMALS_COMPLAINTS_BYCATEGORY = "/animals/view-crms-event/bycategory",
  ANIMALS_COMPLAINTS_BYSTATUS = "/animals/view-crms-event/bystatus",
  ANIMALS_NOTICES_BYDATE = "/animals/notices/bydate",
  ANIMALS_NOTICES_BYDUESTATUS = "/animals/notices/byduestatus",
  ANIMALS_FINANCE_OSFEES = "/animals/finance/osfees",
  ANIMALS_FINANCE_FEESPAID = "/animals/finance/feespaid",
  ANIMALS_FINANCE_FEESALL = "/animals/finance/feesall",
  ANIMALS_EANIMALS_NEWAPPLICATIONS = "/animals/eanimals/newapplications",
  ANIMALS_POUNDREGISTER_BYNUMBER = "/animals/poundregister/bynumber",
  ANIMALS_POUNDREGISTER_BYSTATUS = "/animals/poundregister/bystatus",
  ANIMALS_KENNELSRENEWALS_REQUIRESRENEWALFEES = "/animals/kennelsrenewals/requiresrenewalfees",
  ANIMALS_SYSTEMADMIN_ANIMALTYPES = "/animals/systemadmin/animaltypes",
  ANIMALS_SYSTEMADMIN_SYSTEMSETTINGS = "/settings/systemadmin/animals/active",
  ANIMALS_DETAIL = "/animals/manageregistration",
}

// New handle will remove old model when not state use

export const animalsSettingEnum = [
  ECorporateSettingsField.Animals_RegistrationExpiryDate,
  ECorporateSettingsField.Animals_RegistrationExpiryCalcEffectiveDate,
  ECorporateSettingsField.Animals_RegistrationExpiryDateOption,

  ECorporateSettingsField.CorporateSettings_EnableUpToGlassFinance,
  ECorporateSettingsField.CorporateSettings_RegisterDisplayDebtorNumber,
  ECorporateSettingsField.CorporateSettings_EnableMultiLineInvoice,
  ECorporateSettingsField.Animals_EnableTransfer,
  ECorporateSettingsField.Animals_EnableDOGExemption,

  ECorporateSettingsField.Animals_ShowOSFees,
  ECorporateSettingsField.Animals_ShowAge,
  ECorporateSettingsField.Animals_DisplayAnimalType,

  ECorporateSettingsField.Animals_Display_Purebreed,
  ECorporateSettingsField.Animals_Display_ClubMember,
  ECorporateSettingsField.Animals_Display_Organisation,
  ECorporateSettingsField.Animals_Display_Menacing,
  ECorporateSettingsField.Animals_Display_GuardDog,
  ECorporateSettingsField.Animals_Display_DogHomeAdoption,
  ECorporateSettingsField.Animals_Display_ObedienceTrained,
  ECorporateSettingsField.Animals_Display_CustomsDog,
  ECorporateSettingsField.Animals_Display_SecondaryColour,
  ECorporateSettingsField.Animals_Display_HealthCareCard,
  ECorporateSettingsField.Animals_Display_CertifiedAssistance,
  ECorporateSettingsField.Animals_Display_RestrictedBreed,
  ECorporateSettingsField.Animals_Display_Huntingdog,
  ECorporateSettingsField.Animals_Display_Greyhound,
  ECorporateSettingsField.Animals_EnablePensionerExpiry,
  ECorporateSettingsField.Animals_EnableTransfer,

  ECorporateSettingsField.Animals_Mandatory_Breed,
  ECorporateSettingsField.Animals_Mandatory_Purebreed,
  ECorporateSettingsField.Animals_Mandatory_Gender,
  ECorporateSettingsField.Animals_Mandatory_Colour,
  ECorporateSettingsField.Animals_Mandatory_AnimalName,
  ECorporateSettingsField.Animals_Mandatory_ClubMember,
  ECorporateSettingsField.Animals_Mandatory_CertifiedAssistance,
  ECorporateSettingsField.Animals_Mandatory_Dangerous,
  ECorporateSettingsField.Animals_Mandatory_Organisation,
  ECorporateSettingsField.Animals_Mandatory_Menacing,
  ECorporateSettingsField.Animals_Mandatory_GuardDog,
  ECorporateSettingsField.Animals_Mandatory_Working,
  ECorporateSettingsField.Animals_Mandatory_GuideDogAssistance,
  ECorporateSettingsField.Animals_Mandatory_DogHomeAdoption,
  ECorporateSettingsField.Animals_Mandatory_ObedienceTrained,
  ECorporateSettingsField.Animals_Mandatory_CustomsDog,
  ECorporateSettingsField.Animals_Mandatory_SecondaryColour,
  ECorporateSettingsField.Animals_Mandatory_HealthCareCard,
  ECorporateSettingsField.Animals_Mandatory_RestrictedBreed,
  ECorporateSettingsField.Animals_Mandatory_Huntingdog,
  ECorporateSettingsField.Animals_Mandatory_Greyhound,

  ECorporateSettingsField.Animals_Mandatory_SterlisedTypeOfProof,
  ECorporateSettingsField.Animals_Mandatory_ObedienceTypeOfProof,
  ECorporateSettingsField.Animals_Mandatory_GuideDogTypeOfProof,
  ECorporateSettingsField.Animals_Mandatory_WorkingTypeOfProof,
  ECorporateSettingsField.Animals_Mandatory_CustomsDogTypeOfProof,
  ECorporateSettingsField.Animals_Mandatory_PureBreedTypeOfProof,
  ECorporateSettingsField.Animals_Mandatory_GuardDogTypeOfProof,
  ECorporateSettingsField.Animals_Mandatory_DogHomeAdoptionTypeOfProof,
  ECorporateSettingsField.Animals_Mandatory_HuntingDogTypeOfProof,
  ECorporateSettingsField.Animals_Mandatory_GreyhoundTypeOfProof,

  ECorporateSettingsField.Animals_LabelBreed,
  ECorporateSettingsField.Animals_LabelDOB,
  ECorporateSettingsField.Animals_LabelClubMember,
  ECorporateSettingsField.Animals_LabelColour,
  ECorporateSettingsField.Animals_LabelOrganisation,
  ECorporateSettingsField.Animals_LabelMenacing,
  ECorporateSettingsField.Animals_LabelHuntingDog,
  ECorporateSettingsField.Animals_LabelPureBreed,
  ECorporateSettingsField.Animals_LabelWorking,
  ECorporateSettingsField.Animals_LabelGreyhound,
  ECorporateSettingsField.Animals_LabelGuideDogAssistance,
  ECorporateSettingsField.Animals_LabelDangerous,
  ECorporateSettingsField.Animals_ClassificationLabelPensioner_Sterilised,
  ECorporateSettingsField.Animals_ClassificationLabelPensioner_NotSterilised,
  ECorporateSettingsField.Animals_ClassificationLabelNotPensioner_Sterilised,
  ECorporateSettingsField.Animals_ClassificationLabelNotPensioner_NotSterilised,
  ECorporateSettingsField.CorporateSettings_DebtorNumberLabel,
  ECorporateSettingsField.CorporateSettings_DebtorNumberMandatory,
  ECorporateSettingsField.CorporateSettings_EnableDebtorUserSelection,

  ECorporateSettingsField.Animals_UseOpenOfficeFeeCalculator,
  ECorporateSettingsField.Animals_EnableDiscount,
  ECorporateSettingsField.Animals_Discount_Name,
  ECorporateSettingsField.Animals_EnableProRata,
  ECorporateSettingsField.Animals_RestrictDateRangeStart,
  ECorporateSettingsField.Animals_RestrictDateRangeEnd,
  ECorporateSettingsField.Animals_AutoIssue,
  ECorporateSettingsField.Animals_EnableDiscount,
  ECorporateSettingsField.Animals_Discount_Name,
  ECorporateSettingsField.CorporateSettings_FinanceAllocateDebtorNumber,

  ECorporateSettingsField.Animals_RenewalMethod,
  ECorporateSettingsField.Animals_EnableBatchRenewRecieptDialogBox,
  ECorporateSettingsField.Animals_EnableQuickEntryBatchRenew,
  ECorporateSettingsField.CorporateSettings_Enable_Subsidies,
  ECorporateSettingsField.CorporateSettings_Subsidies_Label,
  ECorporateSettingsField.CorporateSettings_FinanceAllocateInvoiceNumber,
  ECorporateSettingsField.CorporateSettings_FinanceFeeIDInvoiceNumber,
  ECorporateSettingsField.CorporateSettings_FinanceMethod,
  ECorporateSettingsField.Animals_EnableRegenerateFee,
];

export enum AnimalsMenu {
  Unspecified = 0,
  //#region Animals
  Animals = 1,
  AnimalsActive = 2,
  AnimalsRegister = 3,
  AnimalsByTypeDogs = 4,
  AnimalsByTypeCats = 5,
  AnimalsByTypeOther = 6,
  AnimalsByType = 7,
  AnimalsDeceased = 8,
  AnimalsInactive = 9,
  AnimalsLostTag = 10,
  AnimalsLifeTime = 11,
  AnimalsQuickRegister = 12,
  DogRegister = 13,
  CatRegister = 14,
  BreedingCatRegister = 15,
  DangerousDogs = 16,
  DogExemption = 17,
  //#endregion

  //#region Renewals
  Renewals = 20,
  AnimalsRenewal = 21,
  RenewalsReqFee = 22,
  RenewalsReqNotice = 23,
  RenewalsReqRenewal = 24,
  RenewalsReqNoticeNoIssueFee = 25,
  RenewalsReqRenewalNoIssueFee = 26,
  //#endregion

  //#region Inspection Planner
  InspectionPlanner = 30,
  InspectionPlansByCreatedDate = 31,
  InspectionPlansByArea = 32,
  InspectionPlansByPriority = 33,
  InspectionPlansByOfficer = 34,
  InspectionPlansByOverDue = 35,
  //#endregion

  //#region Inspections
  Inspections = 40,
  InspectionsByDate = 41,
  InspectionsByType = 42,
  InspectionsByOfficer = 43,
  InspectionsByMonth = 44,
  InspectionsByArea = 45,
  //#endregion

  //#region Complaints
  Complaints = 50,
  ComplaintsByDate = 51,
  ComplaintsByStatus = 52,
  ComplaintsByCategory = 53,
  ComplaintsByDateUnrelated = 54,
  EventsByRefNo = 55,
  AllEventsByCategory = 56,
  AllEventsByStatus = 57,
  //#endregion

  //#region Notices
  Notices = 60,
  NoticesByDate = 61,
  NoticesByDueStatus = 62,
  //#endregion

  //#region Finance
  Finance = 70,
  DebtorSummaryAll = 71,
  DebtorSummaryOutstanding = 72,
  FeesOutstanding = 73,
  FeesPaid = 74,
  FeesNotSentToFinance = 75,
  FeesSentToFinance = 76,
  InvoicesNotSentToFinance = 77,
  InvoicesSentToFinance = 78,
  FeesAll = 79,
  //#endregion

  //#region eAnimals
  eAnimals = 80,
  eAnimalsRegistrations = 81,
  //#endregion

  //#region Pound Register
  PoundRegister = 90,
  PoundRegisterByNumber = 91,
  PoundRegisterByStatus = 92,
  PoundRegisterPrelodgement = 93,
  //#endregion

  //#region Kennels
  Kennels = 100,
  KennelByNumber = 101,
  KennelActive = 102,
  KennelInActive = 103,
  KennelPendingApproval = 104,
  //#endregion

  //#region Kennels Renewals
  KennelsRenewals = 110,
  KennelRenewalsReqFees = 111,
  KennelRenewalsReqNotice = 112,
  KennelRenewalsNoticeSent = 113,
  //#endregion

  //#region Administration
  Administration = 120,
  PermitTypes = 121,
  Settings = 122,
  //#endregion
}

export interface AnimalsRegister {
  ID: number;
  RegistrationNumber: string;
  AnimalOwner: string;
  SiteAddress: string;
  Locality: string;
  PropertyOwner: string;
  TagNo: string;
  MicrochipNo: string;
  TagColour: string;
  AnimalName: string;
  Type: string;
  Breed: string;
  Colour: string;
  Gender: string;
  DOB?: Date;
  DOD?: Date;
  Dangerous?: boolean;
  Menacing?: boolean;
  Status: string;
  Status_ENUM?: number;
  LodgedDate?: Date;
  EffectiveFrom?: Date;
  EffectiveTo?: Date;
  Sterilised?: boolean;
  FileNo: string;
  RefNo: string;
  FeesIssued?: number;
  FeesPaid?: number;
  AmountOS?: number;
  Age: string;
  StatusDueStatus: string;
  DebtorNo: string;
  AnimalOwnerContactNo: string;
  AnimalOwnerEmail: string;
  GuideDog?: boolean;
  Working?: boolean;
  DeactiveReason: string;
  AssessmentNo: string;
  DueStatus: string;
  OwnerpostalAddress: string;
  OwnerLocality: string;
  OwnerPostCode: string;
  CustomerBalance: number;
  Microchipped?: boolean;
  PureBreed?: boolean;
  HuntingDog?: boolean;
  Greyhound?: boolean;
  GuardDog?: boolean;
  CertifiedAssistance?: boolean;
  Inactive?: boolean;
  DogExemption?: boolean;
  ExemptionApproved?: boolean;
  ExemptionNo: string;
  HasAlert: boolean;
}

export interface AnimalSettingValue {
  animalsMandatoryBreed: boolean;
  animalsMandatoryPurebreed: boolean;
  animalsMandatoryGender: boolean;
  animalsMandatoryColour: boolean;
  animalsMandatoryAnimalName: boolean;
  animalsMandatoryClubMember: boolean;
  animalsMandatoryCertifiedAssistance: boolean;
  animalsMandatoryDangerous: boolean;
  animalsMandatoryOrganisation: boolean;
  animalsMandatoryMenacing: boolean;
  animalsMandatoryGuardDog: boolean;
  animalsMandatoryWorking: boolean;
  animalsMandatoryGuideDogAssistance: boolean;
  animalsMandatoryDogHomeAdoption: boolean;
  animalsMandatoryObedienceTrained: boolean;
  animalsMandatoryCustomsDog: boolean;
  animalsMandatorySecondaryColour: boolean;
  animalsMandatoryHealthCareCard: boolean;
  animalsMandatoryRestrictedBreed: boolean;
  animalsMandatoryHuntingdog: boolean;
  animalsMandatoryGreyhound: boolean;

  animalsMandatorySterlisedTypeOfProof: boolean;
  animalsMandatoryObedienceTypeOfProof: boolean;
  animalsMandatoryGuideDogTypeOfProof: boolean;
  animalsMandatoryWorkingTypeOfProof: boolean;
  animalsMandatoryCustomsDogTypeOfProof: boolean;
  animalsMandatoryPureBreedTypeOfProof: boolean;
  animalsMandatoryGuardDogTypeOfProof: boolean;
  animalsMandatoryDogHomeAdoptionTypeOfProof: boolean;
  animalsMandatoryHuntingDogTypeOfProof: boolean;
  animalsMandatoryGreyhoundTypeOfProof: boolean;
  corporateSettingsDebtorNumberMandatory: boolean;

  animalsDisplayPurebreed: boolean;
  animalsDisplayClubMember: boolean;
  animalsDisplayOrganisation: boolean;
  animalsDisplayMenacing: boolean;
  animalsDisplayGuardDog: boolean;
  animalsDisplayDogHomeAdoption: boolean;
  animalsDisplayObedienceTrained: boolean;
  animalsDisplayCustomsDog: boolean;
  animalsDisplaySecondaryColour: boolean;
  animalsDisplayHealthCareCard: boolean;
  animalsDisplayCertifiedAssistance: boolean;
  animalsDisplayRestrictedBreed: boolean;
  animalsDisplayHuntingdog: boolean;
  animalsDisplayGreyhound: boolean;
  animalsEnableDOGExemption: string;
  animalsEnablePensionerExpiry: boolean;
  animalsEnableTransfer: boolean;

  animalsLabelBreed: string;
  animalsLabelDOB: string;
  animalsLabelClubMember: string;
  animalsLabelColour: string;
  animalsLabelOrganisation: string;
  animalsLabelMenacing: string;
  animalsLabelHuntingDog: string;
  animalsLabelPureBreed: string;
  animalsLabelWorking: string;
  animalsLabelGreyhound: string;
  animalsLabelGuideDogAssistance: string;
  animalsLabelDangerous: string;
  animalsClassificationLabelPensioner_Sterilised: string;
  animalsClassificationLabelPensioner_NotSterilised: string;
  animalsClassificationLabelNotPensioner_Sterilised: string;
  animalsClassificationLabelNotPensioner_NotSterilised: string;
  corporateSettingsDebtorNumberLabel: string;
  corporateSettingsEnableDebtorUserSelection: boolean;

  animalsRegistrationExpiryDate: string;
  animalsRegistrationExpiryDateOption: number | undefined;
  registrationExpiryCalcEffectiveDate: string;
  corporateSettingsFinanceAllocateDebtorNumber: boolean;
  animalsAutoIssue: boolean;
}

export enum AnimalViewType {
  Active,
  Register,
  DogsByBreed,
  CatsByBreed,
  OtherByBreed,
  AnimalsByType,
  Deceased,
  Inactive,
  LostTags,
  LifeTime,
  QuickRegister,
  DogRegister,
  CatRegister,
  CatBreeders,
  DangerousDogs,
  DogExemptions,
  All,
}

export enum AnimalRenewalViewType {
  RequiresRenewalFee,
  RequiresRenewalNotice,
  NoticeSent,
}
