import { FormAction, FormIdentifier } from "@app/products/crms/model";
import { getActionButtonPermission } from "@common/apis/core-common-apis";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { useFlexibleFetchData } from "@common/hooks/flexible-fetch-data/useFlexibleFetchData";
import { EWatchStatusLoading } from "@common/stores/route/model";
import { routeDataStoreInstance } from "@common/stores/route/store";
import { checkPermissionFormAction } from "@components/cc-check-permission-wrap/util";
import { CCNotFoundPage } from "@components/cc-not-found-page/_index";
import React, { ReactNode, useEffect, useState } from "react";
import { useEffectOnce } from "react-use";

export type TPermissionCheck = {
  formIdentifier: FormIdentifier;
  productType: PRODUCT_TYPE_NUMBER;
  formAction: FormAction | FormAction[];
};
interface ICCCheckPermissionWrapProps {
  onChangeHasPermission?: () => void;
  permission: TPermissionCheck;
  children: ReactNode;
}
export const CCCheckPermissionWrap = ({
  onChangeHasPermission,
  permission,
  children,
}: ICCCheckPermissionWrapProps) => {
  const [hasPermission, setHasPermission] = useState<boolean>(true);

  const slider = useFlexibleFetchData({
    slides: [
      {
        fetch: () =>
          getActionButtonPermission(
            permission.formIdentifier,
            permission.productType
          ),
        handleSuccess: ({ dataFromApi }) => {
          const _hasPermission = checkPermissionFormAction(
            dataFromApi?.data,
            permission.formAction
          );
          setHasPermission(_hasPermission);
        },
      },
    ],
  });

  useEffectOnce(() => {
    slider.fetchApi();
  });

  useEffect(() => {
    routeDataStoreInstance.setLoadingList(
      EWatchStatusLoading.IsLoadingPermission,
      slider.isFetching
    );
  }, [slider.isFetching]);

  useEffect(() => {
    if (onChangeHasPermission && slider.isDone && hasPermission) {
      onChangeHasPermission();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slider.isDone, hasPermission]);

  return hasPermission ? <>{children}</> : <CCNotFoundPage />;
};
