import { FormAction, FormIdentifier } from "@app/products/crms/model";
import {
  DIRECT_DEBIT_MODULE_ITEM_TYPES,
  DIRECT_DEBIT_MODULE_ITEM_TYPES_ODATA,
} from "@app/products/direct-debit/constant";
import { DBRowStateWithoutArchive } from "@app/products/direct-debit/model";
import { directDebitRoute } from "@app/products/direct-debit/route";
import { IntegrationModuleItemType } from "@app/products/direct-debit/system-admin/model";
import { DDModuleItemTypeDeleteButton } from "@app/products/direct-debit/system-admin/module-item-types/[id]/components/buttons/delete/_index";
import { SysAdminModuleItemTypesHistoryTab } from "@app/products/direct-debit/system-admin/module-item-types/[id]/components/slidebar/history/_index";
import { colModuleItemTypes } from "@app/products/direct-debit/system-admin/module-item-types/config";
import { DDSystemAdminBookmark } from "@app/products/direct-debit/system-admin/util";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { LoadingPermissionWrapper } from "@common/hocs/permissions/check-security-loading-wrapper";
import { useCheckPermissions } from "@common/hooks/permissions/useCheckPermissions";
import { nameOfFactory } from "@common/utils/common";
import { CCActionBarNavDropdownNew } from "@components/cc-list-view-action-bar/components/nav-dropdown-new/_index";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import { observer } from "mobx-react-lite";
import React from "react";
import { useHistory } from "react-router-dom";

const nameOf = nameOfFactory<IntegrationModuleItemType>();

export default observer(() => {
  const history = useHistory();

  const { checkPermissions, isLoadingPermission } = useCheckPermissions({
    formIdentifier: FormIdentifier.DirectDebit_Dialog_ModuleItemType,
    productType: PRODUCT_TYPE_NUMBER.DirectDebit,
  });

  useCCListViewActionBar({
    title: directDebitRoute.name,
    leftComponents: [<CCActionBarNavDropdownNew />],
    centerComponents: [
      <CCNavButton
        title="New"
        onClick={() => {
          history.push(`${DIRECT_DEBIT_MODULE_ITEM_TYPES}/new`);
        }}
        disabled={
          !checkPermissions([
            FormAction.CORE_ALLOW_NEW,
            FormAction.CORE_ALLOW_SAVE,
          ])
        }
      />,
      <DDModuleItemTypeDeleteButton
        disabled={!checkPermissions(FormAction.CORE_ALLOW_DELETE)}
      />,
    ],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        linkUrl={DIRECT_DEBIT_MODULE_ITEM_TYPES}
        productType={PRODUCT_TYPE.DirectDebit}
        recordType={RECORDTYPE.Receipting_Integration_Module_Item_Type}
        detail={DDSystemAdminBookmark.getBookmarkListViewDisplayName}
        displayName={
          DDSystemAdminBookmark.getBookmarkListViewDetailRecordModuleItemType
        }
        listViewDetail={() =>
          DDSystemAdminBookmark.getBookmarkListViewDetail("Module Item Types")
        }
        listViewDisplayName={
          DDSystemAdminBookmark.getBookmarkListViewDisplayName
        }
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      {
        title: "History",
        component: <SysAdminModuleItemTypesHistoryTab />,
      },
    ],
  });

  return (
    <LoadingPermissionWrapper isLoadingPermission={isLoadingPermission}>
      <CCProductListView
        columnFields={colModuleItemTypes}
        dataUrl={DIRECT_DEBIT_MODULE_ITEM_TYPES_ODATA}
        primaryField={nameOf("Integration_Module_Item_Type_ID")}
        state={{
          sort: [{ field: nameOf("Name"), dir: "desc" }],
          filter: {
            filters: [
              {
                field: nameOf("Sys_DBRowState_View_Filter"),
                operator: "eq",
                value: DBRowStateWithoutArchive.Active,
              },
            ],
            logic: "and",
          },
        }}
      />
    </LoadingPermissionWrapper>
  );
});
