import { InspectionType } from "@app/core/inspections/[id]/model";
import { APIResponse } from "@common/apis/model";
import { useFlexibleFetchData } from "@common/hooks/flexible-fetch-data/useFlexibleFetchData";
import { useCancelRequest } from "@common/hooks/useCancelRequest";
import { usePageType } from "@common/hooks/usePageType";
import {
  getInspectionTypeById,
  getInspectionTypeLOVs,
} from "@common/pages/settings/lookups/inspection-types/_id/api";
import {
  InspectionTypeLovs,
  TApiInspectionType,
} from "@common/pages/settings/lookups/inspection-types/_id/model";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import { NoData } from "@components/no-data/NoData";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceHeading } from "@components/reference-sidebar/ProductReferenceHeading";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { find } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export const InspectionTypesDetailsTab = observer(() => {
  //#region Store ========/
  const { dataForms } = useFlexibleFormStore();
  const { lastSelectedId } = useCCProductListViewStore();
  //#endregion Store =====/

  //#region State ========/
  const [inspectionType, setInspectionType] = useState<InspectionType>();
  const [inspectionTypeLovs, setInspectionTypeLovs] =
    useState<InspectionTypeLovs>();
  //#endregion State =====/

  //#region Hook ========/
  const { cancelToken } = useCancelRequest();
  const { isManagePage, isProductListView } = usePageType();
  //#endregion Hook =====/

  //#region VARIABLE ========/
  const { id } = useParams<{ id: string }>();
  const inspectionTypeId: string | number = id ? id : lastSelectedId;
  //#endregion VARIABLE =====/

  //#region API ========/
  const inspectionTypesDetailsTabSlider = useFlexibleFetchData({
    slides: [
      {
        fetch: [
          () => getInspectionTypeLOVs(cancelToken()),
          () => getInspectionTypeById(+inspectionTypeId, cancelToken()),
        ],
        handleSuccess: ({ dataFromApi }) => {
          const [
            _inspectionTypeLovsIdentityPacket,
            _inspectionTypeIdentityPacket,
          ]: [
            APIResponse<TApiInspectionType["Lovs"]>,
            APIResponse<TApiInspectionType["Value"]>
          ] = dataFromApi;

          const _inspectionTypeLovs =
            _inspectionTypeLovsIdentityPacket.data?.ReturnObj;
          const _inspectionType = _inspectionTypeIdentityPacket.data?.ReturnObj;

          setInspectionTypeLovs(_inspectionTypeLovs);
          setInspectionType(_inspectionType);
        },
      },
    ],
  });
  //#endregion API =====/
  useEffect(() => {
    if (isManagePage) {
      setInspectionType(dataForms?.GeneralForm as InspectionType);
      setInspectionTypeLovs(dataForms?.GeneralFormLovs as InspectionTypeLovs);
    }
    if (isProductListView) {
      inspectionTypesDetailsTabSlider.fetchApi();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isManagePage, isProductListView, dataForms]);

  if (inspectionTypesDetailsTabSlider.isFetching) return <Loading isLoading />;
  if (inspectionTypesDetailsTabSlider.errorComponent) {
    return inspectionTypesDetailsTabSlider.errorComponent;
  }
  if (!inspectionType) return <NoData />;

  return (
    <ProductReferenceBody>
      <ProductReferenceHeading
        title={"Inspection type ID:"}
        value={inspectionType?.InspectionType_ID?.toString()}
        primary
      />
      <ProductReferenceBlock>
        <ProductReferenceRow title={"Name:"} value={inspectionType?.Name} />
        <ProductReferenceRow
          title={"Classification:"}
          value={
            find(inspectionTypeLovs?.InspClass, [
              "Key",
              inspectionType.InspectionClassification_ENUM,
            ])?.Value
          }
        />
        <ProductReferenceRow
          title={"Group:"}
          value={
            find(inspectionTypeLovs?.Group, ["Key", inspectionType.Group])
              ?.Value
          }
        />
        <ProductReferenceRow
          title={"Code:"}
          value={`${inspectionType?.Code} - ${inspectionType?.Description}`}
        />
      </ProductReferenceBlock>
    </ProductReferenceBody>
  );
});
