import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { handleCatchResponse } from "@common/apis/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { IdentityPacket } from "@common/models/identityPacket";

export const deleteStatusCode = async (
  data: Svc_RecordDetails[]
): Promise<APIResponse<IdentityPacket>> => {
  try {
    return await CoreAPIService.getClient().delete(
      `api/receipting/internal/directdebitstatus/delete`,
      {
        data,
      }
    );
  } catch (error) {
    return handleCatchResponse(error);
  }
};

interface Svc_RecordDetails {
  ID: number;
  RecordType_ENUM: RECORDTYPE;
}
