import { Attachment } from "@app/core/documents/model";
import { AnimalType } from "@app/products/animals/model";
import { RecordSourceIdentifier } from "@app/products/crms/[id]/components/forms/components/child-screens/general/components/form-element/model";
import { NewEventCloneParams } from "@app/products/crms/components/dialogs/clone-new-event/model";
import { FormAction, FormIdentifier } from "@app/products/crms/model";
import { LoginAuthenticationType } from "@app/products/town-planning/ppr/[id]/components/input-picker/officer-picker/model";
import { DataTable } from "@app/products/town-planning/ppr/psa-referrals/_id/components/dialogs/referral/model";
import {
  BubbleUpIdentifier,
  Contact_Relation,
} from "@app/products/waste-water/[id]/model";
import {
  ACCESSRIGHTS,
  AddressBookSection,
  ContactClassification,
  ContactMethodPreferred,
  IssueSource,
  PortalUserRole,
  RosterType,
  UI_ContactRelationship_Mode,
  UI_ContactRelationship_Workflow,
} from "@common/constants/enumerations";
import { RECORDTYPE } from "@common/constants/recordtype";
import { Address } from "@common/input-pickers/address/model";
import { AccessControl } from "@common/models/accessControl";
import { Contact } from "@common/models/contact";
import { IdentityPacket } from "@common/models/identityPacket";
import { IKeyValuePacket } from "@common/models/keyValuePacket";
import { Action } from "@common/pages/actions/model";
import { SiteUser } from "@common/pages/settings/security/site-users/_id/model";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
export class CRMSEventRegister {
  // LOAD BY DEFAULT
  id?: string | number;
  ID?: string | number;
  Target?: string;
  Source?: string;
  Location?: string;
  Contact?: string;
  ContactInfo?: string;
  Status?: string;
  Status_ENUM?: number;

  DueStatus?: string;
  Recorded?: string;
  RecordedBy?: string;
  OrgStructure?: string;
  ActionOfficer?: string;
  Coordinator?: string;
  WorkDate?: string;
  AssetsRefNo?: string;
  AddRefNo?: string;
  OnBeHalfOf?: string;

  ItSupport?: boolean | false;
  Safety?: boolean;
  Risk?: boolean;
  Vip?: boolean;
  RefNo?: string;
  Description?: string;
  Type?: string;
  ReceivedDate?: string;
  FirstActionDate?: string;
  Manager?: string;
  RecordedDate?: string;
  Priority?: string;
  Recipient?: string;
  RescheduledDate?: string;
  AckTarget?: string;
  AckDate?: string;
  AckHow?: string;
  Manual?: string;
  Confidentiality?: string;
  RequestorType?: string;
  Requestor?: string;
  Address?: string;
  LocationType?: string;
  LocationDetails?: string;
  ApplicantName?: string;
}
export class ICRMSEventRecordsState {
  actions: number = 0;
  comments: number = 0;
  contacts: number = 0;
  documents: number = 0;
  fees: number = 0;
  inspections: number = 0;
  samples: number = 0;
}
export interface CRMSFormEventLOVs {
  EventTypes: IKeyValuePacket[];
  SourceTypes: IKeyValuePacket[];
  Confidentialities: IKeyValuePacket[];
  RequestorTypes: IKeyValuePacket[];
}

export interface Event extends DTOBaseClass_Standard {
  Event_Id: number;
  Event_SeqId: number;
  Event_ReferenceNo: string;
  Event_Description: string;
  ServiceStandard_Id: number;
  ServiceStandard: ServiceStandard_LightWeight2;
  ServiceStandardCategory_Id: number;
  ServiceStandardSubCategory_Id: number;
  ServiceStandardType_ENUM: CS_ServiceStandardType;
  ServiceStandardType_Name: string;
  EventStatus_ENUM: CS_EventStatus;
  PreviousEventStatus_ENUM: CS_EventStatus;
  EventStatus_Name: string;
  UpdateStatus: string;
  UpdateStatus_Date: Date | null;
  UpdateStatus_UpdatedBy: string;
  UpdateStatus_UpdatedBy_ID: number | null;
  Coordinator_SiteUser_ID: number | null;
  Coordinator_Email: string;
  ActionOfficer_SiteUser_ID: number | null;
  ActionOfficer_Email: string;
  Type_ID: number | null;
  Type_Display: string;
  Tier_ID: number | null;
  Tier_Display: string;
  Workstream_ID: number | null;
  Workstream_Display: string;
  Source_ID: number | null;
  Source_Display: string;
  Event_ReferenceNoAdditional: string;
  CustomerServiceValidation: boolean | null;
  IncidentDate: Date | null;
  Confidentiality_ENUM: CS_Confidentiality;
  Confidentiality_Name: string;
  RequestorType_ID: number | null;
  RequestorType_Display: string;
  RequestedBy_Contact_RID: number | null;
  RequestedBy_Description: string;
  RequestedBy: Contact_Relation;
  RequestedBy_SiteUser_ID: number | null;
  RequestedBy_SiteUser: SiteUser;
  RequestedBy_SiteUser_Description: string;
  RequestedBy_PortalUser_ID: number | null;
  RequestedBy_PortalUser: PortalUser;
  RequestedBy_PortalUser_Description: string;
  RequestedBy_PortalUser_Profile: PortalUserProfile_ColdChainServiceProvider;
  RequestedBy_Anonymous: boolean | null;
  OnBehalfOf_Contact_RID: number | null;
  OnBehalfOf_Description: string;
  OnBehalfOf: Contact_Relation;
  OnBehalfOf_SiteUser_ID: number | null;
  OnBehalfOf_SiteUser: SiteUser;
  OnBehalfOf_SiteUser_Description: string;
  Complainee: Contact_Relation;
  Complainee_RID: number | null;
  Provider: Contact_Relation;
  Provider_RID: number | null;
  RequestorProvider: Contact_Relation;
  RequestorProvider_RID: number | null;
  RecordType_ENUM: number | null;
  RecordType_Name: string;
  Record_ID: number | null;
  LocationType_ID: number | null;
  LocationType_Display: string;
  IssueLocation: CS_IssueLocation;
  Location_ID: number | null;
  Premises_ID: number | null;
  Location_LicensedName: string;
  Register_ID: number | null;
  Register_Description: string;
  WasteWater_InstallationID: number | null;
  WasteWater_InstallationDescription: string;
  WasteWater_ProductID: number | null;
  WasteWater_ProductDescription: string;
  WasteWater_SchemeID: number | null;
  WasteWater_SchemeDescription: string;
  WasteWater_SchemeName: Address;
  ControlledSubstance_LicenceID: number | null;
  ControlledSubstance_LicenceDescription: string;
  Animal_RegisterID: number | null;
  Animal_Name: string;
  Animal_RegisterIDs: string;
  Kennel_KennelID: number | null;
  Kennel_RegisterIDs: string;
  Infringements_IDs: string;
  WasteWater_SystemID: number | null;
  Asset: Asset;
  AssetEnquiryRefNo: string;
  Asset_WorkOrderKEY: string;
  Flag_SuspendAssetComm: boolean | null;
  Asset_LastSyncDate: Date | null;
  WorkOrder_Status: string;
  WorkOrder_StatusLastUpdatedDate: Date | null;
  WorkOrder_AssetNumbers: string;
  WorkOrder_RevisedAssetNumbers: string;
  WorkOrder_ActualStartDate: Date | null;
  WorkOrder_ActualEndDate: Date | null;
  WorkIdentifier_KWD: number | null;
  WorkIdentifier_Name: string;
  WorkOrderAdditionalEmergencyServices_KWD: number | null;
  WorkOrderAdditionalEmergencyServices_Name: string;
  AssetEnquiryJobNo: string;
  WorkOrder_RejectedDate: Date | null;
  RejectedReason: string;
  StatusChangeReason: string;
  Urgency: boolean | null;
  Flags_ReturnCall: boolean | null;
  CallReturned: boolean | null;
  Flags_CustomFlag1: boolean | null;
  Flags_CustomFlag2: boolean | null;
  Flags_CustomFlag3: boolean | null;
  Bookmarked: boolean | null;
  Flag_LockEvent: boolean | null;
  Flag_AnnualReportCase: boolean | null;
  DateReceived: Date | null;
  ReceivedBy_SiteUser_ID: number | null;
  ReceivedBy_SiteUser_DisplayName: string;
  FirstActionDate: Date | null;
  DateRecorded: Date | null;
  RecordedBy_SiteUser_ID: number | null;
  RecordedBy_SiteUser_DisplayName: string;
  TargetDate: Date | null;
  DateOriginalTarget: Date | null;
  DateOpened: Date | null;
  DateSentForAction: Date | null;
  CountNumberOfTimesRescheduled: number | null;
  CountNumberOfTimesAllocated: number | null;
  CountNumberOfTimesCategorised: number | null;
  CountNumberOfTimesResent: number | null;
  DateAcknowledged: Date | null;
  AcknowledgedBy_ID: number | null;
  ClosureDetails: string;
  ClosedBy_ID: number | null;
  ClosedBy: string;
  DateClosed: Date | null;
  FinalNotificationText: string;
  DateFinalNoticeSent: Date | null;
  FinalNoticeSentMethod_ID: number | null;
  FinalNoticeSentMethod_Display: string;
  NoOfvisits_SchoolPatrols: number | null;
  TimeTaken_SchoolPatrols: number | null;
  NoOfExpitations_SchoolPatrols: number | null;
  TimeTaken_NuisanceAct: number | null;
  ComplexityGrouped_NuisanceAct: number | null;
  NoOfAbatement_NuisanceAct: number | null;
  NoOfExpitations_NuisanceAct: number | null;
  ReopenReason: string;
  DateReopened: Date | null;
  SpecialInterest_Email_ManualEntries: string;
  DateRescheduled: Date | null;
  RescheduledReason: string;
  RescheduleNotificationNeeded: boolean | null;
  DateRescheduledNotification: Date | null;
  ParentEvent_Id: number | null;
  ParentEvent_ReferenceNo: string;
  ParentEvent_Description: string;
  SameIssue_ID: number | null;
  SameIssue_RelatedEvent_Id: number | null;
  SSRecords_Classification: string;
  SSRecords_FileNumber: string;
  EventRecords_ParentFileNumber: string;
  EventRecords_FileNumber: string;
  ForceCreateContainer: boolean | null;
  Complainee_FirstName: string;
  Complainee_LastName: string;
  Complainee_Email: string;
  Complainee_Phone: string;
  Complainee_RegistrationNumber: string;
  Complainee_ServiceProvider: string;
  OnBehalfOf_FirstName: string;
  OnBehalfOf_LastName: string;
  OnBehalfOf_Email: string;
  OnBehalfOf_Phone: string;
  RequestedBy_FirstName: string;
  RequestedBy_LastName: string;
  RequestedBy_Email: string;
  RequestedBy_Phone: string;
  RequestedBy_TTY: string;
  RequestedBy_GenderPronoun: string;
  RequestedBy_PreferredContactMethod_ENUM: number | null;
  RequestedBy_PreferredContactMethod_Name: string;
  RequestedBy_PostalAddress_UnitNo: string;
  RequestedBy_PostalAddress_StreetNo: string;
  RequestedBy_PostalAddress_StreetName: string;
  RequestedBy_PostalAddress_Suburb: string;
  RequestedBy_PostalAddress_State: string;
  RequestedBy_PostalAddress_Postcode: string;
  HasColdChainBreach: boolean | null;
  ColdChainBreach: ColdChainBreach;
  HasCaseDetailIncidentCheckListFlood: boolean | null;
  CaseDetailIncidentCheckListFlood: CaseDetailIncidentCheckListFlood;
  HasCaseDetailIncidentCheckListStorm: boolean | null;
  CaseDetailIncidentCheckListStorm: CaseDetailIncidentCheckListStorm;
  HasCaseDetailTreeManagement: boolean | null;
  CaseDetailTreeManagement: CaseDetailTreeManagement;
  HasCaseDetailDrinkingWater: boolean | null;
  CaseDetailDrinkingWater: CaseDetailDrinkingWater;
  HasCaseDetailDisabilityWorker: boolean | null;
  CaseDetailDisabilityWorker: CaseDetailDisabilityWorker;
  HasCaseDetailAnimalAttack: boolean | null;
  CaseDetailAnimalAttack: CaseDetailAnimalAttack;
  Issues: Issues;
  HasIssues: boolean | null;
  HasCaseDetailPublicLiabilitySimple: boolean | null;
  CaseDetailPublicLiabilitySimple: CaseDetailPublicLiabilitySimple;
  HasCaseDetailAnimalsWandering: boolean | null;
  CaseDetailAnimalsWandering: CaseDetailAnimalsWandering;
  HasCaseDetailBins: boolean | null;
  CaseDetailBins: CaseDetailBins;
  HasCaseDetailCatTrap: boolean | null;
  CaseDetailCatTrap: CaseDetailCatTrap;
  HasCaseDetailSyringesAndSharps: boolean | null;
  CaseDetailSyringesAndSharps: CaseDetailSyringesAndSharps;
  HasCaseDetailEnvironmentalHealth: boolean | null;
  CaseDetailEnvironmentalHealth: CaseDetailEnvironmentalHealth;
  HasCaseDetailComplaintsHandling: boolean | null;
  CaseDetailComplaintsHandling: CaseDetailComplaintsHandling;
  HasCaseDetailMyPlaceYouthCentre: boolean | null;
  CaseDetailMyPlaceYouthCentre: CaseDetailMyPlaceYouthCentre;
  HasCaseDetailPublicLiabilityComprehensive: boolean | null;
  CaseDetailPublicLiabilityComprehensive: CaseDetailPublicLiabilityComprehensive;
  HasCaseDetailVehicleImpoundChecklist: boolean | null;
  CaseDetailVehicleImpoundChecklist: CaseDetailVehicleImpoundChecklist;
  NewEvent_DeferCategorising: boolean;
  ServiceStandardType_For_Picker: CS_ServiceStandardType | null;
  FocussedTab: string;
  SendForAction: boolean | null;
  Activity: Action;
  ContactRelation: Contact_Relation;
  _ContactRelation_Workflow: UI_ContactRelationship_Workflow;
  _ContactRelation_Mode: UI_ContactRelationship_Mode;
  Comment: Comment;
  Attachment: Attachment[];
  SpecialInterest: AccessControl[];
  Readers: AccessControl[];
  Writers: AccessControl[];
  ServiceDirection_AttachmentID: number | null;
  ServiceDirection_CreatedDate: Date | null;
  ServiceDirection_CreatedBy: string;
  ServiceDirection_CreatedBy_ID: number | null;
  Flag_PhoneMessage: boolean | null;
  WorkflowActions: EventActionTypeLink[];
  Location_FormattedSingleLine: string;
  Location_AssessmentNo: string;
  Legal_Description: string;
  Location_ParcelID: string;
  RequestedBy_Address: string;
  RequestedBy_AssessmentNo: string;
  RequestedBy_Contact_ID: number | null;
  Requestor: Contact;
  RequestedBy_DisplayName: string;
  OnBehalfOf_Contact_ID: number | null;
  OnBehalfOf_DisplayName: string;
  ActionOfficer_DisplayName: string;
  ActionOfficer_Position: string;
  Coordinator_DisplayName: string;
  Coordinator_Position: string;
  OrgStructure: string;
  Category: string;
  KB_Identity_ENUM: CS_KnowledgeBase_CreateEventIdentity | null;
  KB_Identity_Name: string;
  WSR_IsWebServiceRequest: boolean | null;
  Event_WebDescription: string;
  WSR_Source_ENUM: WSR_Source | null;
  WSR_Source_Name: string;
  WSR_Category: string;
  WSR_SubCategory: string;
  WSR_SubCategoryLevel3: string;
  WSR_SubCategoryLevel4: string;
  WSR_Errors: string;
  WSR_NewRequestValidation_Errors: string;
  WSR_Password: string;
  WSR_Status_ENUM: CS_WebServiceRequestStatus | null;
  WSR_Status_Name: string;
  WSR_RequestType: string;
  WSR_AgentName: string;
  Flag_LodgedAfterHours: boolean | null;
  Flag_LodgedByAfterHoursService: boolean | null;
  Flag_Stat: boolean | null;
  RecipientType_ENUM: CS_RecipientType | null;
  RecipientType_Name: string;
  Recipient_Councillor_ContactID: number | null;
  Recipient_Councillor_DisplayName: string;
  BlobStore_Id: number | null;
  HasServiceDirection: boolean;
  BulletinBoardID: number | null;
  BulletinBoardIDStr: string | null;
  Flag_ITSupport: boolean | null;
  VIP_ID: number | null;
  VIP_Display: string;
  isVIPExternal: boolean | null;
  VIP_Contact_ID: number | null;
  VIP_Contact_RID: number | null;
  VIP_ContactName: string;
  VIP_Contact_External: Contact_Relation;
  VIP_Contact_Internal: SiteUser;
  VIP_TypeID: number | null;
  VIP_Type: string;
  VIP_RelationshipOwnerID: number | null;
  VIP_RelationshipOwner: string;
  SafetyReason: string;
  VIPIssue: boolean;
  SafetyIssue: boolean;
  RiskIssue: boolean;
  Flag_HardRubbish: boolean | null;
  Flag_HardRubbishAdminOverride: boolean | null;
  Flag_HardRubbishCancelled: boolean | null;
  Flag_HardRubbishMissed: boolean | null;
  HardRubbish_CollectionID: number | null;
  Flag_HardRubbishCollected: boolean | null;
  HardRubbish_DocumentSentDate: Date | null;
  HardRubbish_CollectionDate: Date | null;
  HardRubbish_ItemsCollectedID: number | null;
  HardRubbish_ItemsCollectedDisplay: string;
  HardRubbish_ItemsCollectedWeb: string;
  HardRubbish_PickupLocation: string;
  HardRubbish_ItemsCollectedWebDisplay: string;
  HardRubbish_NumItemsCollected: string;
  BinSizeID: number | null;
  BinSizeIDStr: string | null;
  BinTypeID: number | null;
  BinTypeIDStr: string | null;
  RecategorisationComment: string;
  ReassignComment: string;
  WizardSteps: WizardStep[];
  CustomerContacted: number | null;
  IncidentTypes: EventLookupTableLNK[];
  IncidentMLVAPattern: string;
  IncidentCause: string;
  IncidentNoOfCases: number | null;
  IncidentSupplierDetails: string;
  Admin_Action: string;
  DateRiskDOB: Date | null;
  Flag_QualityCheckRaisedCorrectly: boolean | null;
  QualityCheckItems: EventLookupTableLNK[];
  QualityCheckItems_Display: string;
  QualityCheckComment: string;
  Flag_QualityCheckReviewRequired: boolean | null;
  SpecialComments: string;
  Flag_Communication: boolean | null;
  Flag_UnreadEvent: boolean | null;
  Flag_Payroll: boolean | null;
  IsAnimal: boolean | null;
  SaveTriggers: EventUpdateTriggers[];
  EnvHealthIssue_ID: number | null;
  EnvHealthAction_ID: number | null;
  EnvHealthResult_ID: number | null;
  EnvHealthFoodType_ID: number | null;
  Permit_ID: number | null;
  TP_ApplicationID: number | null;
  EMS_EventBookingID: number | null;
  ParkingPermit_ID: number | null;
  Shell_Record_ID: number | null;
  Shell_RecordType_ENUM: RECORDTYPE | null;
  BubbleUp: BubbleUpIdentifier[];
}

export interface ServiceStandard_LightWeight2 {
  ServiceStandard_Id: number;
  ServiceStandardMapper_Id: number;
  ServiceStandardCategory_Id: number;
  Category: string;
  ServiceStandardSubCategory_Id: number;
  SubCategory: string;
  SubCategoryLevel3: string;
  SubCategoryLevel4: string;
  ConfidentialEnableAddAttachment: boolean | null;
  FullName: string;
  Name: string;
  Description: string;
  ServiceStandardType_ENUM: CS_ServiceStandardType;
  ServiceStandardType_Name: string;
  Org1_Id: number | null;
  Org2_Id: number | null;
  Org3_Id: number | null;
  Org4_Id: number | null;
  Org5_Id: number | null;
  DistributedWithin: number | null;
  OrgStructure: string;
  isCollectionDate: boolean | null;
  AutomaticAllocation: boolean | null;
  AllowAnonymous: boolean | null;
  AllowRecategorise: boolean | null;
  AllowReallocateOfficer: boolean | null;
  IsComplaineeEnabled: boolean | null;
  ComplaineeLabel: string;
  EnableSchoolPatrols: boolean | null;
  EnableNuisanceAct: boolean | null;
  SendToCoordinator: boolean | null;
  Coordinator_SiteUser_ID: number | null;
  Coordinator_DisplayName: string;
  Coordinator_Email: string;
  Coordinator_Position: string;
  SendToActionOfficer: boolean | null;
  ActionOfficer_SiteUser_ID: number | null;
  ActionOfficer_DisplayName: string;
  ActionOfficer_Email: string;
  ActionOfficer_Position: string;
  Flag_ActionOfficer_StaffMember: boolean | null;
  RosterType_ENUM: RosterType | null;
  RosterType_Name: string;
  Confidentiality_ENUM: CS_Confidentiality;
  Confidentiality_Name: string;
  IssueLocationTypes: EventLookupTableLNK[];
  IssueLocationTypes_UseDefaults: boolean | null;
  ResolutionResponses_UseDefaults: boolean | null;
  ResolutionResponses: EventLookupTableLNK[];
  Response1: string;
  Response2: string;
  Response3: string;
  EnableQuickClose: boolean | null;
  DisplayInBulletinBoard: boolean | null;
  DisplayAsInternetChoice: boolean | null;
  Flag_MobileChoice: boolean | null;
  Flag_InformationOnly: boolean | null;
  Flag_WebHouseNumberMandatory: boolean | null;
  Flag_WebEmailMandatory: boolean | null;
  Flag_WebHomePhoneMandatory: boolean | null;
  Flag_WebWorkPhoneMandatory: boolean | null;
  Flag_WebLookupCouncilLocation: boolean | null;
  Flag_WebEnableUploadControl: boolean | null;
  Flag_WebShowCouncillor: boolean | null;
  CategoryWebInstructions: string;
  CategoryInternetSearchKey: string;
  CommunityEngagementSearchKey: number | null;
  CategoryInternetFriendlyName: string;
  InternetPageTitle: string;
  CategorySortIndex: number;
  CategoryDescriptionTextOption1: string;
  CategoryDescriptionTextOption2: string;
  CategoryDescriptionCheckboxes: string;
  CategoryUploadControlAdditionalText: string;
  InternetPageSubTitle: string;
  InternetTags: string;
  InternetParentServiceStandard_Id: number | null;
  NeedAutoPopupInstructions: boolean | null;
  IsAvailableOnlyToOwners: boolean | null;
  ConfirmationContent: string;
  IssueLocationLabel: string;
  DisplayAsCRMSChoice: boolean | null;
  SpecialInterest_SiteUser_IDs: number[];
  SpecialInterest_Email_ManualEntries: string;
  Flag_SpecialInterest_Email_RequestorOrgManager: boolean | null;
  SpecialInterest_Email_RequestorOrgManagerLevel_ENUM: SpecialInterestRequestorOrgManagerLevel | null;
  SpecialInterest_Email_RequestorOrgManagerLevel_Name: string;
  SpecialInterest_Email_WhenCreated: boolean | null;
  SpecialInterest_Email_WhenClosed: boolean | null;
  SpecialInterest_Email_WhenOverdue: boolean | null;
  SpecialInterest_Email_WhenRecategorised: boolean | null;
  SpecialInterest_Email_WhenRescheduled: boolean | null;
  SpecialInterest_EmailIncludeDescriptionInSubject: boolean | null;
  SpecialInterest_Email_WhenSentForAction: boolean | null;
  EnableTargetDays: boolean | null;
  TargetInDays: boolean | null;
  Targets_Acknowledgement: boolean | null;
  Targets_Standard_Acknowledgement: number | null;
  Targets_Standard_Actual_Completion: number | null;
  Targets_Standard_Admin_Completion: number | null;
  Targets_Urgent_Acknowledgement: number | null;
  Targets_Urgent_Actual_Completion: number | null;
  Targets_Urgent_Admin_Completion: number | null;
  Targets_Max_Completion_Days: number | null;
  Targets_IncludeWeekendInCalculation: boolean | null;
  Targets_AllowRescheduling: boolean | null;
  Targets_RescheduledNotifyCustomer: boolean | null;
  Targets_FinalNotification: boolean | null;
  Targets_AllowReschedulingOnUrgent: boolean | null;
  Enable_InternalClosureComments: boolean | null;
  CaseDetails_Form_ENUM: CS_CaseDetailType | null;
  CaseDetails_Name: string;
  CaseDetails_Label: string;
  SendToActionSendEmailToRequestor: boolean | null;
  ReSendToActionSendEmailToRequestor: boolean | null;
  SendToActionSendSMSToRequestor: boolean | null;
  ReSendToActionSendSMSToRequestor: boolean | null;
  ClosureSendSMSToRequestor: boolean | null;
  Alerts_EnableEscalationAlerts: boolean | null;
  Alerts_Escalation1_Officer_SiteUser_ID: number | null;
  Alerts_Escalation1_Officer: SiteUser;
  Alerts_Escalation1_Days: number | null;
  Alerts_Escalation2_Officer_SiteUser_ID: number | null;
  Alerts_Escalation2_Officer: SiteUser;
  Alerts_Escalation2_Days: number | null;
  Alerts_EnableCouncillorAlerts: boolean | null;
  Alerts_Councillor_RequestSent: boolean | null;
  Alerts_Councillor_RequestClosed: boolean | null;
  Alerts_Councillor_Manager1: boolean | null;
  Alerts_Councillor_Manager2: boolean | null;
  Alerts_Councillor_Manager3: boolean | null;
  Alerts_Councillor_Manager4: boolean | null;
  Alerts_Councillor_Manager5: boolean | null;
  Alerts_Councillor_Other: boolean | null;
  Alerts_Councillor_Other_Emails: string;
  Records_Classification: string;
  Records_FileNumber: string;
  Records_FileNoType_ENUM: Records_FileNoType | null;
  Records_FileNoType_Name: string;
  Records_CategoryCode: string;
  HasSpecialInterest: boolean | null;
  HasCaseDetails: boolean | null;
  HasAlerts: boolean | null;
  Flag_Inspection: boolean | null;
  IsNotice: boolean | null;
  IsHealth: boolean | null;
  IsLocalLaws: boolean | null;
  HasAsset: boolean | null;
  Flag_AssetIsMandatory: boolean | null;
  Flag_AssetSendToAssetSystem: boolean | null;
  Flag_AssetSendToAssetSystemOnClosure: boolean | null;
  AssetServiceCode: string;
  AssetSubjectCode: string;
  AssetCategoryCode: string;
  AssetJobPriority: string;
  HasWasteWater: boolean | null;
  HasControlledSubstance: boolean | null;
  SpecialInterest: AccessControl[];
  Readers: AccessControl[];
  Writers: AccessControl[];
  EventReaders: AccessControl[];
  EventWriters: AccessControl[];
  ServiceDirection_DocumentTemplate_ID: number | null;
  Flag_ITSupport: boolean | null;
  Flag_ExternalUser: boolean | null;
  Flag_ReferenceNumberAdditional: boolean | null;
  ReferenceNumberAdditionalLabel: string;
  OnBehalfOfType_ENUM: CS_OnBehalfOfType | null;
  OnBehalfOfType_Name: string;
  SendToActionSendEmailToOnBehalfOf: boolean | null;
  ReSendToActionSendEmailToOnBehalfOf: boolean | null;
  Flag_AssessmentNo: boolean | null;
  Flag_PropertySearch: boolean | null;
  Flag_HardRubbish: boolean | null;
  Flag_HardRubbishPicker: boolean | null;
  Flag_HardRubbishCollectionItemLookup: boolean | null;
  HardRubbish_CollectionItemLookupTableID: number | null;
  HardRubbish_CollectionItemLookupTableName: string;
  HardRubbish_CollectionItemUserDefinedValue: string;
  HardRubbish_CollectionItemName: string;
  Flag_HardRubbishAllocationManual: boolean | null;
  HardRubbish_AllocationBusinessDays: number | null;
  HardRubbish_CollectionsPerYear: number | null;
  Flag_HardRubbishScheduleEventOnSent: boolean | null;
  HardRubbish_RestrictionsPerMonth: number | null;
  HardRubbish_RestrictionsAlert: string;
  HardRubbish_MessagePrompt: string;
  HardRubbish_MessageItemsOptions: string;
  Flag_HardRubbishMessagePrompt: boolean | null;
  Flag_RequestorAddressMandatory: boolean | null;
  Flag_AfterHoursSendEmail: boolean | null;
  AfterHoursEmailAddress: string;
  Flag_IncidentTypeLookup: boolean | null;
  FSRCode_ENUM: FSR_COMPLAINTTYPE | null;
  Flag_EventClosureShowHealthFields: boolean | null;
  FSRCode_Name: string;
  DefaultEventType_ID: number | null;
  Tier_ID: number | null;
  EnableTierLookup: boolean | null;
  EnableInformationOrProcedureDisplay: boolean | null;
  EnableCustomerContacted: boolean | null;
  Flag_SimpleForm: boolean | null;
  Flag_Payroll: boolean | null;
  IsAnimal: boolean | null;
  IsKennels: boolean | null;
  Flag_System: boolean | null;
  Flag_MeAsRequestor: boolean | null;
  Flag_AddAttachment: boolean | null;
  Flag_ShowMap: boolean | null;
  Flag_LockEvent: boolean | null;
  CustomerServiceValidation: string;
  IsEnableBinsLookUp: boolean | null;
  IsAnimalsLookUpEnabled: boolean | null;
  Flag_WorkflowCreateActions: boolean | null;
  WorkflowActions: ServiceStandardActionTypeLink[];
  LocalLawPermitType_ID: number | null;
  IsParkingPermit: boolean | null;
  ParkingPermitType_ID: number | null;
  IsTownPlanning: boolean | null;
  TPApplicationCategory_ID: number | null;
  IsEMS: boolean | null;
  AutoCreateEMSEvent: boolean | null;
  EmailRequestorOnSchedule: boolean | null;
  WorkIdentifier_KWD: number | null;
  WorkIdentifier_Name: string;
  IsBuilding: boolean | null;
  IsInfringements: boolean | null;
  IsLandManagement: boolean | null;
}

export enum CS_ServiceStandardType {
  Default = 0,
  Request = 1,
  Incident = 2,
}

export enum CS_EventStatus {
  Default = 0,
  Draft = 1,
  Pending = 2,
  Open = 3,
  OnHold = 4,
  Closed = 5,
  Scheduled = 6,
  NewMatter = 7,
  InitialConsideration = 8,
  Assessment = 9,
  TakingAction = 10,
  OutcomeAndClosure = 11,
  ApprovedForClosure = 12,
  UnderInvestigation = 13,
  Risk = 14,
  VIP = 15,
  Safety = 16,
  Alert = 17,
  Urgent = 18,
}

export enum CS_Confidentiality {
  Default = 0,
  NonConfidential = 1,
  Confidential = 2,
  Sensitive = 3,
}

export interface PortalUser extends Contact {
  MemberUserName: string;
  MemberGUID: string;
  Salutation: string;
  MiddleName: string;
  ContactMethodPreferred_ENUM: ContactMethodPreferred;
  ContactMethodPreferred_Name: string;
  ContactMethodPreferredWritten_ENUM: ContactMethodPreferred;
  ContactMethodPreferredWritten_Name: string;
  ContactMethodPreferredVerbal_ENUM: ContactMethodPreferred;
  ContactMethodPreferredVerbal_Name: string;
  VerificationQuestion: string;
  VerificationAnswer: string;
  DateOfBirth: Date | null;
  PortalUserRoles: PortalUserRole[];
  FullName_Short: string;
  FullName_Salutation: string;
  FullName: string;
}

export interface PortalUserProfile_ColdChainServiceProvider {
  Profile_Id: number;
  ServiceProvider_PortalUser_Id: number;
  PortalMember: NewPortalMember;
  TradingName: string;
  DHAccountNo: string;
  ServiceProviderType_ENUM: CS_ColdChain_ServiceProviderType;
  ServiceProviderType_Name: string;
  PrimaryContact_Salutation: string;
  PrimaryContact_Firstname: string;
  PrimaryContact_LastName: string;
  PrimaryContact_Email: string;
  PrimaryContact_Phone: string;
  PrimaryContact_Position_Id: number | null;
  MedicareLocal_Id: number | null;
}

export interface CS_IssueLocation {
  LocationType_ENUM: CS_IssueLocationType | null;
  LocationType_Name: string;
  Map: string;
  Location_Id: number | null;
  LocationDisplay: string;
  Address_Id: number | null;
  LocationAddress: Address;
  Region_Id: number | null;
  Suburb_Id: number | null;
  Hospital_Id: number | null;
  CoolingTower_Id: number | null;
  Organisation_Id: number | null;
  PesticideLicence_Id: number | null;
  LGA_Id: number | null;
  Scheme_Id: number | null;
}

export interface Asset {
  AssetId: number | null;
  AssetDescription: string;
  SiteName: string;
  SiteCode: string;
  PropertyNo: string;
  LocationDescription: string;
  StreetNo: string;
  StreetName: string;
  SuburbArea: string;
  Postcode: string;
  LandNo: string;
  LocationName: string;
  Notes: string;
  Mapkey: string;
  FeatureKey: string;
  FeatureName: string;
  FeatureType: string;
  FeatureGroupName: string;
  FeatureGroup: string;
  FeatureLocation: string;
  FeatureAssetID: string;
  FeaturePlotNo: string;
  Flag_AssetHasAsbestos: boolean | null;
  Flag_AssetIsLeased: boolean | null;
  ActionOfficerCode: string;
  ActionOfficerName: string;
  AssetEnquiryRefNo: string;
  Asset_WorkOrderKEY: string;
  AssetEnquiryClosed: Date | null;
  AssetServiceCode: string;
  AssetSubjectCode: string;
  AssetCategoryCode1: string;
  CommentsList: string[];
  DisplayName: string;
  AssetHierarchy: string | null;
}

export interface ColdChainBreach {
  ColdChainBreach_Id: number;
  Event_Id: number;
  ServiceProvider_PortalUser_Id: number;
  ServiceProvider_PortalUserProfile: PortalUserProfile_ColdChainServiceProvider;
  ReportedBy_Firstname: string;
  ReportedBy_LastName: string;
  ReportedBy_Email: string;
  ReportedBy_Phone: string;
  ReportedBy_Position_Id: number | null;
  NatureOfBreach_ENUM: CS_ColdChain_NatureOfBreach | null;
  NatureOfBreach_Name: string;
  DateBreachOccurred: Date | null;
  DateBreachDetected: Date | null;
  BreachDuration_Hours: number | null;
  BreachDuration_Minutes: number | null;
  BreachEventDescription: string;
  TemperatureDurationRange_Id: number | null;
  PortalSubmission: boolean | null;
  NormalRange: boolean | null;
  DeliveryIssue_Indicator1Type_Id: number | null;
  DeliveryIssue_Indicator2Type_Id: number | null;
  FridgeInvolved: boolean | null;
  CauseOfBreach_Id: number | null;
  FridgeMinimumTemperature: number | null;
  FridgeMaximumTemperature: number | null;
  FridgeInvolvedInPreviousBreach30Days: boolean | null;
  TemperatureType_ENUM: CS_ColdChain_TemperatureType | null;
  TemperatureType_Name: string;
  FridgeType_Id: number | null;
  BrandOfFridge_Id: number | null;
  OtherBrand: string;
  FridgeIdentification: string;
  BreachDiscoveredMethod_ENUM: CS_ColdChain_BreachDiscoveredMethod | null;
  BreachDiscoveredMethod_Name: string;
  IceSlurryResultTemperature: number | null;
  BreachStatus_ENUM: CS_ColdChain_BreachStatus | null;
  BreachStatus_Name: string;
  EstimatedRoomTemperature: number | null;
  AffectedStock: ColdChainBreach_Detail[];
  BreachActions: ColdChainBreach_Detail[];
  Advices: ColdChainBreach_Advice[];
  ResponseGenerated: boolean | null;
  AutomatedMessage: string;
  TotalVaccineCost: number | null;
  TotalVaccineCostRangeLow: number | null;
  TotalVaccineCostRangeHigh: number | null;
  RecallRequired: boolean | null;
  NoOfRecalls: number | null;
  SaveTriggers: ColdChainBreachUpdateTriggers[];
}

export interface CaseDetailIncidentCheckListFlood {
  CaseDetails_ID: number;
  Event_ID: number;
  Priority: string;
  ImmediateHazard: boolean | null;
  NatureofHazard: string;
  HouseHabit: boolean | null;
  UnderHouseHabit: boolean | null;
  Garage: boolean | null;
  Garden: boolean | null;
  Pool: boolean | null;
  Road: boolean | null;
  ThrExtDoor: boolean | null;
  ThrRoofGutters: boolean | null;
  FromPP: boolean | null;
  FromAP: boolean | null;
  Responded: Date | null;
  Truck: string;
  Contacted: Date | null;
  Referred: string;
}

export interface CaseDetailIncidentCheckListStorm {
  CaseDetails_ID: number;
  Event_ID: number;
  Priority: string;
  ImmediateHazard: boolean | null;
  NatureofHazard: string;
  TreeBranchInvolved: boolean | null;
  MainRoad: boolean | null;
  ResidentialStreet: boolean | null;
  Footpath: boolean | null;
  DrivewayUrgentAccessReq: boolean | null;
  OverheadWiresCables: boolean | null;
  House: boolean | null;
  Garage: boolean | null;
  Vehicle: boolean | null;
  Fences: boolean | null;
  Garden: boolean | null;
  Other: string;
  Responded: Date | null;
  Truck: string;
  Contacted: Date | null;
  Referred: string;
}

export interface CaseDetailTreeManagement {
  CaseDetails_ID: number;
  Event_ID: number;
  Insp_Officer_ID: number | null;
  Insp_Officer_DisplayName: string;
  Insp_Officer_Email: string;
  Insp_Officer_Mobile: string;
  Insp_DateTime: Date | null;
  Insp_SpeciesKWD: number | null;
  Insp_HeightKWD: number | null;
  Insp_DBHKWD: number | null;
  Insp_ConditionRatingKWD: number | null;
  Insp_HazardRatingKWD: number | null;
  Insp_DAAssessment: boolean | null;
  Insp_OverheadLines: boolean | null;
  Insp_Team: string;
  Insp_Priority: string;
  Insp_MeetingSpec: boolean | null;
  Insp_MeetingRemovalCR: boolean | null;
  Insp_Notification: boolean | null;
  Insp_Comments: string;
  WReq_ActionRequired: boolean | null;
  WReq_Removal: boolean | null;
  WReq_UnderPrune: boolean | null;
  WReq_Treatment: boolean | null;
  WReq_PropertyLine: boolean | null;
  WReq_ElectricLines: boolean | null;
  WReq_ServiceWires: boolean | null;
  WReq_BranchPopUp: boolean | null;
  WReq_HazardDeadwood: boolean | null;
  WReq_Comments: string;
  WSite_StumpReport: boolean | null;
  WSite_FollowUp: boolean | null;
  WSite_TrafficManagementPlansUtilised: string;
  WSite_JSAUtilised: string;
  WSite_Comments: string;
  Comp_ActionOfficer_ID: number | null;
  Comp_ActionOfficer_DisplayName: string;
  Comp_ActionOfficer_Email: string;
  Comp_ActionOfficer_Mobile: string;
  Comp_DateTime: Date | null;
  Comp_Comments: string;
}

export interface CaseDetailDrinkingWater {
  CaseDetails_ID: number;
  Event_ID: number;
  Incident_CategoryKWD: number | null;
  Further_Info: string;
  Cause_Incident: string;
  Corrective_Action: string;
  Customer_Complaint: string;
  Incident_DueTo_Test: boolean | null;
  Incident_DueTo_Test_Details: string;
  Compliant_With_Notification_Requirements: boolean | null;
  Additional_Test_Status: string;
}

export interface CaseDetailDisabilityWorker {
  CaseDetails_ID: number;
  Event_ID: number;
  IsExistingComplaineeChecked: boolean | null;
  ExistingComplainees: number[];
  Need_Interpreter: boolean | null;
  Initiator_Anonymous: boolean | null;
  Initiator_Consent: boolean | null;
  ConfirmationEmail: boolean | null;
  Submitted_On_Behalf_Of_Service_User: boolean | null;
  Service_User_Know_Complaint: boolean | null;
  Consent_For_Complaint: boolean | null;
  Initiator_Has_ServiceUser_Consent_For_Complaint: boolean | null;
  Service_User_Age_18_Or_Less: boolean | null;
  Service_User_LGBTQ: boolean | null;
  Immediate_Serious_Risk: boolean | null;
  Is_Necessary_Actions_Taken: boolean | null;
  Is_Complaint_In_Scope: boolean | null;
  Withhold_Notice: boolean | null;
  Initial_Notice_Sent: boolean | null;
  Notify_NDIS: boolean | null;
  Wait_Outcome_NDIS: boolean | null;
  Is_Matter_Sensitive_To_DWRBC: boolean | null;
  Human_Rights_Contravened: boolean | null;
  ServiceStandards_Or_CodeOfConduct_Contravened: boolean | null;
  Notice_Of_Receipt_Sent: boolean | null;
  Outcome_Notice_Sent: boolean | null;
  Registered_At_Incident: boolean | null;
  Work_For_NDIS_Provider: boolean | null;
  Language_At_Home: boolean | null;
  Registration_Enquiry: boolean | null;
  Language: number | null;
  LanguageAtHomeName: number | null;
  How_To_Address: number | null;
  Relationship_to_DW: number | null;
  Relationship_To_Service_User: number | null;
  Service_User_Aboriginal_Or_Torres: number | null;
  Priority: number | null;
  NDIS_Status: number | null;
  Referral_From_Other_Entity: number | null;
  How_Conduct_Came_To_Attention: number | null;
  Concern_Raised_To_Another_Agency: number | null;
  Outcome: number | null;
  Incident_Dates: number | null;
  Service_Type: number | null;
  Outcome_Sub_Category: number | null;
  Complainant_Satisfied: number | null;
  Service_User_Satisfied: number | null;
  Worker_Satisfied: number | null;
  Referred_To_Entity: number | null;
  How_Many_SU_Affected: number | null;
  Immediate_Or_Imminent_Harm: number | null;
  Confidentiality: number | null;
  Reason_For_Withholding_Notice_Of_Receipt: number | null;
  Outcome_PrimaryResolutionType: number | null;
  Incident_Date: Date | null;
  Incident_Date_From: Date | null;
  Incident_Date_To: Date | null;
  NoticeOfReceiptSentDate: Date | null;
  Contact_Method_Notes: string;
  TTY: string;
  Anonymous_Notes: string;
  Employer_Name: string;
  Why_No_Consent: string;
  Service_User_Disability_Notes: string;
  Why_Serious_Risk: string;
  Plan: string;
  Risk_Assessment: string;
  Why_Withhold_Notice: string;
  Sensitivity_Other: string;
  How_Conduct_Came_To_Attention_Other: string;
  Affected_Individuals: string;
  Date_Lodged_And_Reference_Numbers: string;
  Outcome_Notes: string;
  Summary: string;
  Title: string;
  Place_Other: string;
  HeardAboutUsOther: string;
  Service_Through_Provider: string;
  Reference: string;
  Worker_Who_Has_Other: string;
  ServiceProvider_Name: string;
  ServiceProvider_Contact: string;
  DW_OtherNames: string;
  WorkerName: string;
  PrimaryResolutionTypeName: string;
  Lst_Preferred_Contact_Time: number[];
  Lst_Service_User_Disability_Type: number[];
  Lst_Service_User_Disability_Category: number[];
  Lst_Service_User_Disability_Category_Sub: number[];
  Lst_Necessary_Actions: number[];
  Lst_Human_Rights_Issues: number[];
  Lst_Practise_Issues: number[];
  Lst_How_Heard_About_Us: number[];
  Lst_Outcome_Actions: number[];
  Lst_Place: number[];
  Lst_Worker_Who_Has: number[];
  Lst_Agencies: number[];
  Lst_How_Were_Service_Users_Harmed: number[];
  Lst_ConductContravened: number[];
  Worker: Worker;
}

export interface CaseDetailAnimalAttack {
  CaseDetails_ID: number;
  Event_ID: number;
  TypeOfAttack: string;
  Severity_KWD: number | null;
  TypeOfOrder: EventLookupTableLNK[];
  TypeOfOrder_ID: number | null;
  Outcome_ID: number | null;
  Outcome: EventLookupTableLNK[];
  AnimalAttacks: DataTable;
  IsAddAnimalAttackClicked: boolean;
  AnimalAttackDetail: CaseDetailAnimalAttackDetail;
}

export interface CaseDetailAnimalAttack {
  CaseDetails_ID: number;
  Event_ID: number;
  TypeOfAttack: string;
  Severity_KWD: number | null;
  TypeOfOrder: EventLookupTableLNK[];
  TypeOfOrder_ID: number | null;
  Outcome_ID: number | null;
  Outcome: EventLookupTableLNK[];
  AnimalAttacks: DataTable;
  IsAddAnimalAttackClicked: boolean;
  AnimalAttackDetail: CaseDetailAnimalAttackDetail;
}

export interface CaseDetailPublicLiabilitySimple {
  CaseDetails_ID: number;
  Event_ID: number;
  IncidentStatus_KWD: number | null;
  ExcessPaid: number | null;
  ClaimValue: number | null;
  ClaimNumber: string;
  AtFault_ID: number | null;
  AtFault: EventLookupTableLNK[];
}

export interface CaseDetailAnimalsWandering {
  CaseDetails_ID: number;
  Event_ID: number;
  CalledForCompliance: boolean | null;
  WasAnimalFound: boolean | null;
  AnimalReturnedToOwner: boolean | null;
  AnimalImpound: boolean | null;
  AnimalRegisteredWhenCollected: boolean | null;
  AnimalTakenFromSystem: boolean | null;
  AnimalNumber: string;
  AnimalRegistration_ID: number | null;
  AnimalOwner: Contact_Relation;
  AnimalName: string;
  AnimalBreed_KWD: number | null;
  AnimalSex: number | null;
  Comment: string;
  PoundRegister_ID: number | null;
  PoundRegister_No: string;
  AnimalType_ID: number | null;
  AnimalType: AnimalType;
  CrossBreed_KWD: number | null;
  Colour_KWD: number | null;
  TagNumber: string;
  Flag_Microchipped: boolean | null;
  MicrochipNumber: string;
  Size_KWD: number | null;
  Condition_KWD: number | null;
  Disposition_KWD: number | null;
  RegisteredAddress: Address;
}

export interface CaseDetailBins {
  CaseDetails_ID: number;
  Event_ID: number;
  RemindCustomerChangingAddress: boolean | null;
  CustomerAdvisedAdditionalCharge: boolean | null;
  CustomerAdvisedCurrentCharge: boolean | null;
  CustomerCustomerWantsToUpgrade: boolean | null;
}

export interface Issues {
  EventIssue_ID: number;
  Record_ID: number | null;
  RecordType_ENUM: RECORDTYPE | null;
  RecordType_Name: string;
  IssueSource_ENUM: IssueSource | null;
  IssueSource_Name: string;
  Issue_Classification: number | null;
  Issue_SubClassification: number | null;
  Issue_Description: string;
  Lst_Issue_Outcome: number[];
  Lst_Actions_Taken: number[];
  Outcome_Notes: string;
  Is_Closed: boolean | null;
  Closed_By: string;
  Closed_Date: Date | null;
  isAddIssueClicked: boolean;
  allIssuesClosed: boolean;
}

export interface CaseDetailCatTrap {
  CaseDetails_ID: number;
  Event_ID: number;
  HasDriversLicenseSighted: boolean | null;
}

export interface CaseDetailSyringesAndSharps {
  CaseDetails_ID: number;
  Event_ID: number;
  HasEventCancelledHealth: boolean | null;
}

export interface CaseDetailEnvironmentalHealth {
  CaseDetails_ID: number;
  Event_ID: number;
  HasOfficerSpokenToResident: boolean | null;
  AffectingHealth: boolean | null;
}

export interface CaseDetailComplaintsHandling {
  CaseDetails_ID: number;
  Event_ID: number;
  RootCause: number | null;
  ReasonsForDissatisfaction: string;
  ResponseTarget: Date | null;
  ResponseDate: Date | null;
  TargetDate: Date | null;
  ResponseChannel: number | null;
  ResponseComment: string;
  ComplaintsHandlings: DataTable;
  IsAddComplaintsHandlingClicked: boolean;
  ComplaintsHandlingDetail: CaseDetailComplaintsHandlingDetail;
}

export interface CaseDetailMyPlaceYouthCentre {
  CaseDetails_ID: number;
  Event_ID: number;
  VisitOrContactDate: Date | null;
  Gender: number | null;
  ContactDescription: number | null;
  ContactDescriptionOther: string;
  AgeOfContact: number | null;
  ContactType: number | null;
  ServiceOrProgram: string;
  InformationOrReferral: number | null;
  AppointmentWithServiceProvider: number | null;
  EnquiryType: number | null;
  EnquiryTypeOther: string;
  Response: number | null;
  UnableToAssist: string;
  ServiceAtMyPlace: number | null;
  Comments: string;
}

export interface CaseDetailPublicLiabilityComprehensive {
  CaseDetails_ID: number;
  Event_ID: number;
  IsIncidentActual: boolean | null;
  AreAnyInjuredPeople: boolean | null;
  HowManyInjured: number | null;
  DescriptionOfInjuries: string;
  OtherDetailsDescribingTheIncident: string;
  AnywitnessToTheIncident: boolean | null;
  WitnessNameAndContact: string;
  WitnessContact: string;
  CouncilDepartmentsMostAffected: string;
  CouncilDepartments: EventLookupTableLNK[];
  CouncilDepartment_ID: number | null;
  TypesOfPersonInjured: string;
  objTypesOfPersonInjured: EventLookupTableLNK[];
  TypesOfPersonInjured_ID: number | null;
  OtherAgenciesNotified: string;
  objOtherAgenciesNotified: EventLookupTableLNK[];
  OtherAgenciesNotified_ID: number | null;
  OtherAgencies: string;
  PublicLiabilitypes: string;
  EventTypes: string;
  objEventTypes: EventLookupTableLNK[];
  EventTypes_ID: number | null;
  RemedialAction: Action;
  IsIncidentAPotentialLiabilityClaim: boolean | null;
  WasDamageFormSent: boolean | null;
  Reviewer: number | null;
  PostIncidentReviewer: SiteUser;
  BriefDescriptionOfIncident: string;
  AnyEmployeesLostTimeInjuries: boolean | null;
  NumberOfCouncilStaffImpacted: number | null;
  NumberOfCouncilVolunteersImpacted: number | null;
  NumberOfCouncilContractorStaffImpacted: number | null;
  NameOfRepresentative1: string;
  CompanyAgency1: string;
  RoleInIncident1: string;
  NameOfRepresentative2: string;
  CompanyAgency2: string;
  RoleInIncident2: string;
  NameOfRepresentative3: string;
  CompanyAgency3: string;
  RoleInIncident3: string;
  NameOfRepresentative4: string;
  CompanyAgency4: string;
  RoleInIncident4: string;
  NameOfRepresentative5: string;
  CompanyAgency5: string;
  RoleInIncident5: string;
  IncidentSeverityRating: string;
  objIncidentSeverityRating: EventLookupTableLNK[];
  IncidentSeverityRating_ID: number | null;
  ProbabilityOfRecurrance: number | null;
  RiskRanking: string;
  IncidentRemedialActionType: number | null;
  DatePostIncidentReviewCompleted: Date | null;
  ForwardPIRForInformation: string;
  IsRectificationWorkRequired: boolean | null;
  IsRectficationWorksInspected: boolean | null;
  IsPIRCompletedSatisfactorily: boolean | null;
  IsFollowUpRequiredWithCustomer: boolean | null;
  DateCustomerContacted: Date | null;
  IncidentReportFinalisationComment: string;
  PublicLiabilityComprehensives: DataTable;
  IsPLCAddSequenceClicked: boolean;
  IsPLCAddActionClicked: boolean;
  IsPLCAddFactorClicked: boolean;
  PLCAction: CaseDetailPLCAction;
  PLCSequence: CaseDetailPLCSequence;
  PLCFactor: CaseDetailPLCFactor;
  dtPLCAction: DataTable;
  dtPLCSequence: DataTable;
  dtPLCFactor: DataTable;
  DateSendForRiskEventReview: Date | null;
  RiskEventReviewSendBy: string;
  RiskEventReviewSendTo: string;
  DateSendForRiskEventFinalisation: Date | null;
  RiskEventFinalisationSendBy: string;
  RiskEventFinalisationSendTo: string;
}

export interface CaseDetailVehicleImpoundChecklist {
  CaseDetails_ID: number;
  Event_ID: number;
  Location: EventLookupTableLNK[];
  ValveStemsDriverDateTime: Date | null;
  ValveStemsPassengerDateTime: Date | null;
  ValveStemsDriver: string;
  ValveStemsPosition: string;
  TyresMarked: boolean | null;
  TyresMarkedDateTime: Date | null;
  CardsLeft: boolean | null;
  CardsLeftDateTime: Date | null;
  ToBeRemoved: boolean | null;
  ToBeRemovedDateTime: Date | null;
  PoliceCheck: boolean | null;
  PoliceCheckDateTime: Date | null;
  RemovalNotice: boolean | null;
  RemovalNoticeDateTime: Date | null;
  PhotoValveStems: boolean | null;
  HouseNumbers: string;
  PoliceInterest: boolean | null;
  VRN: string;
  VehicleMake: number | null;
  VehicleModel: number | null;
  VehicleColour: number | null;
  Registration: string;
  VehicleTowDateTime: Date | null;
  TowingLocation: string;
  InfringementNo: number | null;
  Offence: number | null;
  TowingCompany: string;
  Cost: number | null;
  InvoiceNo: string;
  VehicleRemovedBeforeTowing: boolean | null;
  VehicleRemovedBeforeTowingDateTime: Date | null;
  OfficerNotes: string;
}

export interface EventActionTypeLink {
  CUST_EventActionTypeLNK_ID: number;
  Event_ID: number;
  ActionType_ID: number;
  ActionType_Name: string;
}

export enum CS_KnowledgeBase_CreateEventIdentity {
  Default = 0,
  PhoneEnquiry = 1,
  CounterEnquiry = 2,
  Transfer = 3,
  LiveChat = 4,
  Twitter = 5,
  Email = 6,
  Facebook = 7,
  MobileApp = 8,
  Website = 9,
}

export enum WSR_Source {
  Default = 0,
  Internet = 1,
  Records = 2,
  Mobile = 3,
  Other = 100,
}

export enum CS_WebServiceRequestStatus {
  Default = 0,
  OK = 1,
  HasErrors = 2,
  UnknownCategory = 3,
  DefaultServiceStandardNotSetUp = 4,
  EventDescriptionNotFilled = 5,
  DefaultTypeNotSetUp = 6,
  DefaultTypeInvalid = 7,
  DefaultSourceNotSetUp = 8,
  DefaultSourceInvalid = 9,
  DefaultRequestorTypeNotSetUp = 10,
  DefaultRequestorTypeInvalid = 11,
  AnonymousRequestorNotAllowed = 12,
  NameFieldsNotFilled = 13,
  NonPropertyLocationTypeNotSetUp = 14,
  NoLocationLocationTypeNotSetUp = 15,
  LocationTypeInvalid = 16,
  SameAsRequestorNotAllowedForAnonymousRequestor = 17,
  NoLocationNotAllowedInServiceStandard = 18,
  NoLocationNotAllowedInSystemSettings = 19,
  ReceivedByUserNotValid = 20,
  RecordedByUserNotValid = 21,
  DefaultUserNotSetUp = 22,
  PropertyAddressLocationTypeNotSetUp = 23,
  PropertyIDNotInPropertyLookup = 24,
  AttachmentFileNameEmpty = 25,
  AttachmentFileExtensionNotAllowed = 26,
  AttachmentIsEmpty = 27,
  AttachmentIsTooLarge = 28,
}

export enum CS_RecipientType {
  Default = 0,
  Standard = 1,
  Councillor = 2,
  Director = 3,
  CEO = 4,
}

export enum WizardStep {
  Default = 0,
  PickLocation = 1,
  HardRubbish_PropertyPickupsPerYearCheck = 2,
  HardRubbish_MessagePrompt = 3,
  HardRubbish_CollectionDate = 4,
  RecategoriseComment = 5,
  HardRubbish_PickupRestrictionPerMonthCheck = 6,
  ClosureDetails = 7,
  HardRubbish_CancelCollection = 8,
  QualityCheck = 9,
  ReassignComment = 10,
}

export interface EventLookupTableLNK {
  Record_LookupTable_LNK_Id: number;
  Parent_Id: number;
  Parent_RecordType_ENUM: RECORDTYPE;
  Parent_RecordType_Name: string;
  LookupTableType_ENUM: CS_LookupTable;
  LookupTableType_Name: string;
  LookupTable_Id: number;
  SortIndex: number | null;
}

export enum EventUpdateTriggers {
  SaveEvent,
  UpdateRequestedByExternal,
  ClearRequestedByExternal,
  UpdateRequestedByInternal,
  ClearRequestedByInternal,
  UpdateRequestedByPortalUser,
  ClearRequestedByPortalUser,
  UpdateOnBehalfOfExternal,
  ClearOnBehalfOfExternal,
  UpdateOnBehalfOfInternal,
  ClearOnBehalfOfInternal,
  UpdateCoordinator,
  UpdateActionOfficer,
  UpdateReceivedBy,
  UpdateRecipientCouncillor,
  UpdateAcknowledgedBy,
  UpdateIssueLocation,
  ClearIssueLocation,
  UpdateReceivedOn,
  Recategorise,
  Recategorise_CreateNewAssetJob,
  Recategorise_CreateLastComment,
  Recategorise_CreateNewComment,
  Recategorise_CloseCurrentAndCreateNewAssetJob,
  Recategorise_CloseCurrentAssetJob,
  SaveColdChainBreach,
  SaveAndQuickClose,
  UpdateAccessControl,
  UpdateAssetDetails,
  AssetSystemDataPush,
  UpdateSameIssueRelatedEvent,
  BuildAttachmentsFromBlobStore,
  Acknowledged,
  SafetyReason,
  SafetyRemovedReason,
  HardRubbishAdminOverride,
  EmailActionOfficer,
  SaveCaseDetailIncidentCheckListFlood,
  SaveCaseDetailIncidentCheckListStorm,
  SaveCaseDetailTreeManagement,
  SaveCaseDetailAnimalAttack,
  SaveCaseDetailPublicLiabilitySimple,
  SaveCaseDetailPublicLiabilityComprehensive,
  SaveCaseDetailVehicleImpoundChecklist,
  SaveCaseDetailAnimalsWandering,
  SaveCaseDetailBins,
  SaveCaseDetailCatTrap,
  SaveCaseDetailSyringesAndSharps,
  SaveCaseDetailEnvironmentalHealth,
  SaveCaseDetailMyPlaceYouthCentre,
  SaveCaseDetailComplaintsHandling,
  SaveCaseDetailDrinkingWater,
  SaveCaseDetailDisabilityWorker,
  SaveIssues,
  SaveComplainee,
  SaveProvider,
  PortalImportServiceStandardSettings,
  UpdateStatusAssessmentDW,
  UpdateStatusTakingActionDW,
  UpdateStatusOutcomeAndClosureDW,
  ClearComplainee,
  ClearProvider,
  SaveRequestorProvider,
  ClearRequestorProvider,
  LinkAnimalRegistration,
  UnlinkAnimalRegistration,
  SaveEventPhase,
  UpdateStatusInitialConsiderationDW,
}

export enum SpecialInterestRequestorOrgManagerLevel {
  Default = 0,
  Org1 = 1,
  Org2 = 2,
  Org3 = 3,
  Org4 = 4,
  Org5 = 5,
  LowestOrg = 6,
}

export enum CS_CaseDetailType {
  Default = 0,
  CustomForm = 1,
  DHS_ColdChain = 11,
  LG_IncidentChecklist_Flood = 51,
  LG_IncidentChecklist_Storm = 52,
  LG_TreeManagement = 53,
  LG_AnimalAttack = 54,
  LG_PublicLiabilitySimple = 55,
  LG_PublicLiabilityComprehensive = 56,
  LG_VehicleImpoundChecklist = 57,
  LG_AnimalsWandering = 58,
  LG_Bins = 59,
  LG_CatTrap = 60,
  LG_EnvironmentalHealth = 62,
  LG_MyPlaceYouthCentre = 63,
  LG_ComplaintsHandling = 64,
  PHMS_Incident_DrinkingWater = 101,
  Incident_DisabilityWorker = 151,
}

export enum Records_FileNoType {
  Default = 0,
  SelectContainer = 1,
  AssessmentNumber = 2,
  HealthContainer = 3,
}

export enum CS_OnBehalfOfType {
  Default = 0,
  AllowInternalOnly = 1,
  AllowExternalOnly = 2,
  AllowBoth = 3,
}

export enum FSR_COMPLAINTTYPE {
  NA = 0,
  FOOD_ADULTERATION_FOOD_COMPLAINT_RECEIVED_FROM_THE_COMMUNITY_CONCERNING_FOOD_WHICH_HAS_BEEN_ALLEGED_TO_HAVE_BEEN_ADULTERATED_WITH_A_FOREIGN_OBJECT = 10,
  MICROBIOLOGICAL_ALLEGED_FOOD_POISONING_OR_FOOD_SPOILAGE_COMPLAINT_RECEIVED_FROM_THE_COMMUNITY = 11,
  CHEMICAL_ADULTERATION_FOOD_COMPLAINT_RECEIVED_FROM_THE_COMMUNITY_CONCERNING_FOOD_WHICH_HAS_BEEN_ADULTERATED_BY_CHEMICALS = 12,
  FOOD_PREMISES_RELATED_FOOD_COMPLAINT_RECEIVED_FROM_THE_COMMUNITY_CONCERNING_A_FOOD_PREMISES = 13,
}

export interface ServiceStandardActionTypeLink {
  CUST_ServiceStandardActionTypeLNK_ID: number;
  ServiceStandard_ID: number;
  ActionType_ID: number;
  ActionType_Name: string;
}

export interface NewPortalMember {
  AuthenticationType: LoginAuthenticationType;
  UserName: string;
  UserPassword: string;
  bResetPasswordOnFirstLogon: boolean;
  EnterpriseKey_Set: string;
  Contact_PortalUser: PortalUser;
}

export enum CS_ColdChain_ServiceProviderType {
  Default = 0,
  GP = 1,
  Pharmacist = 2,
  Hospital = 3,
  LocalGovernment = 4,
  Other = 5,
}

export enum CS_IssueLocationType {
  Default = 0,
  NoLocation = 1,
  PropertyAddress = 2,
  SameAsReporter = 3,
  Hospital = 4,
  Suburb = 5,
  LGA = 6,
  DHSRegion = 7,
  Organisation = 8,
  CoolingTower = 9,
  PesticideLicence = 10,
  NonProperty = 11,
  Council = 12,
  HealthPremises = 13,
  TobaccoPremises = 14,
  CommunityProperty = 15,
  Reserve = 16,
  Assets = 17,
  RadiationPlace = 18,
  Poisons = 19,
  WWMSScheme = 20,
  Street = 21,
}

export enum CS_ColdChain_NatureOfBreach {
  Default = 0,
  DeliveryIssue = 1,
  ServiceProviderIssue = 2,
}

export enum CS_ColdChain_TemperatureType {
  Default = 0,
  FreezeAndHeat = 1,
  Heat = 2,
  Freeze = 3,
}

export enum CS_ColdChain_BreachDiscoveredMethod {
  Default = 0,
  MinMaxThermometer = 1,
  DataLogger = 2,
  InbuiltTemperatureIndicator = 3,
}

export enum CS_ColdChain_BreachStatus {
  Default = 0,
  FollowUp = 1,
  WaitingForInformation = 2,
  Closed = 3,
  Internal = 10,
}

export interface ColdChainBreach_Detail {
  AffectedStock_Id: number;
  ColdChainBreach_Id: number;
  Vaccine_Id: number;
  Vaccine: ColdChainVaccine;
  DosesInStock: number | null;
  AdministeredAfterBreach: boolean | null;
  AdministeredDoses: number | null;
  VaccineCostPerDose: number | null;
  VaccineCost: number | null;
  Action_LookupValue_Id: number | null;
}

export interface ColdChainBreach_Advice {
  BreachAdvice_Id: number;
  ColdChainBreach_Id: number;
  Advice_LookupValue_Id: number;
  Advice_LookupValue: LookupTable;
}

export enum ColdChainBreachUpdateTriggers {
  SaveColdChainBreach,
  UpdateAffectedStock,
  UpdateAdvices,
}

export interface CaseDetailAnimalAttackDetail {
  AnimalAttackDetail_ID: number;
  CaseDetails_ID: number;
  Event_ID: number;
  Name: string;
  Owner_ID: number;
  Owner2_ID: number | null;
  Breed_KWD: number | null;
  Sex: string;
  IsRegistered: boolean | null;
  AnimalTagNumber: string;
  IsEnteredOnMgtBrdWebsite: boolean | null;
  OwnerContact: Contact_Relation;
  Address: string;
  Contact: string;
  OwnerName: string;
  Owner2Contact: Contact_Relation;
  Address2: string;
  Contact2: string;
  OwnerName2: string;
  Comments: string;
  BreedName: string;
}

export interface CaseDetailPLCAction {
  Action_ID: number;
  CaseDetails_ID: number;
  Event_ID: number;
  IncidentRemedialActionType: number | null;
  DoesCouncilInsurerNeedNotification: boolean | null;
  DatePostIncidentReviewCompleted: Date | null;
  ForwardPIRForInformation: number | null;
}

export interface CaseDetailPLCSequence {
  Sequence_ID: number;
  CaseDetails_ID: number;
  Event_ID: number;
  Date: Date | null;
  Description: string;
}

export interface CaseDetailPLCFactor {
  Factor_ID: number;
  CaseDetails_ID: number;
  Event_ID: number;
  Description: string;
  Factor: number | null;
  FactorPercentage: number | null;
  FactorList: LookupTable[];
}

export enum CS_LookupTable {
  Default = 0,
  IssueLocationType = 1,
  RegionDivision = 2,
  Type = 3,
  CommunicationMethod = 4,
  EventSource = 5,
  RequestorType = 6,
  EventClosureComment = 7,
  EventRescheduleStandardReason = 8,
  HardRubbish_ItemsCollected = 10,
  IncidentType = 11,
  QualityCheckItem = 12,
  PhoneMessageFlags = 13,
  CaseDetailsAnimalAttackTypeOfOrder = 14,
  CaseDetailsAnimalAttackOutcome = 15,
  HealthIssue = 16,
  HealthAction = 17,
  HealthResult = 18,
  HealthFoodType = 19,
  CaseDetailsPublicLiabilityFaultType = 20,
  CaseDetailsPublicLiabilityComprehensiveTypeOfPersonInjured = 21,
  CaseDetailsPublicLiabilityComprehensiveOtherAgencies = 22,
  CaseDetailsPublicLiabilityComprehensiveEventTypes = 23,
  CaseDetailsPublicLiabilityComprehensiveIncidentSeverityRating = 24,
  CaseDetailsPublicLiabilityComprehensiveProbabilityOfReccurence = 25,
  CaseDetailsPublicLiabilityComprehensiveWorkfaceOperator = 26,
  CaseDetailsPublicLiabilityComprehensiveRiskStream = 27,
  CaseDetailsPublicLiabilityComprehensiveHazardType = 28,
  CaseDetailsPublicLiabilityComprehensiveRiskCategory = 29,
  CaseDetailsPublicLiabilityComprehensiveRiskType = 30,
  CaseDetailsPublicLiabilityCouncilDepartment = 31,
  CaseDetailsPublicLiabilityComprehensiveFactor = 32,
  CaseDetailsVehicleImpoundChecklistLocation = 33,
  CaseDetailsMyPlaceYouthCentreContactDescription = 34,
  CaseDetailsMyPlaceYouthCentreAgeOfContact = 35,
  CaseDetailsMyPlaceYouthCentreContactType = 36,
  CaseDetailsMyPlaceYouthCentreInformationOrReferral = 37,
  CaseDetailsMyPlaceYouthCentreAppointmentWithServiceProvider = 38,
  CaseDetailsMyPlaceYouthCentreEnquiryType = 39,
  CaseDetailsMyPlaceYouthCentreResponse = 40,
  CaseDetailsMyPlaceYouthCentreReferralToServiceAtMyPlace = 41,
  CaseDetailsComplaintsHandlingRootCause = 42,
  TelephoneActivityCategory = 43,
  NuisanceAct_ComplexityGrouped = 44,
  Bin_Size = 45,
  Bin_Type = 46,
  Workstream = 47,
  Tier = 48,
  ColdChain_ReporterPosition = 50,
  ColdChain_CauseOfBreach_Fridge = 51,
  ColdChain_FridgeType = 52,
  ColdChain_FridgeBrand = 53,
  ColdChain_CauseOfBreach_NonFridge = 54,
  ColdChain_DeliveryIssue_IndicatorType = 55,
  ColdChain_Advice = 56,
  ColdChain_BreachAction = 57,
  ColdChain_GPDivision_Class = 58,
  ColdChain_MedicareLocal = 59,
}

export interface ColdChainVaccine {
  Vaccine_Id: number;
  Vaccine_Name: string;
  Vaccine_Description: string;
  Price: number | null;
  NextEffectiveDate: Date | null;
  NewPrice: number | null;
}

export interface DTOBaseClass_Standard {
  Sys_EnterpriseKey: string;
  Sys_CreatedDate: Date;
  Sys_CreatedBy: string;
  Sys_CreatedBy_ID: number;
  Sys_DBRowState: DBRowState;
  Sys_TimeStamp: string;
  Sys_TransactionDate: Date;
  Sys_ModifiedDate: Date;
  Sys_ModifiedBy: string;
  Sys_ModifiedBy_ID: number;
  Sys_ReferenceNumber: string;
  Sys_StatClockStatus_ENUM: StatClockStatus;
  Sys_StatClockStatus_Name: string;
  Sys_FileNumber: string;
  Sys_DebtorNumber: string;
  Sys_ExternalReference: string;
}

export enum DBRowState {
  Inactive = 0,
  Active = 1,
  Archive = 2,
}

export enum StatClockStatus {
  NeverUsed = 0,
  ClockStarted = 1,
  ClockStopped = 2,
}

export interface LookupTable extends DTOBaseClass_Standard {
  CustomerService_LookupTable_Id: number;
  LookupTableType_ENUM: CS_LookupTable;
  LookupTableType_Name: string;
  Description: string;
  FullDescription: string;
  LookupTable_EnumeratedValueType_ENUM: number | null;
  LookupTable_EnumeratedValueType_Name: string;
  LookupTable_EnumeratedValue_ENUM: number | null;
  LookupTable_EnumeratedValue_Name: string;
  SortIndex: number | null;
  Flag_PublishToWeb: boolean | null;
  Flag_IsTypeRequest: boolean | null;
  Flag_IsTypeIncident: boolean | null;
  AcknowledgementDays: number | null;
  TargetDays: number | null;
  URL: string;
  SpecialInterest: AccessControl[];
  SaveTriggers: ServiceStandardUpdateTriggers[];
  _SaveTriggers: ServiceStandardUpdateTriggers[];
  OrgStructure_ID: number | null;
  OrgStructure_Name: string;
}

export enum ServiceStandardUpdateTriggers {
  SaveServiceStandard,
  UpdateCoordinator,
  UpdateActionOfficer,
  UpdateEscalationOfficer1,
  UpdateEscalationOfficer2,
  ClearEscalationOfficer1,
  ClearEscalationOfficer2,
  UpdateIssueLocationType,
  UpdateAccessControl,
  UpdateAutomaticActions,
}

export interface CaseDetailComplaintsHandlingDetail {
  ComplaintsHandlingDetail_ID: number;
  CaseDetails_ID: number;
  Event_ID: number;
  IdentifiedImprovement: string;
  Target: Date | null;
  ResponsibleOfficer_ID: number | null;
  ResponsibleOfficer: SiteUser;
}

export enum CS_RequestorType {
  Default = 0,
  Anonymous = 1,
  Councillor = 2,
  ExternalPerson = 3,
  InternalPerson = 4,
  ServiceProvider = 5,
  ExecutiveManagementTeam = 6,
}

export enum Svc_EventFormAction {
  SystemInitialise,
  Form_CategoryChange,
  Form_TypeChange,
  Form_SourceChange,
  Form_ConfidentialityChange,
  Form_RequestorTypeChange,
  Form_RequestorChange,
  Form_LocationTypeChange,
  Form_ChangeRecipientType,
  Form_PickSpecialInterest,
  Form_PickReceivedBy,
  Form_PickRecipientCouncillor,
  Form_PickLocationAddress,
  Form_PickReaderSiteUser,
  Form_PickWriterSiteUser,
  Form_PickPremises,
  Form_PickLookupValueVIP,
  Form_ChangeCustomFlag1,
  Form_ChangeCustomFlag2,
  Form_ChangeCustomFlag3,
  Form_PickSafetyIssueReason,
  Form_PickSafetyIssueRemovedReason,
  Form_UrgentChange,
  Form_PickLocationRegister,
  Form_PickOnBehalfOfExternal,
  Form_PickOnBehalfOfInternal,
  Form_PickActionOfficer,
  Form_TierChange,
  Form_AssetDetails,
  Form_PickAutoAction,
  Form_PickRelatedEvent,
  Form_ClearParentFileNumber,
  Form_PickWasteWaterScheme,
}

export interface EventHandlerRequest {
  EventFormAction?: Svc_EventFormAction;
  Event: Event;
  EventArgs: any;
  isFirstTimeLoad?: boolean;
}

export interface EventMapObj {
  Event: Event;
}

export interface EventHandlerResponse {
  Event: Event;
  UIControl: EventUIControl;
}

export interface EventUIControl {
  DdlType: ElementControl;
  LitRequestedBy: ElementControl;
  LblRequestedByAddress: ElementControl;
  LblRequestedByContact: ElementControl;
  DivRequestedByPosition: ElementControl;
  RblLocationType: ElementControl;
  DivLocationDetails: ElementControl;
  LitRequestedByPosition: ElementControl;
  DdlRequestorType: ElementControl;
  DivRecipientCouncillor: ElementControl;
  LitCorrespondanceType: ElementControl;
  DdlRecipientType: ElementControl;
  DivLocation: ElementControl;
  DivLocationAssessment: ElementControl;
  LblLocation: ElementControl;
  LnkLocation: ElementControl;
  LblLocationAssessmentNo: ElementControl;
  LblParcelID: ElementControl;
  LitEventsAlert: ElementControl;
  DdlConfidentiality: ElementControl;
  DivAcknowledgement: ElementControl;
  DivAcknowledgementNotRequired: ElementControl;
  BtnRequestedByAnonymous: ElementControl;
  BtnRequestedByMe: ElementControl;
  DivReschedule: ElementControl;
  LblAcknowledgementTarget: ElementControl;
  ChkCallReturned: ElementControl;
  ChkCustomField1: ElementControl;
  ChkCustomField2: ElementControl;
  ChkCustomField3: ElementControl;
  ChkUrgent: ElementControl;
  DivBannerMiddleFlags: ElementControl;
  LitOnBehalfOf: ElementControl;
  LitOnBehalfAddress: ElementControl;
  LitOnBehalfOfPosition: ElementControl;
  LitOnBehalfOfPreferredContact: ElementControl;
  DivWebServiceRequest: ElementControl;
  LblWebServiceAfterHoursService: ElementControl;
  LblWebServicePassword: ElementControl;
  LblWebServiceEventDescription: ElementControl;
  LblWebServiceCategory: ElementControl;
  LblWebServiceRequestType: ElementControl;
  LblWebServiceErrors: ElementControl;
  LblWebServiceSource: ElementControl;
  DivCustomerServiceValidation: ElementControl;
  BtnReopenEvent: ElementControl;
  DivEventDetailsAdviceText: ElementControl;
  LblEventDetailsAdviceText: ElementControl;
  DivBinLookupDetails: ElementControl;
  DdlBinSizeLookup: ElementControl;
  DdlBinTypeLookup: ElementControl;
  DdlRelatedEventsFilter: ElementControl;
  DdlCategory: ElementControl;
  DialogDuplicationEventCheck: ElementControl;
  LitBannerMiddle: ElementControl;
  DivRecordsInfoXpert: ElementControl;
  DivRecordsFileNumber: ElementControl;
  DivWorkflowRecords: ElementControl;
  DivWorkflowRecordsClassification: ElementControl;
  FlWorkflowRecordsSSFileNoLabel: ElementControl;
  DivRecordsParentFileNumber: ElementControl;
  AfileNumber: ElementControl;
  TxtWorkflowRecordsSSFileNumber: ElementControl;
  TxtFileNo: ElementControl;
  TxtWorkflowRecordsSSClassification: ElementControl;
  TxtWorkFlowRecordsParentFileNumber: ElementControl;
  LblPremisesRegistrationNumber: ElementControl;
  LitAnimalNoView: ElementControl;
}

export enum ElementDisplayStatus {
  Visible,
  Hidden,
  Disable,
}

export interface ElementControl {
  Value: any;
  DisplayStatus: ElementDisplayStatus;
  Dataset: any[];
  Label: string;
}
export interface EventIdentityPacket extends IdentityPacket {
  Event: Event;
}

export enum CS_CustomFlag {
  Default = 0,
  Risk = 1,
  Safety = 2,
  VIP = 3,
}

export interface ISafetyReason {
  isShow: boolean;
  titleDialog: string;
  switchState: boolean;
}

export interface EventWithAccessRight {
  Event: Event;
  AccessRights: ACCESSRIGHTS;
}

export interface EventFormNavigate {
  isSimpleForm: boolean;
}

export interface Contact_PickContactFilters {
  AllowMultiSelect: boolean;
  ContactIDs: number[];
  ClassificationFilters: ContactClassification[];
  _ClassificationFilters: ContactClassification[];
  RoleNameFilters: string[];
  _RoleNameFilters: string[];
  ProductRoleFilters: string[];
  _ProductRoleFilters: string[];
  _OrgStructureFilter: number[];
  ShowOnlyCouncillors: boolean;
  HideCouncillors: boolean;
  ShowOnlyVIP: boolean;
  ShowOnlyDW: boolean;
  ShowOnlyDWCMSProviders: boolean;
  AddressBookFilter: AddressBookSection | null;
  NeededSignoff: boolean;
  RecordSourceFilter: RecordSourceIdentifier;
  SearchKeylist: string;
}

export interface PickCategoryFilter {
  Event: Event | object;
  ArticleID: number | null;
  PickServiceStandardType?: PickServiceStandard_Types;
}

export enum CS_RecentRequestsFilter {
  Default = 0,
  Requestor = 1,
  RequestorAddress = 2,
  Location = 3,
  CategoryAndLocation = 4,
  WasteWater = 5,
  ControlledSubstance = 6,
  Animal = 7,
  Kennel = 8,
}

export enum SpecialCharacter {
  SlashMark = "SlashMark",
  QuestionMark = "QuestionMark",
  ColonMark = "ColonMark",
  PlusSymbolMark = "PlusSymbolMark",
  PlusMark = "PlusMark",
  HashtagMark = "HashtagMark",
  DotMark = "DotMark",
}

export interface IDataStateFromNewEventButton {
  formIdentifier: FormIdentifier;
  formAction: FormAction;
}

export interface IDataStateURLToNewEventPage {
  cloneNewEventState: NewEventCloneParams;
}

export interface IEventsParentSection {
  notification: IAppNotificationItemAddProps[];
  animalsRegistrationData?: EventAnimalsRegistrationSectionData;
  isFromAnimals?: boolean;
  kennelData?: EventKennelSectionData;
  isFromKennel?: boolean;
}
export enum PickServiceStandard_Types {
  PickServiceStandard_CRMS = 0,
  PickServiceStandard_HardRubbish = 1,
  PickServiceStandard_Payroll = 2,
  PickServiceStandard_Animal = 3,
  PickServiceStandard_Kennel = 4,
  PickServiceStandard_Health = 5,
  PickServiceStandard_WasteWater = 6,
  PickServiceStandard_LocalLaws = 7,
  PickServiceStandard_Infringements = 8,
  PickServiceStandard_CSM = 9,
  PickServiceStandard_TP = 10,
  PickServiceStandard_Building = 11,
  PickServiceStandard_LandMangement = 12,
}

export interface EventAnimalsRegistrationSectionData {
  Registration_ID: number;
  AnimalName: string;
}

export interface EventKennelSectionData {
  Kennel_ID: number;
}

export interface EventAnimalsLookup {
  Registration_ID: number;
  Registration_Number: string;
  AnimalOwner: string;
  AnimalOwnerRID?: number;
  AnimalName: string;
  Breed: string;
  TagNo: string;
}
