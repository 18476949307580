import { FormAction, FormIdentifier } from "@app/products/crms/model";
import { DirectDebitAccountLOVs } from "@app/products/direct-debit/accounts/model";
import { getDirectDebitAccountLOVs } from "@app/products/direct-debit/accounts/[id]/api";
import { alertDeleteRecord } from "@app/products/direct-debit/constant";
import { IntegrationModuleItemType } from "@app/products/direct-debit/system-admin/model";
import { TitleDDModuleItemTypeManagePage } from "@app/products/direct-debit/system-admin/module-item-types/constant";
import { getDDModuleItemTypeById } from "@app/products/direct-debit/system-admin/module-item-types/[id]/api";
import { ExistManageDDModuleItemType } from "@app/products/direct-debit/system-admin/module-item-types/[id]/existed/_index";
import { NewManageDDModuleItemType } from "@app/products/direct-debit/system-admin/module-item-types/[id]/new/_index";
import { checkIsInactiveDDModuleItemType } from "@app/products/direct-debit/system-admin/module-item-types/[id]/util";
import { APIResponse } from "@common/apis/model";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { useFlexibleFetchData } from "@common/hooks/flexible-fetch-data/useFlexibleFetchData";
import { useCancelRequest } from "@common/hooks/useCancelRequest";
import { useIsNew } from "@common/hooks/useIsNew";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { EWatchStatusLoading } from "@common/stores/route/model";
import { routeDataStoreInstance } from "@common/stores/route/store";
import { CCCheckPermissionWrap } from "@components/cc-check-permission-wrap/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

const ManageDirectDebitModuleItemType = () => {
  //#region STORE ========/
  const {
    listenNotificationPortal,
    pushNotificationPortal,
    clearNotifications,
  } = useNotificationPortalStore();
  const { setInitialDataForms, resetDataForm, isLoadingForm } =
    useFlexibleFormStore();
  useEffectOnce(() => {
    return () => {
      resetDataForm();
    };
  });
  //#endregion STORE =====/

  //#region HOOK ========/
  const history = useHistory();
  const { cancelToken } = useCancelRequest();
  const isNew = useIsNew();
  //#endregion HOOK =====/

  //#region Variable ========/
  let { id } = useParams<{ id: string }>();
  const moduleItemTypeId = useMemo(() => {
    return isNew ? 0 : +id;
  }, [isNew, id]);
  //#endregion Variable =====/

  const [hasAllowNewPermission, setHasAllowNewPermission] = useState(false);

  const manageDDModuleItemTypeSlider = useFlexibleFetchData({
    alias: "manageDDModuleItemTypeSlider",
    slides: [
      {
        fetch: [
          () => getDDModuleItemTypeById(moduleItemTypeId, cancelToken()),
          () => getDirectDebitAccountLOVs(cancelToken()),
        ],
        handleSuccess: ({ dataFromApi }) => {
          const response: [
            APIResponse<IntegrationModuleItemType>,
            APIResponse<DirectDebitAccountLOVs>
          ] = dataFromApi;
          const ddModuleItemTypeFormData = response[0]?.data;
          const ddAccountLOVs = response[1]?.data;

          // delete "Unspecified" initial value
          if (isNew) {
            ddModuleItemTypeFormData.Integration_Module_Type_ENUM = null;
            ddModuleItemTypeFormData.Integration_Module_Type_Name = null;
          }

          // delete "Unspecified" item from LOV
          const lovModuleTypes = ddAccountLOVs?.IntegrationModuleTypes?.filter(
            (item) => item?.Key !== 0
          );
          const newDDAccountLOVs = { IntegrationModuleTypes: lovModuleTypes };

          //#region save data to store ========/
          setInitialDataForms({
            GeneralForm: ddModuleItemTypeFormData,
            GeneralFormLovs: newDDAccountLOVs,
          });
          //#endregion save data to store =====/

          //#region Notification ========/
          listenNotificationPortal();

          if (checkIsInactiveDDModuleItemType()) {
            pushNotificationPortal(alertDeleteRecord, true);
          }
          //#endregion Notification =====/
        },
      },
    ],
  });

  useEffect(() => {
    if (!isNew || (isNew && hasAllowNewPermission)) {
      manageDDModuleItemTypeSlider.fetchApi();
    }
    return () => clearNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location, hasAllowNewPermission]);

  return (
    <>
      <Loading
        isLoading={
          manageDDModuleItemTypeSlider.isFetching ||
          isLoadingForm ||
          routeDataStoreInstance.isLoadingFor(
            EWatchStatusLoading.IsLoadingPermission
          )
        }
        isFullScreen
      />
      <FormNavigation title={TitleDDModuleItemTypeManagePage} />
      {manageDDModuleItemTypeSlider.errorComponent ? (
        manageDDModuleItemTypeSlider.errorComponent
      ) : isNew ? (
        <CCCheckPermissionWrap
          onChangeHasPermission={() => setHasAllowNewPermission(true)}
          permission={{
            formIdentifier: FormIdentifier.DirectDebit_Dialog_ModuleItemType,
            productType: PRODUCT_TYPE_NUMBER.DirectDebit,
            formAction: [FormAction.CORE_ALLOW_NEW, FormAction.CORE_ALLOW_SAVE],
          }}
        >
          <NewManageDDModuleItemType />
        </CCCheckPermissionWrap>
      ) : (
        <ExistManageDDModuleItemType />
      )}
    </>
  );
};

export default observer(ManageDirectDebitModuleItemType);
