import { DirectDebitAuthorisationFormElement } from "@app/products/direct-debit/authorisations/[id]/components/general/form-element/_index";
import { DirectDebitAuthorisation } from "@app/products/direct-debit/authorisations/model";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import {
  Form,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export const DirectDebitAuthorisationForm = observer(() => {
  //#region STORE ========/
  const { dataForms, middlewareSubmitForm, setOnSubmitFlexible } =
    useFlexibleFormStore();
  const { pushNotificationPortal } = useNotificationPortalStore();
  //#endregion STORE =====/

  //#region METHOD ========/
  const handleOnSubmit = (event: FormSubmitClickEvent) => {
    middlewareSubmitForm(event);
    if (!event.isValid) {
      pushNotificationPortal({
        title: "Please enter all required information.",
        type: "warning",
      });
    } else if (!event.isModified) {
      pushNotificationPortal({
        title: "The form is not modified.",
        type: "warning",
      });
    }
  };
  //#endregion METHOD =====/

  //#region INITIAL_VALUES ========/
  const directDebitAuthorisation =
    dataForms?.GeneralForm as DirectDebitAuthorisation;
  //#endregion INITIAL_VALUES =====/

  return (
    <div className="cc-form">
      <Form
        onSubmitClick={handleOnSubmit}
        initialValues={directDebitAuthorisation}
        key={JSON.stringify(directDebitAuthorisation)}
        render={(formRenderProps: FormRenderProps) => {
          setOnSubmitFlexible({
            formName: "GeneralForm",
            eventSubmit: formRenderProps.onSubmit,
          });
          return (
            <CCGeneralPanel
              isInvalid={!formRenderProps.valid}
              component={
                <DirectDebitAuthorisationFormElement
                  formRenderProps={formRenderProps}
                />
              }
            />
          );
        }}
      />
    </div>
  );
});
