import { Comments } from "@app/core/comments/_index";
import { ITabProps, TabTable } from "@app/core/tab-table/_index";
import { TabTableType } from "@app/core/tab-table/model";
import { FormAction, FormIdentifier } from "@app/products/crms/model";
import { DirectDebitGroupDeleteButton } from "@app/products/direct-debit/system-admin/groups/[id]/components/buttons/delete/_index";
import { SaveButtonDirectDebitGroup } from "@app/products/direct-debit/system-admin/groups/[id]/components/buttons/save/_index";
import { DirectDebitGroupForm } from "@app/products/direct-debit/system-admin/groups/[id]/components/general/_index";
import { SysAdminGroupHistoryTab } from "@app/products/direct-debit/system-admin/groups/[id]/components/slidebar/history/_index";
import { generateBadgeDBRowState } from "@app/products/direct-debit/system-admin/groups/[id]/util";
import { DirectDebitGroup } from "@app/products/direct-debit/system-admin/model";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useCheckPermissions } from "@common/hooks/permissions/useCheckPermissions";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { useGlobalStore } from "@common/stores/global/store";
import { getDisplayTextWithDashes } from "@common/utils/common";
import { FormTitle } from "@components/cc-form/title/_index";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { YieldNotificationPortal } from "@components/cc-notification-portal/_index";
import { TypePlaceId } from "@components/cc-notification-portal/constant";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";

export const ExistManageDirectDebitGroup = observer(() => {
  const { currentUserInfo } = useGlobalStore();
  const { dataForms } = useFlexibleFormStore();
  const directDebitGroup = dataForms?.GeneralForm as DirectDebitGroup;

  //#region HOOK ========/
  const location = useLocation();
  const [showSlideBar, setShowSlideBar] = useState(true);
  //#endregion HOOK =====/

  const { checkPermissions } = useCheckPermissions({
    formIdentifier: FormIdentifier.DirectDebit_Dialog_Group,
    productType: PRODUCT_TYPE_NUMBER.DirectDebit,
  });

  const bookmarkList: IBookmarkItem[] = [
    {
      LinkText: `Direct Debit - Group - ${getDisplayTextWithDashes([
        directDebitGroup?.Direct_Debit_Group_ID,
      ])}`,
      Text: "Direct Debit - System Admin",
      LinkUrl: location.pathname,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      ProductType_ENUM: PRODUCT_TYPE.DirectDebit,
      RecordType_ENUM: RECORDTYPE.Receipting_DirectDebitGroup,
      Record_ID: directDebitGroup?.Direct_Debit_Group_ID,
    },
  ];

  const listPanelBar: ITabProps[] = [
    {
      title: "Comments",
      tabType: TabTableType.Comments,
      component: (
        <Comments
          id={directDebitGroup?.Direct_Debit_Group_ID}
          recordType={RECORDTYPE.CORE_Contact}
        />
      ),
    },
  ];

  return (
    <>
      <FormTitle
        title={directDebitGroup?.Description}
        badge={generateBadgeDBRowState(directDebitGroup?.Sys_DBRowState)}
      />
      <CCManagePageActionBar
        leftActions={[
          <SaveButtonDirectDebitGroup
            disabled={!checkPermissions(FormAction.CORE_ALLOW_EDIT)}
          />,
          <DirectDebitGroupDeleteButton
            disabled={!checkPermissions(FormAction.CORE_ALLOW_DELETE)}
          />,
        ]}
        rightActions={[
          <CCNavButton
            title={"Slide Bar"}
            iconClass="fal fa-info-circle"
            isActive={showSlideBar}
            onClick={() => {
              setShowSlideBar(!showSlideBar);
            }}
          />,
          <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
        ]}
      />
      <div className="cc-manage-container">
        <div className="cc-manage-left">
          <YieldNotificationPortal placeId={TypePlaceId.Main} />
          <div className="cc-manage-form-body">
            {directDebitGroup && <DirectDebitGroupForm />}
            <TabTable
              initialPanels={listPanelBar}
              recordType={RECORDTYPE.CORE_Contact}
              id={directDebitGroup?.Direct_Debit_Group_ID}
            />
          </div>
        </div>
        {showSlideBar && (
          <div className="cc-manage-right">
            <ReferenceSideBar
              components={[
                {
                  title: "History",
                  component: <SysAdminGroupHistoryTab />,
                },
              ]}
            />
          </div>
        )}
      </div>
    </>
  );
});
