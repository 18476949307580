import { AddressesForAssessment } from "@app/products/property/assessments/[id]/components/child-screens/general/components/form-element/components/addresses-for-assessment/_index";
import ValuationsInfoPopup from "@app/products/property/assessments/[id]/components/child-screens/general/components/form-element/components/popups/valuations-info/_index";
import {
  colBalanceOutstanding,
  colValuations,
} from "@app/products/property/assessments/[id]/components/child-screens/general/components/form-element/config";
import { IBalanceOutstanding } from "@app/products/property/assessments/[id]/components/child-screens/general/components/form-element/model";
import {
  DTO_Address,
  DTO_Assessment,
  DTO_FinancialAreaBalance_Totals,
  DTO_Valuation_Value,
} from "@app/products/property/assessments/[id]/model";
import { useAssessmentStore } from "@app/products/property/assessments/[id]/store";
import { BalanceOutStandingSAP } from "@app/products/property/components/grid/balance-out-standing-sap/_index";
import {
  IOwnerAndRatepayer,
  OwnerAndRatepayer,
} from "@app/products/property/components/owner-ratepayer/_index";
import { ECustomColNameProperty } from "@app/products/property/config";
import { isFieldVisible } from "@app/products/property/util";
import { DATE_FORMAT } from "@common/constants/common-format";
import { useIsNew } from "@common/hooks/useIsNew";
import { CommunityProperty, Label } from "@common/stores/products/config";
import { nameOfFactory } from "@common/utils/common";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CurrencyCell } from "@components/cc-grid/components/grid-cells/currency/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { CCGrid } from "@components/cc-grid/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCPercentInput } from "@components/cc-percent-input/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import {
  Field,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { cloneDeep, isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

interface IProps {
  addressData?: DTO_Address[];
  formRenderProps: FormRenderProps;
}

const nameOfBalanceOutstanding = nameOfFactory<IBalanceOutstanding>();
const nameOfValuation = nameOfFactory<DTO_Valuation_Value>();

export const PropertyAssessmentFormElement = observer(
  ({ formRenderProps, addressData }: IProps) => {
    const isNew = useIsNew();
    const { assessment } = useAssessmentStore();
    const { isLLS, isVic, isActro } = CommunityProperty.getFlagOfStates();
    const [colBalances, setColBalances] = useState<IColumnFields[]>([]);

    //Get labels
    const [
      ratepayerNoticeGroupLabel,
      legalDescriptionLabel,
      valuationNumberLabel,
      assessmentNumberLabel,
      sectorLabel,
      categoryLabel,
      assessmentIDLabel,
      addressesForAssessmentLabel,
    ] = Label.CommunityProperty.getLabel([
      ECustomColNameProperty.Ratepayer_notice_group,
      ECustomColNameProperty.Legal_Description,
      ECustomColNameProperty.Valuation_Number,
      ECustomColNameProperty.AssessmentNumber,
      ECustomColNameProperty.Sector,
      ECustomColNameProperty.Category,
      ECustomColNameProperty.AssessmentID,
      ECustomColNameProperty.Addresses_For_Assessment,
    ]);

    useEffect(() => {
      const tempCol = cloneDeep(colBalanceOutstanding);
      if (assessment?.FinancialAreaBalances?.Balances?.length) {
        tempCol.forEach((item: IColumnFields) => {
          if (
            Object.keys(
              assessment?.FinancialAreaBalances?.Totals as Object
            ).includes(item.field)
          ) {
            const colField =
              item.field as keyof DTO_FinancialAreaBalance_Totals;
            if (!isNil(assessment?.FinancialAreaBalances?.Totals?.[colField])) {
              item.footerCell = (
                <CurrencyCell
                  value={
                    assessment?.FinancialAreaBalances?.Totals[
                      colField
                    ] as number
                  }
                />
              );
            }
          }
        });
      }
      setColBalances(tempCol);
    }, [assessment]);

    const nameOf = nameOfFactory<DTO_Assessment>();
    const getValue = formRenderProps.valueGetter;
    const { pushNotification } = useCCAppNotificationStore();
    const currentValuation = assessment?.Assessment.CurrentValuation;

    const validateForm = (event: React.SyntheticEvent<any>) => {
      if (formRenderProps.modified) {
        formRenderProps.onSubmit(event);
        return;
      }
      if (!formRenderProps.valid) return;
      pushNotification({
        title: "The form is not modified",
        type: "warning",
      });
    };

    const propsOwnerAndRatepayer: IOwnerAndRatepayer = {
      data: {
        visibilityOwner: assessment?.OwnersGroupBoxVisibility,
        ownerValue: getValue(nameOf("Owners")),
        ratepayerValue: getValue(nameOf("Ratepayer")),
        options: {
          isLLS,
        },
      },
    };

    return (
      <FormElement className="cc-property-assessment-form-body">
        {/* Reference by a label in WastewaterActionBar.tsx */}
        <input hidden type="submit" id="btn-submit" onClick={validateForm} />
        <section className="cc-field-group">
          <div className="cc-form-cols-3">
            <OwnerAndRatepayer {...propsOwnerAndRatepayer} readOnly />
            <div className="cc-field">
              <div className="cc-field cc-col-span-3">
                <section className="cc-field-group">
                  {isLLS && (
                    <div className="cc-field">
                      <CCLabel title="Property name" />
                      <Field
                        name={nameOf("PropertyName")}
                        placeholder={"Property name"}
                        component={CCInput}
                        rows={4}
                        readOnly
                      />
                    </div>
                  )}
                  <div className="cc-form-cols-1">
                    <div className="cc-field">
                      <CCLabel title="Property address" />
                      <Field
                        name={nameOf("PropertyName_Address_Locality")}
                        placeholder={"Property address"}
                        component={CCTextArea}
                        rows={4}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="cc-field">
                    {isLLS && (
                      <div className="cc-form-cols-2">
                        {isFieldVisible(
                          getValue(nameOf("StateVisibility"))
                        ) && (
                          <div className="cc-field">
                            <CCLabel title="State" />
                            <Field
                              name={nameOf("State")}
                              placeholder="State"
                              component={CCInput}
                              readOnly
                            />
                          </div>
                        )}
                        {isFieldVisible(
                          getValue(nameOf("CountryVisibility"))
                        ) && (
                          <div className="cc-field">
                            <CCLabel title="Country" />
                            <Field
                              name={nameOf("Country")}
                              placeholder="Country"
                              component={CCInput}
                              readOnly
                            />
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </section>
              </div>
            </div>
          </div>

          <div className="cc-form-cols-3">
            {!isLLS && (
              <>
                <div className="cc-field">
                  <CCLabel title={"Owner notice group"} />
                  <Field
                    name={nameOf("Owner_NoticeGroup")}
                    placeholder={"Owner notice group"}
                    component={CCInput}
                    readOnly
                  />
                </div>
                <div className="cc-field">
                  <CCLabel title={ratepayerNoticeGroupLabel} />
                  <Field
                    name={nameOf("Ratepayer_NoticeGroup")}
                    placeholder={ratepayerNoticeGroupLabel}
                    component={CCInput}
                    readOnly
                  />
                </div>
              </>
            )}
          </div>

          <div className="cc-form-cols-3">
            <div className="cc-field">
              <CCLabel title="Descriptor" />
              <Field
                name={nameOf("PropertyLocationDescriptor")}
                placeholder={"Descriptor"}
                component={CCInput}
                readOnly
              />
            </div>
            <div className="cc-field">
              <CCLabel title="Primary land use" />
              <Field
                name={nameOf("PrimaryLandUse")}
                placeholder={"Primary land use"}
                component={CCInput}
                readOnly
              />
            </div>
            {isLLS &&
              isFieldVisible(
                getValue(nameOf("SecondaryLandUseVisibility"))
              ) && (
                <div className="cc-field">
                  <CCLabel title="Secondary land use" />
                  <Field
                    name={nameOf("SecondaryLandUse")}
                    placeholder={"Secondary land use"}
                    component={CCInput}
                    readOnly
                  />
                </div>
              )}
          </div>
          {isLLS && (
            <div className="cc-form-cols-3">
              {isFieldVisible(
                getValue(nameOf("CalculatedLandAreaVisibility"))
              ) && (
                <div className="cc-field">
                  <CCLabel title={"Calculated land area"} />
                  <Field
                    name={nameOf("CalculatedLandArea")}
                    placeholder={"Calculated land area"}
                    component={CCInput}
                    readOnly
                  />
                </div>
              )}
              <div className="cc-form-cols-2">
                {isFieldVisible(getValue(nameOf("GLSLandAreaVisibility"))) && (
                  <div className="cc-field">
                    <CCLabel title="GIS land area" />
                    <Field
                      name={nameOf("GISLandArea")}
                      placeholder={"GIS land area"}
                      component={CCInput}
                      readOnly
                    />
                  </div>
                )}
                <div className="cc-field">
                  <CCLabel title="Unit" />
                  <Field
                    name={nameOf("GISLandAreaUnit")}
                    component={CCInput}
                    readOnly
                  />
                </div>
              </div>
              <div className="cc-form-cols-2">
                {isFieldVisible(
                  getValue(nameOf("ActualLandAreaVisibility"))
                ) && (
                  <div className="cc-field">
                    <CCLabel title="Actual land area" />
                    <Field
                      name={nameOf("ActualLandArea")}
                      placeholder={"Actual land area"}
                      component={CCInput}
                      readOnly
                    />
                  </div>
                )}
                <div className="cc-field">
                  <CCLabel title="Unit" />
                  <Field
                    name={nameOf("ActualLandAreaUnit")}
                    component={CCInput}
                    readOnly
                  />
                </div>
              </div>
            </div>
          )}

          <div className="cc-form-cols-3">
            <div className="cc-field cc-col-span-2">
              {/* Waiting api add label for Sentence case */}
              <CCLabel title={legalDescriptionLabel} />
              <Field
                name={nameOf("Legal_Description")}
                placeholder={legalDescriptionLabel}
                component={CCTextArea}
                rows={4}
                readOnly
              />
            </div>
            <div className="cc-field">
              <div className="cc-form-cols-1">
                <div className="cc-field">
                  <CCLabel title="Status" />
                  <Field
                    name={nameOf("Status")}
                    placeholder={"Status"}
                    component={CCInput}
                    readOnly
                  />
                </div>

                <div className="cc-field">
                  <CCLabel title="Type" />
                  <Field
                    name={nameOf("Type")}
                    placeholder={"Type"}
                    component={CCInput}
                    readOnly
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="cc-form-cols-3">
            {isFieldVisible(getValue(nameOf("ValuationNumberVisibility"))) && (
              <div className="cc-field">
                {/* Waiting api add label for Sentence case */}
                <CCLabel title={valuationNumberLabel} />
                <Field
                  name={nameOf("ValuationNumberX")}
                  placeholder={valuationNumberLabel}
                  component={CCInput}
                  readOnly
                />
              </div>
            )}
            <div className="cc-field">
              <label
                className="cc-label"
                id={`cc-label-${ECustomColNameProperty.AssessmentNumber}`}
              >
                {/* Waiting confirm to split phrase or whole phrase */}
                {assessmentNumberLabel}
              </label>
              <Field
                name={nameOf("AssessmentNumber")}
                placeholder={assessmentNumberLabel}
                component={CCInput}
                readOnly
              />
            </div>
            <div className="cc-field">
              <CCLabel title="Create date" />
              <Field
                name={nameOf("CreationDate")}
                format={DATE_FORMAT.DATE_CONTROL}
                component={CCDatePicker}
                disabled
              />
            </div>
          </div>

          <div className="cc-form-cols-3">
            <div className="cc-field">
              <CCLabel title={sectorLabel} />
              <Field
                name={nameOf("Group")}
                placeholder={sectorLabel}
                component={CCInput}
                readOnly
              />
            </div>
            <div className="cc-field">
              <CCLabel title={categoryLabel} />
              <Field
                name={nameOf("Category")}
                placeholder={categoryLabel}
                component={CCInput}
                readOnly
              />
            </div>
            <FieldAssessmentID
              label={assessmentIDLabel}
              name={nameOf("Id")}
              hidden={isLLS}
            />
          </div>
          {isActro && !isLLS && (
            <div className="cc-form-cols-3">
              {isFieldVisible(
                getValue(nameOf("LeaseCommencementVisibility"))
              ) && (
                <div className="cc-field">
                  <CCLabel title="Lease commencement" />
                  <Field
                    name={nameOf("LeaseCommencementDate")}
                    format={DATE_FORMAT.DATE_CONTROL}
                    component={CCDatePicker}
                    disabled
                  />
                </div>
              )}
              {isFieldVisible(getValue(nameOf("LeaseInDateVisibility"))) && (
                <div className="cc-field">
                  <CCLabel title="Lessee in date" />
                  <Field
                    name={nameOf("LesseeIn")}
                    format={DATE_FORMAT.DATE_CONTROL}
                    component={CCDatePicker}
                    disabled
                  />
                </div>
              )}
              {isFieldVisible(getValue(nameOf("LandTaxLevyVisibility"))) && (
                <div className="cc-field">
                  <CCLabel title="Land tax levy" />
                  <Field
                    name={nameOf("LandTaxLevy")}
                    placeholder={"Land tax levy"}
                    component={CCInput}
                    readOnly
                  />
                </div>
              )}
            </div>
          )}

          {!isLLS && (
            <div className="cc-form-cols-3">
              {isFieldVisible(
                getValue(nameOf("UnitEntitlementVisibility"))
              ) && (
                <div className="cc-field">
                  <CCLabel title="Unit entitlement" />
                  <Field
                    name={nameOf("UnitEntitlement")}
                    disabled
                    component={CCPercentInput}
                    spinners={false}
                  />
                </div>
              )}
              {isFieldVisible(
                getValue(nameOf("UnitTypeEntitlementVisibility"))
              ) && (
                <div className="cc-field">
                  <CCLabel title="Unit type entitlement" />
                  <Field
                    name={nameOf("UnitEntitlementPercentage")}
                    disabled
                    component={CCPercentInput}
                    spinners={false}
                  />
                </div>
              )}
            </div>
          )}

          {isLLS && (
            <>
              <div className="cc-form-cols-3">
                {isFieldVisible(getValue(nameOf("LGAVisibility"))) && (
                  <div className="cc-field">
                    <CCLabel title="LGA" />
                    <Field
                      name={nameOf("LGA")}
                      placeholder={"LGA"}
                      component={CCInput}
                      readOnly
                    />
                  </div>
                )}
                <div className="cc-field">
                  <CCLabel title="SAP ID" />
                  <Field
                    name={nameOf("SAPHoldingId")}
                    placeholder={"SAP ID"}
                    component={CCInput}
                    readOnly
                  />
                </div>
                <FieldAssessmentID
                  label={assessmentIDLabel}
                  name={nameOf("Id")}
                />
              </div>
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <CCLabel title="Effective from" />
                  <Field
                    name={nameOf("AssessmentEffectiveFrom")}
                    component={CCDatePicker}
                    format={DATE_FORMAT.DATE_CONTROL}
                    disabled
                  />
                </div>
                <div className="cc-field">
                  <CCLabel title="Effective to" />
                  <Field
                    name={nameOf("AssessmentEffectiveTo")}
                    component={CCDatePicker}
                    format={DATE_FORMAT.DATE_CONTROL}
                    disabled
                  />
                </div>
              </div>
            </>
          )}

          {!isActro && (
            <div className="cc-form-cols-3">
              <div className="cc-field cc-col-span-2">
                <CCLabel title="Improvements" />
                <Field
                  name={nameOf("Improvements")}
                  component={CCTextArea}
                  rows={4}
                  readOnly
                />
              </div>
            </div>
          )}
          {!isNew && (
            <section className="cc-field-group">
              <div className="cc-form-cols-1">
                <AddressesForAssessment
                  label={addressesForAssessmentLabel}
                  addressData={addressData ?? []}
                />
              </div>
            </section>
          )}

          {isVic && (
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel title="Fire services land status" />
                <Field
                  name={nameOf("FireServicesLandStatus")}
                  placeholder={"Fire services land status"}
                  component={CCInput}
                  readOnly
                />
              </div>
              <div className="cc-field">
                <CCLabel title="FSPL land classification" />
                <Field
                  name={nameOf("FSPLLandClassification")}
                  placeholder={"FSPL land classification"}
                  component={CCInput}
                  readOnly
                />
              </div>
            </div>
          )}
          {!isNew && assessment ? (
            <div className="cc-form-cols-3">
              {assessment.CanViewAssessmentFinancials ? (
                <div className="cc-field cc-col-span-2">
                  <BalanceOutStandingSAP
                    sapId={assessment?.Assessment?.SAPHoldingId}
                  />
                  {!isLLS ? (
                    <>
                      <CCLabel
                        title={
                          assessment?.Assessment
                            ?.BalancesOutstandingListCaption ??
                          "Balance outstanding"
                        }
                      />
                      <CCGrid
                        className="cc-balance-outstanding-grid"
                        data={assessment.FinancialAreaBalances.Balances || []}
                        columnFields={colBalances}
                        primaryField={nameOfBalanceOutstanding("Id")}
                      />
                    </>
                  ) : null}
                </div>
              ) : null}
              <div>
                <div className="cc-field">
                  <div className="cc-tool-tip-wrapper">
                    <CCLabel title="Valuations" />
                    <ValuationsInfoPopup
                      assessmentInfo={assessment.Assessment}
                    />
                  </div>
                  <CCGrid
                    className="cc-anonymous-requestor-grid"
                    data={currentValuation ? currentValuation.Values : []}
                    columnFields={colValuations}
                    primaryField={nameOfValuation("Name")}
                  />
                </div>
              </div>
            </div>
          ) : null}
        </section>
      </FormElement>
    );
  }
);

const FieldAssessmentID = ({
  name,
  label,
  hidden = false,
}: {
  name: string;
  label: string;
  hidden?: boolean;
}) => {
  return hidden ? null : (
    <div className="cc-field">
      <label
        className="cc-label"
        id={`cc-label-${ECustomColNameProperty.AssessmentID}`}
      >
        {label}
      </label>
      <Field name={name} placeholder={label} component={CCInput} readOnly />
    </div>
  );
};
