import { InspectionType } from "@app/core/inspections/[id]/model";
import { alertDeleteRecord } from "@app/products/direct-debit/constant";
import { APIResponse } from "@common/apis/model";
import { useFlexibleFetchData } from "@common/hooks/flexible-fetch-data/useFlexibleFetchData";
import { useCancelRequest } from "@common/hooks/useCancelRequest";
import { useIsNew } from "@common/hooks/useIsNew";
import {
  getInspectionTypeById,
  getInspectionTypeLOVs,
} from "@common/pages/settings/lookups/inspection-types/_id/api";
import { checkIsInactiveInspectionType } from "@common/pages/settings/lookups/inspection-types/_id/components/buttons/save/util";
import { TitleInspectionTypeManagePage } from "@common/pages/settings/lookups/inspection-types/_id/constant";
import { ExistManageInspectionType } from "@common/pages/settings/lookups/inspection-types/_id/existed/_index";
import {
  InspectionTypeLovs,
  TApiInspectionType,
} from "@common/pages/settings/lookups/inspection-types/_id/model";
import { NewManageInspectionTypeComponent } from "@common/pages/settings/lookups/inspection-types/_id/new/_index";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";

const ManageInspectionType = () => {
  //#region STORE
  const { setInitialDataForms, isLoadingForm } = useFlexibleFormStore();
  //#endregion STORE

  //#region HOOK
  const { cancelToken } = useCancelRequest();
  const {
    listenNotificationPortal,
    pushNotificationPortal,
    clearNotifications,
  } = useNotificationPortalStore();
  const isNew = useIsNew();
  const history = useHistory();
  //#endregion HOOK

  //#region Variable ========/
  let { id } = useParams<{ id: string }>();
  const inspectionTypeId = useMemo(() => {
    return isNew ? "0" : id;
  }, [isNew, id]);
  //#endregion Variable =====/

  const manageInspectionTypeSlider = useFlexibleFetchData({
    slides: [
      {
        fetch: [
          () => getInspectionTypeLOVs(cancelToken()),
          () => getInspectionTypeById(+inspectionTypeId, cancelToken()),
        ],
        handleSuccess: ({ dataFromApi }) => {
          const [
            _inspectionTypeLovsIdentityPacket,
            _inspectionTypeIdentityPacket,
          ]: [
            APIResponse<TApiInspectionType["Lovs"]>,
            APIResponse<TApiInspectionType["Value"]>
          ] = dataFromApi;

          const _inspectionTypeLovs =
            _inspectionTypeLovsIdentityPacket.data?.ReturnObj;
          const _inspectionType = _inspectionTypeIdentityPacket.data?.ReturnObj;

          //#region save data to store
          setInitialDataForms({
            GeneralFormLovs: _inspectionTypeLovs as InspectionTypeLovs,
            GeneralForm: _inspectionType as InspectionType,
          });
          //#endregion save data to store
          listenNotificationPortal();

          if (checkIsInactiveInspectionType()) {
            pushNotificationPortal(alertDeleteRecord, true);
          }
        },
      },
    ],
  });

  useEffect(() => {
    manageInspectionTypeSlider.fetchApi();
    return () => clearNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location]);

  return (
    <>
      <Loading
        isLoading={manageInspectionTypeSlider.isFetching || isLoadingForm}
        isFullScreen
      />
      <FormNavigation title={TitleInspectionTypeManagePage} />
      {manageInspectionTypeSlider.errorComponent ? (
        manageInspectionTypeSlider.errorComponent
      ) : isNew ? (
        <NewManageInspectionTypeComponent />
      ) : (
        <ExistManageInspectionType />
      )}
    </>
  );
};

export default observer(ManageInspectionType);
