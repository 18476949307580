import { getDDAccountItemTypeLOVs } from "@app/products/direct-debit/accounts/[id]/api";
import {
  DirectDebitAccount,
  IntegrationModuleItemType,
} from "@app/products/direct-debit/accounts/model";
import { IntegrationModuleType } from "@app/products/direct-debit/model";
import { isSuccessResponse } from "@common/apis/util";
import { useCancelRequest } from "@common/hooks/useCancelRequest";
import { nameOfFactory } from "@common/utils/common";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import { CCValueField } from "@components/cc-value-field/_index";
import { FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

interface IDDAccountDetailsSectionProps {
  formRenderProps: FormRenderProps;
  isDisabled?: boolean;
}
export interface IDirectDebitAccountManagePage extends DirectDebitAccount {
  _options?: {
    ItemTypesDisplay: string;
  };
}

const nameOf = nameOfFactory<IDirectDebitAccountManagePage>();

export const DDAccountDetailsSection = observer(
  ({ formRenderProps }: IDDAccountDetailsSectionProps) => {
    const { valueGetter, onChange } = formRenderProps;
    const getValue = (nameOfField: keyof IDirectDebitAccountManagePage) =>
      valueGetter(nameOf(nameOfField));

    const [isLoadingItemType, setIsLoadingItemType] = useState(false);
    const { cancelToken } = useCancelRequest();
    const { pushNotificationPortal } = useNotificationPortalStore();

    const getModuleType = async (moduleTypeId: number, itemTypeId: number) => {
      setIsLoadingItemType(true);
      const response = await getDDAccountItemTypeLOVs(
        cancelToken(),
        moduleTypeId
      );
      setIsLoadingItemType(false);
      if (isSuccessResponse(response) && response?.data) {
        const currentModuleType = response.data as IntegrationModuleItemType[];
        const moduleItemTypeName = currentModuleType.find(
          (item: IntegrationModuleItemType) => item.Item_Type_ID === itemTypeId
        )?.Name;
        onChange(nameOf("Integration_Module_Item_Type_Name"), {
          value: moduleItemTypeName,
        });
      } else {
        pushNotificationPortal({
          title: "Get module item type failed.",
          type: "error",
          autoClose: false,
        });
      }
    };
    useEffect(() => {
      if (!getValue("Integration_Module_Item_Type_Name")) {
        getModuleType(
          getValue("Integration_Module_Type_ENUM"),
          getValue("Item_Type")
        );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getValue("Integration_Module_Type_ENUM"), getValue("Item_Type")]);
    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <CCValueField
              value={getValue("Integration_Module_Type_Name")}
              label="Module type"
            />
          </div>
          <div className="cc-field">
            <CCValueField
              value={getValue("Account_Reference")}
              label="Account reference"
            />
          </div>
          <div className="cc-field">
            <CCValueField
              value={
                isLoadingItemType ? (
                  <i className="fas fa-spinner fa-spin" />
                ) : (
                  getValue("Integration_Module_Item_Type_Name")
                )
              }
              label="Item type"
            />
          </div>
        </div>

        {getValue("Integration_Module_Type_ENUM") ===
          IntegrationModuleType.PropertyRegister && (
          <div className="cc-form-cols-3">
            <CCValueField value={getValue("Register_ID")} label="Register ID" />
            <CCValueField
              value={getValue("Register_Account_ID")}
              label="Register account ID"
            />
            <CCValueField
              value={getValue("Property_Address")}
              label="Property address"
            />
          </div>
        )}

        {getValue("Integration_Module_Type_ENUM") ===
          IntegrationModuleType.PropertyAssessment && (
          <div className="cc-form-cols-3">
            <CCValueField
              value={getValue("AssessmentID")}
              label="Assessment ID"
            />
            <CCValueField
              value={getValue("Assessment_Number")}
              label="Assessment number"
            />
            <CCValueField
              value={getValue("Assessment_Reference")}
              label="Assessment reference"
            />
            <CCValueField
              value={getValue("Assessment_Reference")}
              label="Assessment reference"
            />
            <CCValueField
              value={getValue("Property_Address")}
              label="Property address"
            />
          </div>
        )}

        {getValue("Integration_Module_Type_ENUM") ===
          IntegrationModuleType.CommunityFinance && (
          <div className="cc-form-cols-3">
            <CCValueField
              value={getValue("Finance_Customer_No")}
              label="Finance customer number"
            />
            <CCValueField
              value={getValue("Finance_Invoice_No")}
              label="Finance invoice number"
            />
          </div>
        )}
      </section>
    );
  }
);
