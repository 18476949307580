import { eventEmitter } from "@/App";
import { VO_Workflow_Draft } from "@app/products/property/actions/model";
import { listSubmitButton } from "@app/products/property/assessments/components/form-steps/new-assessment/config";
import {
  getInitialNewCertificate,
  getWorkflowNewCertificate,
  postProcessNewCertificate,
} from "@app/products/property/certificates/[id]/components/form-steps/new-certificate/api";
import { CertificateDetailFormStep } from "@app/products/property/certificates/[id]/components/form-steps/new-certificate/components/form-elements/certificate-details/_index";
import { eCertificateTypeName } from "@app/products/property/certificates/[id]/components/form-steps/new-certificate/components/form-elements/certificate-details/model";
import { useCertificateDetailStepStore } from "@app/products/property/certificates/[id]/components/form-steps/new-certificate/components/form-elements/certificate-details/store";
import { NewCertificateDetailFormStep } from "@app/products/property/certificates/[id]/components/form-steps/new-certificate/components/form-elements/details/_index";
import { useNewCertificateDetailStepStoreContext } from "@app/products/property/certificates/[id]/components/form-steps/new-certificate/components/form-elements/details/store";
import { NamesFormStep } from "@app/products/property/certificates/[id]/components/form-steps/new-certificate/components/form-elements/names/_index";
import { QuestionnaireFormStep } from "@app/products/property/certificates/[id]/components/form-steps/new-certificate/components/form-elements/responses/_index";
import {
  DTO_Certificate_LOVs,
  DTO_PIC,
  DTO_Workflow_CertificateCreate,
  NewCertificateKeysOfSteps,
  keysOfSendSteps,
} from "@app/products/property/certificates/[id]/components/form-steps/new-certificate/model";
import { PROPERTY_CERTIFICATE_ROUTE } from "@app/products/property/certificates/[id]/constant";
import { getValueName } from "@app/products/property/charge-and-notice-runs/charge-runs/components/form-steps/create-charge-run/components/form-elements/details/util";
import { useConfirmCancelDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-cancel/store";
import { useConfirmCloseDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-close/store";
import { useConfirmFinishDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-finish/store";
import { useConfirmReallocateDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-reallocate/store";
import { useConfirmRejectDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-reject/store";
import { useConfirmSendBackDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-send-back/store";
import { CommentsFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/comments/_index";
import { DocumentsFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/documents/_index";
import { usePropertyWorkflow } from "@app/products/property/components/action-bar/property-workflow/component/hooks/useProprtyWorkflow/usePropertyWorkflow";
import { IProcessWorkflow } from "@app/products/property/components/action-bar/property-workflow/model";
import { getTitleWorkflow } from "@app/products/property/components/action-bar/property-workflow/util";
import {
  DTO_WorkflowHeader,
  EListSubmitButton,
  EWorkflowStatus,
  WorkflowProcessMode,
  WorkflowTypes,
} from "@app/products/property/model";
import { isShowParkButton } from "@app/products/property/util";
import { APIResponse } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ResponsePacket } from "@common/models/identityPacket";
import { CommunityProperty } from "@common/stores/products/config";
import { useCommonProductStore } from "@common/stores/products/store";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDialog } from "@components/cc-dialog/_index";
import {
  CCFormStep,
  ICCFormStepNotificationHandle,
  ICCFormStepRender,
} from "@components/cc-form-step/_index";
import { IStep } from "@components/cc-form-step/model";
import { CCGridEventType } from "@components/cc-grid/constant";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { Button } from "@progress/kendo-react-buttons";
import { head, isEqual, isNil, isObject, pickBy } from "lodash";
import React, { useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useEffectOnce } from "react-use";

interface INewCertificateDialog {
  onClose: () => void;
  prefixTitle?: string;
  suffixTitle?: string;
  dataFromActionList?: VO_Workflow_Draft;
  isSaveOnNextStep?: boolean;
}
export const NewCertificateDialog = ({
  onClose,
  isSaveOnNextStep = false,
  prefixTitle,
  suffixTitle,
  dataFromActionList,
}: INewCertificateDialog) => {
  //#region <Store>

  const { setCertificateDetailStepLOVs } = useCertificateDetailStepStore();
  const { pushNotification } = useCCAppNotificationStore();
  //set data for modes
  const { setDataForCancelDialog } = useConfirmCancelDialogStore();
  const { setDataForFinishDialog } = useConfirmFinishDialogStore();
  const { setDataForCloseDialog, setIsLoadingClose } =
    useConfirmCloseDialogStore();
  const { currentFormTitle } = useCommonProductStore();
  const { isLLS } = CommunityProperty.getFlagOfStates();
  const { clearSelectedItems } = useCCProductListViewStore();
  const { setDataForRejectDialog } = useConfirmRejectDialogStore();
  const { setDataForSendBackDialog } = useConfirmSendBackDialogStore();
  const { setDataForReallocateDialog } = useConfirmReallocateDialogStore();
  const { setLovDetail } = useNewCertificateDetailStepStoreContext();
  const history = useHistory();
  const { certificateDetailStepLOVs } = useCertificateDetailStepStore();
  const {
    isIncompleteMode,
    isFromActionList,
    isReadOnly,
    statusBadge,
    isShowCancelWorkflowButton,
    isToBeApprovalMode,
  } = usePropertyWorkflow(dataFromActionList);
  //#endregion

  //#region <Local state>
  const [isLoadingInStep, setIsLoadingInStep] = useState<boolean>(false);
  const formStepRef = useRef<ICCFormStepNotificationHandle | null>(null);
  const [workflowInitData, setWorkflowInitData] =
    useState<DTO_Workflow_CertificateCreate>();
  const [workflowHeader, setWorkflowHeader] = useState<DTO_WorkflowHeader>({
    WorkflowDraft: { Workflow_Draft_Id: 0 },
    AvailableSecondaryWorkflows: [],
    WorkflowApprovals: [],
  });
  const [workflowDraftId, setWorkflowDraftId] = useState<number>(0);
  const [isFirstSave, setIsFirstSave] = useState<boolean>(true);
  const [isLoadingApprove, setIsLoadingApprove] = useState<boolean>(false);
  const [isLoadingPark, setIsLoadingPark] = useState<boolean>(false);
  const [isLoadingOnNext, setIsLoadingOnNext] = useState<boolean>(false);
  const [listIDs, setListIDs] = useState<number[]>([]);
  //#endregion

  const certificateType =
    workflowInitData?.WorkflowDetail?.CertificateDetails?.CertificateType;

  const dynamicQuestionListId = useMemo(
    () =>
      getValueName(
        certificateType,
        certificateDetailStepLOVs ?? [],
        "DynamicQuestionListId"
      ),
    [certificateType, certificateDetailStepLOVs]
  );

  //#region <Title header>
  //Use memo
  const titleHeader = useMemo(() => {
    const formId = workflowHeader?.WorkflowDraft?.WD_Form_Id;
    const title = currentFormTitle(formId ?? 0) ?? "New Certificate";
    return getTitleWorkflow(title, prefixTitle, suffixTitle);
    // eslint-disable-next-line
  }, [workflowHeader, prefixTitle, suffixTitle]);
  //#endregion

  //region <Initial value>
  const initialValue = useMemo((): any => {
    const selectedPrimaryPicId =
      workflowInitData?.WorkflowDetail?.CertificateDetails?.SelectedPICs
        ?.Selected_Primary_PIC_Id;
    let initialDetail = {
      ...workflowInitData?.WorkflowDetail?.Details,
      ApplicantName: {
        Name: workflowInitData?.WorkflowDetail?.Details?.ApplicantName,
      },
      ReasonId:
        workflowInitData?.WorkflowDetail?.Details?.ReasonId === 0
          ? undefined
          : workflowInitData?.WorkflowDetail?.Details?.ReasonId,
      WD_Assessment_Group_Id:
        workflowInitData?.WorkflowHeader?.WorkflowDraft
          ?.WD_Assessment_Group_Id ??
        workflowInitData?.WorkflowHeader?.OfficerAssessmentGroups?.[0]?.Code ??
        null,
      Officer: workflowInitData?.WorkflowHeader?.Officer,
      OfficerAssessmentGroups:
        workflowInitData?.WorkflowHeader?.OfficerAssessmentGroups,
    };
    let newPicList: DTO_PIC[] = [];
    const picList =
      workflowInitData?.WorkflowDetail?.CertificateDetails?.SelectedPICs
        ?.PICs ?? [];
    if (picList?.length) {
      newPicList = picList.map((item: DTO_PIC) => {
        return {
          ...item,
          Is_Primary: item.PIC_Id === selectedPrimaryPicId,
        };
      });
    }
    let initCertificateDetails: any = {
      CertificateType: certificateType === 0 ? undefined : certificateType,
      Assessments:
        workflowInitData?.WorkflowDetail?.CertificateDetails?.Assessments ?? [],
      Parcels:
        workflowInitData?.WorkflowDetail?.CertificateDetails?.Parcels ?? [],
      AssociateTitles:
        workflowInitData?.WorkflowDetail?.CertificateDetails?.Titles ?? [],
      PICs: newPicList,
      Selected_Primary_PIC_Id: selectedPrimaryPicId,
    };
    let initNames: any = {
      ProposedSettlementDate:
        workflowInitData?.WorkflowDetail?.ProposedSettlementDate,
      Associated_Entities: workflowInitData?.WorkflowDetail?.Contact?.Contacts,
    };

    const isAdverseAffection = isEqual(
      getValueName(certificateType, certificateDetailStepLOVs ?? [], "Name"),
      eCertificateTypeName.AdverseAffectations
    );

    let initQuestionnaire: any = {
      DynamicQuestionList_ID:
        workflowInitData?.WorkflowDetail?.Questionnaire
          ?.DynamicQuestionList_ID ?? 0,
      Questions: workflowInitData?.WorkflowDetail?.Questionnaire?.Questions,
    };

    if (certificateType && isFromActionList) {
      formStepRef?.current?.setStepsVisible([
        {
          visible: isAdverseAffection,
          key: NewCertificateKeysOfSteps.Questionnaire,
          isClearData: false,
        },
      ]);
    }
    if (workflowInitData) {
      setWorkflowHeader(workflowInitData?.WorkflowHeader);
    }

    return {
      [NewCertificateKeysOfSteps.Details]: initialDetail,
      [NewCertificateKeysOfSteps.CertificateDetails]: initCertificateDetails,
      [NewCertificateKeysOfSteps.Names]: initNames,
      [NewCertificateKeysOfSteps.Questionnaire]: initQuestionnaire,
      [NewCertificateKeysOfSteps.Documents]: {},
      [NewCertificateKeysOfSteps.Comments]: {},
    };
    // eslint-disable-next-line
  }, [workflowInitData]);
  //#endregion

  const steps: IStep[] = [
    {
      label: "Details",
      component: NewCertificateDetailFormStep,
      visible: true,
      key: NewCertificateKeysOfSteps.Details,
      initialValues: initialValue[NewCertificateKeysOfSteps.Details],
      options: {
        isReadOnly,
        isDisabled: workflowDraftId,
        isDisabledStatus: true,
        isLLS,
        setIsLoadingInStep,
        isLoadingInStep,
        isToBeApprovalMode
      },
    },
    {
      label: "Certificate details",
      initialValues: initialValue[NewCertificateKeysOfSteps.CertificateDetails],
      component: CertificateDetailFormStep,
      visible: true,
      key: NewCertificateKeysOfSteps.CertificateDetails,
      options: {
        isReadOnly,
        isLLS,
        listIDs,
        setIsLoadingInStep,
      },
    },
    {
      label: "Names",
      initialValues: initialValue[NewCertificateKeysOfSteps.Names],
      component: NamesFormStep,
      visible: true,
      key: NewCertificateKeysOfSteps.Names,
      options: {
        isReadOnly,
      },
    },
    {
      label: "Responses",
      initialValues: initialValue[NewCertificateKeysOfSteps.Questionnaire],
      component: QuestionnaireFormStep,
      visible: false,
      key: NewCertificateKeysOfSteps.Questionnaire,
      options: {
        isReadOnly,
        id: dynamicQuestionListId,
      },
    },
    {
      label: "Comments",
      component: CommentsFormStep,
      visible: true,
      key: NewCertificateKeysOfSteps.Comments,
      customClassName: "cc-comment-step-fixed-height-grid",
      options: {
        isReadOnly,
        workflowDraftId,
        recordType: RECORDTYPE.CommunityProperty_Certificate,
      },
      initialValues: initialValue[NewCertificateKeysOfSteps.Comments],
    },
    {
      label: "Documents",
      component: DocumentsFormStep,
      visible: true,
      key: NewCertificateKeysOfSteps.Documents,
      options: {
        isReadOnly,
        workflowDraftId,
        workflowType: WorkflowTypes.Certificate_Create,
      },
      initialValues: initialValue[NewCertificateKeysOfSteps.Documents],
    },
  ];

  //#region handle Cancel Button>
  const handleCancelButton = (data: any) => {
    if (isFromActionList || !isFirstSave) {
      setDataForCancelDialog({
        cancelAPI: postProcessNewCertificate,
        dataCancel: data,
        defaultSuccessMessage: "New certificate was cancelled successfully.",
        defaultErrorMessage: "New certificate could not be cancelled.",
      });
    } else {
      onClose();
    }
  };
  //#endregion

  //#region <Handle close dialog>
  /**
   * @param renderProps
   */
  const handleCloseDialog = (renderProps: ICCFormStepRender) => {
    if (!isFromActionList && !isFirstSave) {
      //Store submit event
      setDataForCloseDialog({
        closeCallback: renderProps.submitButton.onClick,
      });
    } else if (
      isIncompleteMode &&
      dataFromActionList?.Workflow_Status_Name === EWorkflowStatus.Park
    ) {
      onClose();
    } else if (
      dataFromActionList?.Workflow_Status_Name === EWorkflowStatus.Incomplete &&
      !isFirstSave
    ) {
      const newEvent = {
        currentTarget: { id: EListSubmitButton.Close },
      };
      renderProps.submitButton.onClick(newEvent);
    } else {
      onClose();
    }
  };
  //#endregion

  //#region <Handle save and next>
  const handleSaveAndNext = async (
    payload: DTO_Workflow_CertificateCreate,
    isCloseDialog: boolean = false,
    isRefreshWorkflowData: boolean = false
  ): Promise<boolean> => {
    //Calling process Save at next button
    const response = await postProcessNewCertificate(
      WorkflowProcessMode.Save,
      payload
    );
    setIsLoadingOnNext(false);

    //set default notification
    const defaultSuccessMessage = "New certificate was saved successfully.";
    const defaultFailedMessage = "New certificate could not be saved.";
    if (isSuccessResponse(response) && response?.data?.IsSuccess) {
      if (isCloseDialog) {
        onClose();
        pushNotification({
          title: response?.data?.Notification ?? defaultSuccessMessage,
          type: "success",
        });
      }
      // check is the first saving
      if (isFirstSave) {
        setIsFirstSave(false);
        //set current workflowDraft Id
        setWorkflowDraftId(response?.data?.ID || 0);
        // set payload to send
        setWorkflowHeader({
          ...workflowHeader,
          WorkflowDraft: {
            ...workflowHeader.WorkflowDraft,
            Workflow_Draft_Id: response?.data?.ID || workflowDraftId || 0,
          },
        });
        // get new data from draftId after first save
        if (!isFromActionList) {
          refreshWorkflowData(response?.data?.ID || 0);
        }
      }
      // TODO: Show notification after reloading the step -> enhance later
      if (isRefreshWorkflowData) {
        getWorkflowData().then(() => {
          formStepRef?.current
            ?.getNotificationFormStep()
            ?.current?.pushNotification({
              title: response?.data?.Notification ?? defaultSuccessMessage,
              type: "success",
            });
        });
      }
      return true;
    } else {
      const showNotification = () => {
        formStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title:
              (isRefreshWorkflowData
                ? head(response?.data?.Errors)
                : response.data?.ErrorMessage) ?? defaultFailedMessage,
            type: "error",
            autoClose: false,
          });
      };
      if (isRefreshWorkflowData) {
        getWorkflowData().then(showNotification);
      } else {
        showNotification();
      }
      return false;
    }
  };
  //#endregion

  //#region <Handle next button>
  /**
   * @param data
   * @param step
   * @param keyStep
   * @returns
   */
  const handleNextButton = async (data: DTO_Workflow_CertificateCreate) => {
    setIsLoadingOnNext(true);
    const processPayload = processData(data);
    //send data to call api save
    return handleSaveAndNext(processPayload);
  };
  //#endregion

  //#region <Handle park process>
  /**
   * @param payload
   */
  const handleParkProcess = async (payload: DTO_Workflow_CertificateCreate) => {
    setIsLoadingPark(true);
    const parkProps: IProcessWorkflow<DTO_Workflow_CertificateCreate> = {
      payload: payload,
      actionSuccess: (e) => {
        onClose();
        pushNotification({
          title:
            e?.Notification ??
            e?.SuccessMessage ??
            "New certificate was parked successfully.",
          type: "success",
        });
      },
      defaultFailedMessage: "New certificate could not be parked.",
      modeProcess: WorkflowProcessMode.Park,
    };

    const setLoading = () => setIsLoadingPark(false);

    //calling api process workflow
    await handleProcessWorkflow(parkProps, setLoading);
  };
  //#endregion

  //#region
  const handleSubmit = async (data: any, buttonId?: string, actions?: any) => {
    switch (buttonId) {
      case EListSubmitButton.Save:
        const response = await handleSaveAndNext(processData(data));
        if (actions?.reloadForm && response) {
          refreshWorkflowData(workflowDraftId || 0);
        }
        break;
      case EListSubmitButton.Approve:
        await handleApproveProcess(processData(data));
        break;
      case EListSubmitButton.Finish:
        handleConfirmFinishProcess(data);
        break;
      case EListSubmitButton.Cancel:
      case EListSubmitButton.ConfirmCloseNo:
        handleCancelButton(processData(data));
        break;
      case EListSubmitButton.Park:
      case EListSubmitButton.Close:
        await handleParkProcess(processData(data));
        break;
      case EListSubmitButton.Reject:
        handleRejectButton(processData(data));
        break;
      case EListSubmitButton.SendBack:
        handleConfirmSendBackProcess(processData(data));
        break;
      case EListSubmitButton.Reallocate:
        handleConfirmReallocateProcess(processData(data));
        break;
      case EListSubmitButton.ConfirmCloseYes:
        await handleConfirmRetainProcess(processData(data));
        break;
    }
  };
  //#endregion

  /**
   * handle confirm retain workflow process
   * @param payload
   */
  const handleConfirmRetainProcess = async (
    payload: DTO_Workflow_CertificateCreate
  ) => {
    //set loading button and dialog
    setIsLoadingClose(true);

    //props send to process workflow
    const parkProps: IProcessWorkflow<DTO_Workflow_CertificateCreate> = {
      payload: payload,
      actionSuccess: (e) => {
        onClose();
        pushNotification({
          title:
            e?.Notification ??
            e?.SuccessMessages ??
            `New certificate was parked successfully.`,
          type: "success",
        });
      },
      defaultFailedMessage: `New certificate could not be parked.`,
      modeProcess: WorkflowProcessMode.Park,
    };

    const setLoading = () => {
      setIsLoadingClose(false);
      setDataForCloseDialog(undefined);
    };

    //calling api process workflow
    await handleProcessWorkflow(parkProps, setLoading);
  };

  /**
   * handle confirm reallocate workflow process
   * @param payload
   */
  const handleConfirmReallocateProcess = (
    payload: DTO_Workflow_CertificateCreate
  ) => {
    setDataForReallocateDialog({
      reallocateCallback: postProcessNewCertificate,
      dataReallocate: payload,
      defaultSuccessMessage: `Certificate approved successfully`,
      defaultErrorMessage: `Approve certificate failed`,
    });
  };

  /**
   * handle confirm send back workflow process
   * @param payload
   */
  const handleConfirmSendBackProcess = (
    payload: DTO_Workflow_CertificateCreate
  ) => {
    setDataForSendBackDialog({
      sendBackCallback: postProcessNewCertificate,
      dataSendBack: payload,
      defaultSuccessMessage: `Certificate approval sent back successfully`,
      defaultErrorMessage: `Send certificate back failed`,
    });
  };

  /**
   * handle finish workflow process
   * @param payload
   */
  const handleConfirmFinishProcess = (
    payload: DTO_Workflow_CertificateCreate
  ) => {
    const dataProcessed = processData(payload);
    const finishCallback = function async() {
      return handleFinishProcess(dataProcessed);
    };
    setDataForFinishDialog({
      finishCallback,
      confirmMessage: `The certificate will be created based on the information provided. Are you sure you want to submit?`,
    });
  };

  /**
   * Handle reject process
   */
  const handleRejectButton = (data: any) => {
    if (isFromActionList || !isFirstSave) {
      setDataForRejectDialog({
        rejectCallback: postProcessNewCertificate,
        dataReject: data,
        defaultSuccessMessage: `New certificate application was rejected successfully`,
        defaultErrorMessage: `New certificate reject failed`,
      });
    } else {
      onClose();
    }
  };

  /**
   * handle approve process
   */
  const handleApproveProcess = async (
    payload: DTO_Workflow_CertificateCreate
  ) => {
    //set loading button
    setIsLoadingApprove(true);

    //props send to process workflow
    const approveProps: IProcessWorkflow<DTO_Workflow_CertificateCreate> = {
      payload: payload,
      actionSuccess: (e) => {
        onClose();
        clearSelectedItems();
        eventEmitter.emit(CCGridEventType.RefreshOData);
        pushNotification({
          title: e?.Notification ?? `New certificate approved successfully`,
          type: "success",
        });
      },
      defaultFailedMessage: `Approve new certificate failed`,
      modeProcess: WorkflowProcessMode.Approve,
    };

    const setLoading = () => {
      setIsLoadingApprove(false);
    };
    //calling api process workflow
    await handleProcessWorkflow(approveProps, setLoading);
  };

  const processData = (data: any) => {
    let workflowDetail: any = {};
    // process workflow header to send the WD_Assessment_Group_Id/ Officer Region Id
    workflowHeader.WorkflowDraft.WD_Assessment_Group_Id =
      +data?.[NewCertificateKeysOfSteps.Details]?.WD_Assessment_Group_Id;
    const sendSteps = pickBy(data, function (value, key) {
      if (keysOfSendSteps.includes(key as NewCertificateKeysOfSteps)) {
        return { [key]: value };
      }
    });
    for (const [key, value] of Object.entries(sendSteps)) {
      const dataStep = { ...value };
      if (dataStep && dataStep?._option) {
        delete dataStep._option;
      }
      workflowDetail[key] = dataStep;
      workflowDetail = {
        ...workflowDetail,
        ProposedSettlementDate:
          workflowInitData?.WorkflowDetail?.ProposedSettlementDate,
      };
      workflowDetail.Details.ApplicantName = isObject(
        data?.Details?.ApplicantName
      )
        ? data?.Details?.ApplicantName?.Name
        : data?.Details?.ApplicantName;
      if (key === NewCertificateKeysOfSteps.CertificateDetails) {
        workflowDetail.CertificateDetails = {
          ...workflowDetail.CertificateDetails,
          SelectedPICs: {
            PIC_Ids: data?.CertificateDetails?.PIC_Ids ?? listIDs ?? [],
            Selected_Primary_PIC_Id:
              data?.CertificateDetails?.Selected_Primary_PIC_Id,
          },
        };
        workflowDetail.CertificateDetails.Titles =
          data?.CertificateDetails?.AssociateTitles ?? [];
        workflowDetail.CertificateDetails.Parcels =
          data?.CertificateDetails?.Parcels ?? [];
        delete workflowDetail.CertificateDetails.AssessmentSelected;
        delete workflowDetail.CertificateDetails.AssociateTitles;
        delete workflowDetail.CertificateDetails.PICs;
        delete workflowDetail.CertificateDetails.Selected_Primary_PIC_Id;
        delete workflowDetail.CertificateDetails.PIC_Ids;
      }
      if (key === NewCertificateKeysOfSteps.Names) {
        workflowDetail.Contact = {
          ...workflowDetail.Contact,
          Contacts: data?.Names?.Associated_Entities,
        };
        delete workflowDetail.Names;
      }
      if (
        data?.CertificateDetails?.PIC_Ids?.length < 1 ||
        listIDs?.length < 1
      ) {
        workflowDetail = {
          ...workflowDetail,
          Questionnaire: { ...workflowDetail.Questionnaire, Questions: [] },
        };
      }
    }

    return {
      WorkflowHeader: workflowHeader,
      WorkflowDetail: workflowDetail,
    };
  };

  //#region <Handle process workflow>
  /**
   * common function
   * handle calling api with multiple process
   * @param props
   */
  const handleProcessWorkflow = async (
    props: IProcessWorkflow<DTO_Workflow_CertificateCreate>,
    setLoading: () => void
  ) => {
    const { actionSuccess, defaultFailedMessage, modeProcess, payload } = props;
    const response = await postProcessNewCertificate(modeProcess, payload);
    setLoading();
    if (isSuccessResponse(response)) {
      if (response?.data?.IsSuccess) {
        actionSuccess(response?.data);
      } else {
        formStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title: response.data?.ErrorMessage ?? defaultFailedMessage,
            type: "error",
            autoClose: false,
          });
      }
    } else {
      formStepRef?.current
        ?.getNotificationFormStep()
        ?.current?.pushNotification({
          title: response?.data?.ErrorMessage ?? defaultFailedMessage,
          type: "error",
          autoClose: false,
        });
    }
  };
  //#endregion

  //#region <Handle finish process>
  /**
   * @param payload
   */
  const handleFinishProcess = async (
    payload: DTO_Workflow_CertificateCreate
  ) => {
    //props send to process workflow
    const finishProps: IProcessWorkflow<DTO_Workflow_CertificateCreate> = {
      payload: payload,
      actionSuccess: (e) => {
        const notificationContent: IAppNotificationItemAddProps = {
          title:
            e?.SuccessMessage ??
            e?.Notification ??
            "New certificate created successfully",
          type: "success",
        };
        onClose();
        if (
          workflowInitData?.WorkflowHeader?.WorkflowApprovals?.length === 0 &&
          !isNil(e?.Component_ID)
        ) {
          history.push(`${PROPERTY_CERTIFICATE_ROUTE}/${e?.Component_ID}`, {
            notification: notificationContent,
          });
        } else {
          pushNotification(notificationContent);
        }
      },
      defaultFailedMessage: `Create new certificate failed`,
      modeProcess: WorkflowProcessMode.Finish,
    };

    const setLoading = () => {};
    //calling api process workflow
    await handleProcessWorkflow(finishProps, setLoading);
  };
  //#endregion

  /**
   * Refresh workflow data
   * @param workflowDraftId
   */
  const refreshWorkflowData = async (workflowDraftId: number) => {
    formStepRef?.current?.setLoadingFormStep(true);
    const getWorkflowResponse = await getWorkflowNewCertificate(
      workflowDraftId
    );
    formStepRef?.current?.setLoadingFormStep(false);
    if (isSuccessResponse(getWorkflowResponse) && getWorkflowResponse?.data) {
      setWorkflowInitData({
        WorkflowDetail: getWorkflowResponse.data?.WorkflowDetail,
        WorkflowHeader: getWorkflowResponse.data?.WorkflowHeader,
      });
      setListIDs(
        getWorkflowResponse.data?.WorkflowDetail?.CertificateDetails
          ?.SelectedPICs?.PIC_Ids ?? []
      );
    } else {
      formStepRef?.current?.setLoadFailedFormStep({
        onReload: () => refreshWorkflowData(workflowDraftId),
        responseError: {
          status: getWorkflowResponse.status,
          error: getWorkflowResponse.error ?? "Load workflow failed",
        },
      });
    }
  };

  //#region <Get workflow data>
  /**
   * load initValue for FormStep
   * call once time
   */
  const getWorkflowData = async (id?: number) => {
    let workflowDraftId: number | undefined =
      dataFromActionList?.Workflow_Draft_Id ??
      workflowHeader?.WorkflowDraft?.Workflow_Draft_Id;
    formStepRef?.current?.setLoadingFormStep(true);
    const response = await getInitialNewCertificate(
      WorkflowTypes.Certificate_Create,
      workflowDraftId
    );
    formStepRef?.current?.setLoadingFormStep(false);
    if (Array.isArray(response)) {
      const [lovsCertificate, workflowData] = response;
      if (
        isSuccessResponse(lovsCertificate) &&
        isSuccessResponse(workflowData) &&
        lovsCertificate?.data &&
        workflowData?.data
      ) {
        const lovPic = lovsCertificate?.data;
        //set Lov Detail step
        setLovDetail(lovPic?.ReasonForCreate ?? []);
        //set Lovs Data
        setCertificateDetailStepLOVs(lovPic?.CertificateType);
        setWorkflowInitData(workflowData?.data);
        setWorkflowInitData({
          WorkflowDetail: workflowData.data?.WorkflowDetail,
          WorkflowHeader: workflowData.data?.WorkflowHeader,
        });
        if (workflowData.data?.WorkflowHeader) {
          setWorkflowHeader(workflowData.data?.WorkflowHeader);
          setWorkflowDraftId(
            workflowData.data?.WorkflowHeader?.WorkflowDraft
              ?.Workflow_Draft_Id ?? 0
          );
        }
        setListIDs(
          workflowData?.data?.WorkflowDetail?.CertificateDetails?.SelectedPICs
            ?.PIC_Ids ?? []
        );
      } else {
        let responseError: APIResponse<
          DTO_Certificate_LOVs | DTO_Workflow_CertificateCreate | ResponsePacket
        > = response[0];
        if (!isSuccessResponse(response[1])) {
          responseError = response[1];
        }
        formStepRef?.current?.setLoadFailedFormStep({
          onReload: () => getWorkflowData(),
          responseError: {
            status: responseError.status,
            error:
              (responseError.data as ResponsePacket)?.Errors ??
              "Load workflow failed",
          },
        });
      }
    } else {
      const responseError = response as APIResponse;
      formStepRef?.current?.setLoadFailedFormStep({
        onReload: () => getWorkflowData(),
        responseError: {
          status: responseError.status,
          error: "Load workflow failed",
        },
      });
    }
  };
  //#endregion

  //#region <Use Effect calling initial data>
  useEffectOnce(() => {
    getWorkflowData();
  });
  //#endregion

  return (
    <CCFormStep
      ref={formStepRef}
      onSubmit={handleSubmit}
      initialSteps={steps}
      initialValues={initialValue}
      listButtonId={listSubmitButton}
      saveOnNextStep={isSaveOnNextStep ? handleNextButton : undefined}
      renderForm={(renderProps: ICCFormStepRender) => (
        <CCDialog
          maxWidth="60%"
          titleHeader={titleHeader}
          onClose={() => handleCloseDialog(renderProps)}
          bodyElement={renderProps.children}
          badge={statusBadge}
          disabled={isLoadingApprove || isLoadingInStep}
          footerElement={
            <>
              <div className={"cc-dialog-footer-actions-right"}>
                {isShowParkButton(isFromActionList, isIncompleteMode) && (
                  <Button
                    iconClass={isLoadingPark ? "fas fa-spinner fa-spin" : ""}
                    className={"cc-dialog-button"}
                    id={EListSubmitButton.Park}
                    onClick={renderProps.submitButton.onClick}
                    disabled={renderProps.nextButton.disabled || isLoadingPark}
                  >
                    Park
                  </Button>
                )}
                {isShowCancelWorkflowButton && (
                  <Button
                    className={"cc-dialog-button"}
                    disabled={
                      isLoadingApprove || isLoadingOnNext || isLoadingPark
                    }
                    id={EListSubmitButton.Cancel}
                    onClick={renderProps.submitButton.onClick}
                  >
                    Cancel
                  </Button>
                )}

                {isToBeApprovalMode && workflowHeader?.OfficerCanApprove && (
                  <>
                    <Button
                      themeColor="primary"
                      id={EListSubmitButton.SendBack}
                      disabled={renderProps.nextButton.disabled}
                      className={"cc-dialog-button"}
                      onClick={renderProps.submitButton.onClick}
                    >
                      Send Back
                    </Button>
                    <Button
                      themeColor="primary"
                      id={EListSubmitButton.Reallocate}
                      disabled={renderProps.nextButton.disabled}
                      className={"cc-dialog-button"}
                      onClick={renderProps.submitButton.onClick}
                    >
                      Reallocate
                    </Button>
                    <Button
                      themeColor="primary"
                      id={EListSubmitButton.Approve}
                      disabled={
                        isLoadingApprove || renderProps.nextButton.disabled
                      }
                      className={"cc-dialog-button"}
                      onClick={renderProps.submitButton.onClick}
                      iconClass={
                        isLoadingApprove ? "fas fa-spinner fa-spin" : ""
                      }
                    >
                      Approve
                    </Button>
                    <Button
                      themeColor="primary"
                      id={EListSubmitButton.Reject}
                      disabled={renderProps.nextButton.disabled}
                      className={"cc-dialog-button"}
                      onClick={renderProps.submitButton.onClick}
                    >
                      Reject
                    </Button>
                  </>
                )}
                {!renderProps.prevButton.disabled && (
                  <Button
                    className={"cc-dialog-button"}
                    themeColor="primary"
                    onClick={renderProps.prevButton.onClick}
                  >
                    Previous
                  </Button>
                )}
                {isToBeApprovalMode || isReadOnly ? (
                  !renderProps.isLastStep && (
                    <Button
                      themeColor="primary"
                      id="cc-next-step-button"
                      disabled={
                        isLoadingOnNext || renderProps.nextButton.disabled
                      }
                      className={"cc-dialog-button"}
                      iconClass={
                        isLoadingOnNext ? "fas fa-spinner fa-spin" : ""
                      }
                      onClick={renderProps.nextButton.onClick}
                    >
                      {isLoadingOnNext
                        ? "Saving"
                        : renderProps.nextButton.label}
                    </Button>
                  )
                ) : (
                  <Button
                    themeColor="primary"
                    id={renderProps.nextButton.idButton}
                    disabled={
                      isLoadingOnNext || renderProps.nextButton.disabled
                    }
                    iconClass={isLoadingOnNext ? "fas fa-spinner fa-spin" : ""}
                    className={"cc-dialog-button"}
                    onClick={renderProps.nextButton.onClick}
                  >
                    {isLoadingOnNext ? "Saving" : renderProps.nextButton.label}
                  </Button>
                )}
              </div>
            </>
          }
        />
      )}
    />
  );
};
