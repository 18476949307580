import { DirectDebitBankAccountFormElement } from "@app/products/direct-debit/system-admin/bank-accounts/[id]/components/general/components/form-element/_index";
import { checkIsInactiveDirectDebitBankAccount } from "@app/products/direct-debit/system-admin/bank-accounts/[id]/util";
import { DirectDebitBankAccount } from "@app/products/direct-debit/system-admin/model";
import { TOnSubmitFlexible } from "@common/stores/flexible-form/model";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import {
  Form,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef } from "react";

export const DirectDebitBankAccountForm = observer(() => {
  //#region STORE ========/
  const { dataForms, middlewareSubmitForm, setOnSubmitFlexible } =
    useFlexibleFormStore();
  //#endregion STORE =====/

  //#region HOOK ========/
  const { pushNotificationPortal } = useNotificationPortalStore();
  //#endregion HOOK =====/

  //#region METHOD ========/
  const handleOnSubmit = (event: FormSubmitClickEvent) => {
    middlewareSubmitForm(event);
    if (!event.isValid) {
      pushNotificationPortal({
        title: "Please enter all required information.",
        type: "warning",
      });
    } else if (!event.isModified) {
      pushNotificationPortal({
        title: "The form is not modified.",
        type: "warning",
      });
    }
  };
  //#endregion METHOD =====/

  //#region INITIAL_VALUES ========/
  const directDebitBankAccount =
    dataForms?.GeneralForm as DirectDebitBankAccount;
  //#endregion INITIAL_VALUES =====/

  //#region setOnSubmitFlexible ========/
  const submitRef = useRef<TOnSubmitFlexible>();
  useEffect(() => {
    if (submitRef.current) setOnSubmitFlexible(submitRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitRef.current]);
  //#endregion setOnSubmitFlexible =====/

  return (
    <div className="cc-form">
      <Form
        onSubmitClick={handleOnSubmit}
        initialValues={directDebitBankAccount}
        key={JSON.stringify(directDebitBankAccount)}
        render={(formRenderProps: FormRenderProps) => {
          submitRef.current = {
            formName: "GeneralForm",
            eventSubmit: formRenderProps.onSubmit,
          };
          return (
            <CCGeneralPanel
              isInvalid={!formRenderProps.valid}
              component={
                <DirectDebitBankAccountFormElement
                  isInactive={checkIsInactiveDirectDebitBankAccount()}
                  formRenderProps={formRenderProps}
                />
              }
            />
          );
        }}
      />
    </div>
  );
});
