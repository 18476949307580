import { getContact } from "@app/core/contacts/_id/api";
import { Svc_Contact } from "@app/core/contacts/_id/model";
import {
  getDirectDebitAccountById,
  getDirectDebitAccountLOVs,
} from "@app/products/direct-debit/accounts/[id]/api";
import {
  ChangeStatusSlider,
  TitleDDAccountManagePage,
} from "@app/products/direct-debit/accounts/[id]/constant";
import { ExistManageDirectDebitAccount } from "@app/products/direct-debit/accounts/[id]/existed/_index";
import { checkDisabledStatus } from "@app/products/direct-debit/accounts/[id]/util";
import {
  DirectDebitAccount,
  DirectDebitAccountLOVs,
} from "@app/products/direct-debit/accounts/model";
import { APIResponse } from "@common/apis/model";
import { useFlexibleFetchData } from "@common/hooks/flexible-fetch-data/useFlexibleFetchData";
import { useCancelRequest } from "@common/hooks/useCancelRequest";
import { useIsNew } from "@common/hooks/useIsNew";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { EWatchStatusLoading } from "@common/stores/route/model";
import { routeDataStoreInstance } from "@common/stores/route/store";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import Loading from "@components/loading/Loading";
import { merge } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

const ManageDirectDebitAccount = () => {
  //#region STORE ========/
  const history = useHistory();
  const { setInitialDataForms, resetDataForm, isLoadingForm } =
    useFlexibleFormStore();
  const {
    pushNotificationPortal,
    clearNotifications,
    setNotificationsCurrentPage,
  } = useNotificationPortalStore();
  useEffectOnce(() => {
    return () => {
      resetDataForm();
      clearNotifications();
    };
  });
  //#endregion STORE =====/

  //#region HOOK ========/
  const { cancelToken } = useCancelRequest();
  const isNew = useIsNew();
  //#endregion HOOK =====/

  //#region Variable ========/
  let { id } = useParams<{ id: string }>();
  const accountId = useMemo(() => {
    return isNew ? "0" : id;
  }, [isNew, id]);
  //#endregion Variable =====/

  const manageDirectDebitAccountSlider = useFlexibleFetchData({
    slides: [
      {
        fetch: [
          () => getDirectDebitAccountById(+accountId, cancelToken()),
          () => getDirectDebitAccountLOVs(cancelToken()),
        ],
        handleSuccess: ({ dataFromApi }) => {
          const response: [
            APIResponse<DirectDebitAccount>,
            APIResponse<DirectDebitAccountLOVs>
          ] = dataFromApi;
          const ddAccountFormData = response[0]?.data;
          if (checkDisabledStatus(ddAccountFormData?.AccountStatus_ENUM)) {
            pushNotificationPortal({
              title: `You are viewing a ${ddAccountFormData.AccountStatus_Name?.toLowerCase()} account. You are not allowed to perform any Workflow functions or make changes to this record`,
              type: "info",
              autoClose: false,
            });
          }
        },
        extractParamForNextAPI: ({ dataFromApi }) => {
          return dataFromApi;
        },
      },
      {
        fetch: ({ dataFromExtractParam }) => {
          const response: [
            APIResponse<DirectDebitAccount>,
            APIResponse<DirectDebitAccountLOVs>
          ] = dataFromExtractParam;

          return getContact(response[0]?.data?.Contact_ID!);
        },
        handleSuccess: ({ dataFromApi, dataFromExtractParam }) => {
          const response: [
            APIResponse<DirectDebitAccount>,
            APIResponse<DirectDebitAccountLOVs>
          ] = dataFromExtractParam;

          const contact: Svc_Contact = dataFromApi?.data;
          const ddAccountFormData = response[0]?.data;
          const ddAccountFormDataAddOptions = merge({}, ddAccountFormData, {
            CCFormOptions: {
              contact,
              initialContact: contact,
              initialName: ddAccountFormData?.Name,
            },
          });
          const ddAccountLOVs = response[1]?.data;
          setInitialDataForms({
            GeneralForm: ddAccountFormDataAddOptions,
            GeneralFormLovs: ddAccountLOVs,
          });
        },
        handleError: () => {
          pushNotificationPortal({
            title: `Loading name failed.`,
            type: "error",
            autoClose: false,
          });
        },
      },
    ],
  });

  const changeStatusSlider = useFlexibleFetchData({
    alias: ChangeStatusSlider,
    slides: [
      {
        fetch: () => getDirectDebitAccountById(+id, cancelToken()),
        handleSuccess: ({ dataFromApi }) => {
          const response: APIResponse<DirectDebitAccount> = dataFromApi;
          const ddAccountFormData = response.data;
          if (checkDisabledStatus(ddAccountFormData?.AccountStatus_ENUM)) {
            pushNotificationPortal({
              title: `You are viewing a ${ddAccountFormData.AccountStatus_Name?.toLowerCase()} account. You are not allowed to perform any Workflow functions or make changes to this record`,
              type: "info",
              autoClose: false,
              level: 1,
            });
          } else {
            setNotificationsCurrentPage([]);
          }
        },
        extractParamForNextAPI: ({ dataFromApi }) => {
          return dataFromApi;
        },
      },
      {
        fetch: ({ dataFromExtractParam }) => {
          const response: APIResponse<DirectDebitAccount> =
            dataFromExtractParam;
          return getContact(response?.data?.Contact_ID!);
        },
        handleSuccess: ({ dataFromApi, dataFromExtractParam }) => {
          const response: APIResponse<DirectDebitAccount> =
            dataFromExtractParam;
          const contact: Svc_Contact = dataFromApi?.data;
          const ddAccountFormData = response.data;
          const ddAccountFormDataAddOptions = merge({}, ddAccountFormData, {
            CCFormOptions: {
              contact: contact,
              initialContact: contact,
              initialName: ddAccountFormData?.Name,
            },
          });
          setInitialDataForms({
            GeneralForm: ddAccountFormDataAddOptions,
          });
        },
        handleError: () => {
          pushNotificationPortal(
            {
              title: `Loading name failed.`,
              type: "error",
              autoClose: false,
            },
            true
          );
        },
      },
    ],
  });

  useEffect(() => {
    manageDirectDebitAccountSlider.fetchApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location]);

  return (
    <>
      <Loading
        isLoading={
          manageDirectDebitAccountSlider.isFetching ||
          changeStatusSlider.isFetching ||
          routeDataStoreInstance.isLoadingFor(
            EWatchStatusLoading.IsLoadingPermission
          ) ||
          isLoadingForm
        }
        isFullScreen
      />
      <FormNavigation title={TitleDDAccountManagePage} />
      {manageDirectDebitAccountSlider.errorComponent ? (
        manageDirectDebitAccountSlider.errorComponent
      ) : (
        <ExistManageDirectDebitAccount />
      )}
    </>
  );
};

export default observer(ManageDirectDebitAccount);
