import { getDDAccountItemTypeLOVs } from "@app/products/direct-debit/accounts/[id]/api";
import { getKeyValueFromLOVs } from "@app/products/direct-debit/accounts/[id]/util";
import {
  ASSESSMENT_LOOKUP_API,
  CREATE_ACCOUNT_GET_ITEM_TYPE,
  DD_CREATE_ACCOUNT,
  FINANCE_CUSTOMER_LOOKUP_API,
  FINANCE_INVOICE_LOOKUP_API,
  REGISTER_LOOKUP_API,
} from "@app/products/direct-debit/accounts/components/dialogs/create-account/constant";
import { IDirectDebitAccountFormStepProps } from "@app/products/direct-debit/accounts/components/dialogs/create-account/steps/general";
import { ComboboxDDSearchAPI } from "@app/products/direct-debit/accounts/components/fields/combobox-search-api";
import { PropertyLookupPicker } from "@app/products/direct-debit/accounts/components/fields/property-lookup-field";
import {
  DirectDebitAccountLOVs,
  FinanceCustomer,
  FinanceCustomerLedgerEntries,
  IntegrationModuleItemType,
} from "@app/products/direct-debit/accounts/model";
import { IntegrationModuleType } from "@app/products/direct-debit/model";
import { DTO_Assessment } from "@app/products/property/components/dialogs/add-assessment-lookup/model";
import { APIResponse } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { CURRENCY_FORMAT } from "@common/constants/common-format";
import { APIResponseStatus } from "@common/constants/response-status";
import {
  fetchApiByAlias,
  useFlexibleFetchData,
} from "@common/hooks/flexible-fetch-data/useFlexibleFetchData";
import { useCancelRequest } from "@common/hooks/useCancelRequest";
import { ResponsePacket } from "@common/models/identityPacket";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCLabel } from "@components/cc-label/_index";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { isEmpty, isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";
import { useEffectOnce } from "react-use";

const nameOf = nameOfFactory<IDirectDebitAccountFormStepProps>();
const nameOfAssess = nameOfFactory<DTO_Assessment>();
export const DDAccountDetailsStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const FormStepElement = observer(({ formRenderProps }: IFormStepElement) => {
  const { valueGetter, onChange } = formRenderProps;

  const [isFetching, setIsFetching] = useState(false);
  const { dataForms, setInitialDataForms } = useFlexibleFormStore();
  const { cancelToken, cancelRequest } = useCancelRequest();
  const { pushNotificationPortal } = useNotificationPortalStore();

  const ddAccountsLOVs = useMemo(() => {
    return dataForms?.DialogFormFirstLOVs as DirectDebitAccountLOVs;
  }, [dataForms?.DialogFormFirstLOVs]);

  const getValue = (nameOfField: keyof IDirectDebitAccountFormStepProps) =>
    valueGetter(nameOf(nameOfField));
  const integrationModuleItemType = getValue(
    "Integration_Module_Type_ENUM"
  ) as IntegrationModuleType;
  const onChangeField = (
    nameOfField: keyof IDirectDebitAccountFormStepProps,
    value: any
  ) => {
    onChange(nameOf(nameOfField as keyof IDirectDebitAccountFormStepProps), {
      value: value,
    });
  };

  const getItemTypeLOVsSlider = useFlexibleFetchData({
    alias: CREATE_ACCOUNT_GET_ITEM_TYPE,
    slides: [
      {
        fetch: () => {
          return getDDAccountItemTypeLOVs(
            cancelToken(),
            getValue("Integration_Module_Type_ENUM")
          );
        },
        handleSuccess: ({ dataFromApi }) => {
          const response: APIResponse<IntegrationModuleItemType[]> =
            dataFromApi;
          const ddAccountFormData = response.data;
          //#region save data to store ========/
          setInitialDataForms({
            DialogFormFirstLOVs: {
              ...dataForms?.DialogFormFirstLOVs,
              ItemTypes: getKeyValueFromLOVs(ddAccountFormData),
            },
          });
          //#endregion save data to store =====/
        },
        handleError: ({ errorFromApi }) => {
          if (errorFromApi?.status !== APIResponseStatus.CANCELLED) {
            pushNotificationPortal({
              title: "There is an error when getting Item Types",
              type: "error",
              placeId: DD_CREATE_ACCOUNT,
              autoClose: false,
            });
          }
        },
      },
    ],
  });

  const handleFinanceCustomerChange = (
    event: ComboBoxChangeEvent | undefined
  ) => {
    handleFinanceInvoiceChange(undefined);
    if (!event || !event.target.value) {
      onChangeField("Finance_Customer_No", null);
      onChangeField("Balance", null);
      onChangeField("_options", {
        ...getValue("_options"),
        Finance_Customer_Display: "",
      });
      return;
    }

    const financeCustomer = event.target.value as FinanceCustomer;
    onChangeField("Finance_Customer_No", financeCustomer?.CustomerNo);
    onChangeField("Balance", event?.target?.value?.Balance);
    onChangeField("_options", {
      ...getValue("_options"),
      Finance_Customer_Display: financeCustomer?.SearchText,
      CustomerBalance: financeCustomer?.Balance,
    });
  };

  //TODO refactor this
  const handleFinanceInvoiceChange = (
    event: ComboBoxChangeEvent | undefined
  ) => {
    const customerNo = getValue("Finance_Customer_No");
    if (
      event?.target?.value?.Description &&
      !event?.target?.value?.CustomerNo
    ) {
      onChangeField("_options", {
        ...getValue("_options"),
        Finance_Invoice_Display: event?.target?.value?.Description ?? "",
      });
      return;
    }
    if (!event || !event?.target?.value?.CustomerNo) {
      onChangeField("Finance_Invoice_No", null);
      if (customerNo) {
        onChangeField(
          "Balance",
          getValue("_options")?.CustomerBalance ?? undefined
        );
      }
      onChangeField("_options", {
        ...getValue("_options"),
        Finance_Invoice_Display: "",
      });
      return;
    }
    const financeInvoice = event.target.value as FinanceCustomerLedgerEntries;
    onChangeField("Finance_Customer_No", financeInvoice?.CustomerNo);
    onChangeField("Finance_Invoice_No", financeInvoice?.EntryNo);
    onChangeField("Balance", financeInvoice?.RemainingAmount);
    onChangeField("_options", {
      ...getValue("_options"),
      Finance_Invoice_Display:
        (financeInvoice?.DocumentNo ? financeInvoice?.DocumentNo + " - " : "") +
        (financeInvoice?.Description ?? ""),
    });
  };

  const handlePropertyLookupChange = (
    event: ComboBoxChangeEvent | undefined
  ) => {
    if (!event || !event.value) {
      onChangeField("AssessmentID", null);
      onChangeField("Property_Address", null);
      onChangeField("Assessment_Reference", null);
      onChangeField("Balance", null);
      onChangeField("Assessment_Number", null);
      onChangeField("_options", {
        ...getValue("_options"),
        currentPropertyLookup: null,
      });
      return;
    }
    onChangeField("AssessmentID", event?.value?.Assessment_Id);
    onChangeField("Property_Address", event?.value?.Assess_Property_Address);
    onChangeField("Assessment_Reference", event?.value?.Assess_Reference);
    onChangeField("Balance", event?.value?.Assess_Balance_Outstanding);
    onChangeField("Assessment_Number", event?.value?.Assess_Number);
    onChangeField("_options", {
      ...getValue("_options"),
      currentPropertyLookup: event?.value,
    });
  };

  const handleRegisterLookupChange = (
    event: ComboBoxChangeEvent | undefined
  ) => {
    if (!event || !event.target.value) {
      onChangeField("Register_Account_ID", null);
      onChangeField("Assessment_Reference", null);
      onChangeField("Assessment_Number", null);
      onChangeField("AssessmentID", null);
      // If module type is "Property Register" => clear Item Type => clear balance
      // Reason: Searching Register account ID field based on a value of the Item Type field
      if (
        getValue("Integration_Module_Type_ENUM") ===
        IntegrationModuleType.PropertyRegister
      ) {
        onChangeField("Balance", null);
      }
      onChangeField("Property_Address", null);
      onChangeField("_options", {
        ...getValue("_options"),
        Register_display: "",
        Account_Number: "",
      });
      return;
    }
    onChangeField("Register_Account_ID", event.target.value?.Account_Id);
    onChangeField("Assessment_Reference", event?.value?.Assess_Reference);
    onChangeField("Assessment_Number", event?.value?.Assess_Number);
    onChangeField("AssessmentID", event?.value?.Assessment_Id);
    onChangeField("Balance", event.target.value?.Balance_Outstanding);
    onChangeField("Property_Address", event.target.value?.PropertyAddress);
    onChangeField("_options", {
      ...getValue("_options"),
      Register_display: event.target.value?.Description,
      Account_Number: event.target.value?.Account_Number,
    });
  };

  const handleModuleTypeChange = async (event: ComboBoxChangeEvent | null) => {
    onChangeField("Item_Type", undefined);
    handleRegisterLookupChange(undefined);
    handlePropertyLookupChange(undefined);
    handleFinanceInvoiceChange(undefined);
    handleFinanceCustomerChange(undefined);
    if (!event || !event?.target?.value) {
      onChangeField("Integration_Module_Type_ENUM", null);
      onChangeField("Integration_Module_Type_Name", null);
    } else {
      onChangeField("Integration_Module_Type_ENUM", event.target.value?.Key);
      onChangeField("Integration_Module_Type_Name", event.target.value?.Value);
      // replace fetchItemTypes() with slider fetch when new structure is updated
      await fetchItemTypes();
      //getItemTypeLOVsSlider.fetchApi()
    }
  };

  const handleItemTypeChange = (event: ComboBoxChangeEvent) => {
    if (!event || !event.target.value) {
      onChangeField("Item_Type", null);
      onChangeField("Register_Account_ID", null);
      onChangeField("Register_ID", null);
      handleRegisterLookupChange(undefined);
      return;
    }
    onChangeField("Item_Type", event.target.value?.Key);
    if (integrationModuleItemType === IntegrationModuleType.PropertyRegister) {
      onChangeField("Register_ID", event.target.value?.Register_ID);
      onChangeField("Register_Account_ID", null);
      handleRegisterLookupChange(undefined);
    }
  };

  const fetchItemTypes = async () => {
    setIsFetching(true);
    cancelRequest();
    const response = await getDDAccountItemTypeLOVs(
      cancelToken(),
      getValue("Integration_Module_Type_ENUM")
    );
    if (isSuccessResponse(response) && response?.data) {
      setIsFetching(false);
      setInitialDataForms({
        DialogFormFirstLOVs: {
          ...dataForms?.DialogFormFirstLOVs,
          ItemTypes: getKeyValueFromLOVs(response.data),
        },
      });
    } else if (response?.status !== APIResponseStatus.CANCELLED) {
      setIsFetching(false);
      pushNotificationPortal({
        title: "There is an error when getting Item Types",
        type: "error",
        placeId: DD_CREATE_ACCOUNT,
        autoClose: false,
      });
    } else {
      onChangeField("Item_Type", undefined);
      handleRegisterLookupChange(undefined);
      handlePropertyLookupChange(undefined);
      handleFinanceInvoiceChange(undefined);
      handleFinanceCustomerChange(undefined);
      setInitialDataForms({
        DialogFormFirstLOVs: {
          ...dataForms?.DialogFormFirstLOVs,
          ItemTypes: [],
        },
      });
    }
  };
  useEffectOnce(() => {
    if (
      !isNil(getValue("Integration_Module_Type_ENUM")) &&
      isEmpty(ddAccountsLOVs?.ItemTypes)
    ) {
      fetchApiByAlias(CREATE_ACCOUNT_GET_ITEM_TYPE);
    }
  });

  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-2">
        <div className="cc-field">
          <CCLabel title="Module type" isMandatory />
          <Field
            name={nameOf("Integration_Module_Type_ENUM")}
            dataItemKey="Key"
            textField="Value"
            data={ddAccountsLOVs?.IntegrationModuleTypes ?? []}
            value={getDropdownValue(
              integrationModuleItemType,
              ddAccountsLOVs?.IntegrationModuleTypes ?? []
            )}
            validator={requiredValidator}
            onChange={handleModuleTypeChange}
            component={CCSearchComboBox}
          />
        </div>
        <div className="cc-field">
          <CCLabel title={"Item type"} isMandatory />
          <Field
            name={nameOf("Item_Type")}
            dataItemKey="Key"
            textField="Value"
            data={ddAccountsLOVs?.ItemTypes ?? []}
            value={getDropdownValue(
              getValue("Item_Type"),
              ddAccountsLOVs?.ItemTypes ?? []
            )}
            validator={requiredValidator}
            onChange={handleItemTypeChange}
            component={CCSearchComboBox}
            disabled={
              getItemTypeLOVsSlider.isFetching ||
              !getValue("Integration_Module_Type_ENUM") ||
              isFetching
            }
            isLoading={getItemTypeLOVsSlider.isFetching || isFetching}
          />
        </div>
      </div>
      {integrationModuleItemType ===
        IntegrationModuleType.PropertyAssessment && (
        <>
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <CCLabel title="Property lookup" isMandatory />
              <Field
                name={nameOf("AssessmentID")}
                component={PropertyLookupPicker}
                value={getValue("_options").currentPropertyLookup ?? null}
                urlAPI={ASSESSMENT_LOOKUP_API}
                keySearch="searchString"
                dataItemKey={nameOfAssess("Assessment_Id")}
                textField={nameOfAssess("Assess_Number")}
                isFilterable={false}
                onChange={handlePropertyLookupChange}
                validator={requiredValidator}
                allowCustom
              />
            </div>
          </div>
          {nameOf("AssessmentID") && (
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCValueField
                  value={
                    getValue("_options").currentPropertyLookup
                      ?.Ratepayer_Name ?? ""
                  }
                  label="Ratepayer name"
                />
              </div>
              <div className="cc-field">
                <CCValueField
                  value={getValue("Assessment_Reference")}
                  label="Assessment reference"
                />
              </div>
              <div className="cc-field">
                <CCValueField
                  value={getValue("Property_Address")}
                  label="Property address"
                />
              </div>
            </div>
          )}
        </>
      )}
      {integrationModuleItemType === IntegrationModuleType.PropertyRegister && (
        <>
          <div className="cc-form-cols-2">
            <div className="cc-field">
              <CCLabel title={"Register account ID"} isMandatory />
              <Field
                name={nameOf("Register_Account_ID")}
                component={ComboboxDDSearchAPI}
                dataItemKey="Account_Id"
                textField="Description"
                value={getValue("_options")?.Register_display ?? ""}
                urlAPI={REGISTER_LOOKUP_API(getValue("Register_ID"))}
                keySearch="searchString"
                isFilterable={false}
                onChange={handleRegisterLookupChange}
                validator={requiredValidator}
                disabled={!getValue("Register_ID")}
                onError={(error: APIResponse<ResponsePacket>) => {
                  pushNotificationPortal({
                    title:
                      error?.data?.Errors?.length > 0
                        ? error?.data?.Errors
                        : "There is an error when searching register account",
                    type: "error",
                    placeId: DD_CREATE_ACCOUNT,
                  });
                }}
                allowCustom
              />
            </div>
          </div>
          {getValue("Register_Account_ID") && (
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCValueField
                  value={getValue("_options")?.Account_Number ?? ""}
                  label="Account number"
                />
              </div>
              <div className="cc-field">
                <CCValueField
                  value={getValue("Assessment_Number")}
                  label="Assessment number"
                />
              </div>
              <div className="cc-field">
                <CCValueField
                  value={getValue("Property_Address")}
                  label="Property address"
                />
              </div>
            </div>
          )}
        </>
      )}
      {integrationModuleItemType === IntegrationModuleType.CommunityFinance && (
        <div className="cc-form-cols-2">
          <div className="cc-field">
            <CCLabel title="Finance customer number" isMandatory />
            <Field
              name={nameOf("Finance_Customer_No")}
              placeholder={"Finance customer number"}
              component={ComboboxDDSearchAPI}
              urlAPI={FINANCE_CUSTOMER_LOOKUP_API}
              keySearch="searchText"
              dataItemKey="Finance_Customer_No"
              textField="SearchText"
              isFilterable={false}
              value={getValue("_options")?.Finance_Customer_Display ?? ""}
              validator={requiredValidator}
              onChange={handleFinanceCustomerChange}
              onError={(error: APIResponse<ResponsePacket>) => {
                pushNotificationPortal({
                  title:
                    error?.data?.Errors?.length > 0
                      ? error?.data?.Errors
                      : "There is an error when searching finance customer number",
                  type: "error",
                  placeId: DD_CREATE_ACCOUNT,
                });
              }}
              allowCustom
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Finance invoice number" />
            <Field
              name={nameOf("Finance_Invoice_No")}
              placeholder={"Finance invoice number"}
              component={ComboboxDDSearchAPI}
              dataItemKey="EntryNo"
              textField="Description"
              keySearch="searchString"
              isFilterable={false}
              urlAPI={FINANCE_INVOICE_LOOKUP_API(
                getValue("Finance_Customer_No")
              )}
              value={getValue("_options")?.Finance_Invoice_Display ?? ""}
              disabled={!getValue("Finance_Customer_No")}
              onChange={handleFinanceInvoiceChange}
              onError={(error: APIResponse<ResponsePacket>) => {
                pushNotificationPortal({
                  title:
                    error?.data?.Errors?.length > 0
                      ? error?.data?.Errors
                      : "There is an error when searching finance invoice",
                  type: "error",
                  placeId: DD_CREATE_ACCOUNT,
                });
              }}
              allowCustom
            />
          </div>
        </div>
      )}
      {!isNil(getValue("Balance")) && (
        <div className="cc-field">
          <CCValueField
            value={getValue("Balance")}
            label="Balance"
            format={CURRENCY_FORMAT.CURRENCY1}
          />
        </div>
      )}
    </section>
  );
});
