import { Svc_Contact } from "@app/core/contacts/_id/model";
import { KeyValuePacket } from "@app/products/crms/components/dialogs/clone-new-event/model";
import {
  IntegrationModuleType,
  PaymentFrequency,
} from "@app/products/direct-debit/model";
import { DTOBaseClass_Standard } from "@common/models/baseClassStandard";

export interface DirectDebitAccount extends DTOBaseClass_Standard {
  Account_ID: number;
  Account_Number: string;
  Authorisation_Id: number | null;
  Authorisation_Number: string;
  Register_ID: number | null;
  State: string;
  Direct_Debit_Group_ID: number | null;
  Direct_Debit_Group_Name: string;
  Payment_Frequency_ENUM: PaymentFrequency;
  Payment_Frequency_Name: string;
  Integration_Module_Type_ENUM: IntegrationModuleType;
  Integration_Module_Type_Name: string;
  Contact_ID: number | null;
  Register_Account_ID: number | null;
  AssessmentID: number | null;
  Assessment_Number: string;
  Assessment_Reference: string;
  Name: string;
  Name_2: string;
  Address: string;
  Address_2: string;
  Locality: string;
  Post_Code: string;
  Finance_Customer_No: string;
  Finance_Invoice_No: string;
  Balance: number | null;
  Balance_Date: Date | null;
  Manual_Description: string;
  Amount: number | null;
  Start_Date: Date | null;
  End_Date: Date | null;
  Next_Transfer_Date: Date | null;
  AccountStatus_ENUM: DirectDebitAccountStatus;
  AccountStatus_Name: string;
  Balance_Only: boolean;
  Item_Type: number | null;
  Stop_When_Balance_Zero: boolean | null;
  Account_Reference: string;
  Transfer_Amount: number | null;
  Instalment_Only: boolean;
  Instalment: number | null;
  Instalment_Date: Date | null;
  Property_Address: string;
  Core_Contact_VIP: boolean | null;
  Core_Contact_VIP_Type: string;
  Core_Contact_VIP_TypeID: number | null;
  Core_Contact_VIP_RelationshipOwnerID: number | null;
  Core_Contact_DisplayName: string;
  Core_Contact_Email: string;
  Core_Contact_HomePhone: string;
  Core_Contact_WorkPhone: string;
  Core_Contact_Mobile: string;
  Direct_Debit_Group_Number: string;
  Integration_Module_Item_Type_Number: string;
  Integration_Module_Item_Type_Name: string;
}

export enum DirectDebitAccountStatus {
  Unspecified = 0,
  Active = 1,
  Suspended = 2,
  Terminated = 3,
  Cancelled = 4,
  Archived = 5,
  CancelledbyChangeofOwnership = 6,
}

export enum DirectDebitAccountStatusFilter {
  "Active" = "Active",
  "Suspended" = "Suspended",
  "Terminated" = "Terminated",
  "Cancelled" = "Cancelled",
  "Archived" = "Archived",
  "Cancelled by Change of Ownership" = "Cancelled by Change of Ownership",
}

export interface ItemTypeKeyValuePacket extends KeyValuePacket {
  Register_ID: number | null;
}

export interface DirectDebitAccountLOVs {
  IntegrationModuleTypes: KeyValuePacket[];
  PaymentFrequencies: KeyValuePacket[];
  ItemTypes: ItemTypeKeyValuePacket[];
  IncludeGroups: KeyValuePacket[];
  AuthorisationsList: KeyValuePacket[];
}

export interface IntegrationModuleItemType extends DTOBaseClass_Standard {
  Integration_Module_Item_Type_ID: number;
  Integration_Module_Item_Type_Number: string;
  Integration_Module_Type_ENUM: IntegrationModuleType;
  Integration_Module_Type_Name: string;
  Register_ID: number | null;
  Name: string;
  Item_Type_ID: number | null;
}

export interface Svc_Contact_With_ID extends Svc_Contact {
  Contact_ID?: number;
}

export interface FinanceCustomer extends DTOBaseClass_Standard {
  CustomerNo: string;
  Name: string;
  Name2: string;
  Address: string;
  Address2: string;
  City: string;
  State: string;
  PostCode: string;
  CustomerPostingGroup: string;
  Blocked: string;
  Abn: string;
  Balance: number | null;
  Email: string;
  PhoneNo: string;
  LastPaymentDate: Date | null;
  SearchText: string;
  WebClientURL: string;
}

export interface PropertyRegister {
  Register_ID: number;
  Register_Account_ID?: number;
  Overdue?: number;
  Due?: number;
  Pending?: number;
  Register_Balance_Outstanding?: number;
  Property_Name_Address_Locality: string;
  Register_Property_Address: string;
  RatePayerName: string;
}

export interface FinanceCustomerLedgerEntries extends DTOBaseClass_Standard {
  EntryNo: number;
  CustomerNo: string;
  PostingDate: Date | null;
  DocumentType: string;
  DocumentNo: string;
  ExternalDocumentNo: string;
  Description: string;
  DueDate: Date | null;
  OriginalAmount: number | null;
  RemainingAmount: number | null;
  Open: boolean | null;
  Positive: boolean | null;
  BpayReferenceNo: string;
  BpayiCRN: string;
  FeeId: number | null;
  FeeTypeId: number | null;
  InvoiceId: number | null;
  WebClientURL: string;
}
export interface ManageDirectDebitAccountProps extends DirectDebitAccount {
  CCFormOptions: DirectDebitAccountFormOptions;
}

export interface DirectDebitAccountFormOptions {
  contact: Svc_Contact;
  initialContact: Svc_Contact;
  initialName: string;
}
export type UsedDirectDebitAccountStatus = Exclude<
  DirectDebitAccountStatus,
  | DirectDebitAccountStatus.Unspecified
  | DirectDebitAccountStatus.CancelledbyChangeofOwnership
  | DirectDebitAccountStatus.Archived
>;
export type ButtonStates = Record<UsedDirectDebitAccountStatus, boolean>;
