import { getPermitTypeFormById } from "@app/products/local-laws/system-admin/permit-types/[id]/api";
import {
  IManagePermitType,
  PermitType,
  TApiPermitType,
  TApiPermitTypeResponse,
} from "@app/products/local-laws/system-admin/permit-types/[id]/model";
import {
  TErrorComponent,
  useCallApiHandleLoadFailed,
} from "@common/hooks/useCallApiHandleLoadFailed";
import { useCancelRequest } from "@common/hooks/useCancelRequest";
import { useIsNew } from "@common/hooks/useIsNew";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import Loading from "@components/loading/Loading";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ExistManagePermitType } from "./existed/_index";
import { NewManagePermitTypeComponent } from "./new/_index";

const ManagePermitType = () => {
  //#region STORE ========/
  const { setInitialDataForms, isLoadingForm } = useFlexibleFormStore();
  //#endregion STORE =====/

  //#region STATE ========/
  const [permitType, setPermitType] = useState<PermitType>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  //#endregion STATE =====/

  //#region HOOK ========/
  const { cancelToken } = useCancelRequest();
  const { listenNotificationPortal } = useNotificationPortalStore();
  const isNew = useIsNew();
  //#endregion HOOK =====/

  //#region Variable ========/
  const titleManagePage = "Permit type";
  let errorComponent: TErrorComponent = null;
  let { id } = useParams<{ id: string }>();
  if (isNew) id = "0";
  //#endregion Variable =====/

  useEffect(() => {
    if (!isLoading && !isLoadingForm) listenNotificationPortal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isLoadingForm]);

  errorComponent = useCallApiHandleLoadFailed({
    isActive: !isNil(id),
    setIsLoading,
    api: async () => getPermitTypeFormById(+id, cancelToken()),
    watch: [id],
    handler: async (
      response: [
        TApiPermitTypeResponse["Lovs"],
        TApiPermitTypeResponse["Value"]
      ]
    ) => {
      const [_permitTypeLOVs, _permitTypeIdentityPacket] = response;
      const _permitType = _permitTypeIdentityPacket.data?.ReturnObj;
      setPermitType(_permitType);

      //#region save data to store ========/
      setInitialDataForms({
        GeneralForm: _permitType,
      });
      setInitialDataForms({
        GeneralFormLovs: _permitTypeLOVs.data as TApiPermitType["Lovs"],
      });
      //#endregion save data to store =====/
    },
  });

  //#region PROPS ========/
  const props: IManagePermitType = {
    titleManagePage,
    id,
    errorComponent,
    permitType,
  };
  //#endregion PROPS =====/

  return (
    <>
      <Loading isLoading={isLoading || isLoadingForm} isFullScreen />
      {isNew ? (
        <NewManagePermitTypeComponent {...props} />
      ) : (
        <ExistManagePermitType {...props} />
      )}
    </>
  );
};

export default observer(ManagePermitType);
