import { Comments } from "@app/core/comments/_index";
import { ITabProps, TabTable } from "@app/core/tab-table/_index";
import { TabTableType } from "@app/core/tab-table/model";
import { FormAction, FormIdentifier } from "@app/products/crms/model";
import { DeleteDirectDebitBankAccountButton } from "@app/products/direct-debit/system-admin/bank-accounts/[id]/components/buttons/delete/_index";
import { SaveButtonDirectDebitBankAccount } from "@app/products/direct-debit/system-admin/bank-accounts/[id]/components/buttons/save/_index";
import { DirectDebitBankAccountForm } from "@app/products/direct-debit/system-admin/bank-accounts/[id]/components/general/_index";
import { SysAdminBankAccountHistoryTab } from "@app/products/direct-debit/system-admin/bank-accounts/[id]/components/slidebar/history/_index";
import { DirectDebitBankAccount } from "@app/products/direct-debit/system-admin/model";
import { generateBadgeDBRowState } from "@app/products/direct-debit/system-admin/status-codes/[id]/util";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useCheckPermissions } from "@common/hooks/permissions/useCheckPermissions";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { useGlobalStore } from "@common/stores/global/store";
import { FormTitle } from "@components/cc-form/title/_index";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { YieldNotificationPortal } from "@components/cc-notification-portal/_index";
import { TypePlaceId } from "@components/cc-notification-portal/constant";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";

export const ExistManageDirectDebitBankAccount = observer(() => {
  const { currentUserInfo } = useGlobalStore();
  const { dataForms } = useFlexibleFormStore();
  const directDebitBankAccount =
    dataForms?.GeneralForm as DirectDebitBankAccount;

  //#region HOOK ========/
  const location = useLocation();
  const [showSlideBar, setShowSlideBar] = useState(true);
  //#endregion HOOK =====/

  const { checkPermissions } = useCheckPermissions({
    formIdentifier: FormIdentifier.DirectDebit_Dialog_BankAccount,
    productType: PRODUCT_TYPE_NUMBER.DirectDebit,
  });

  const bookmarkList: IBookmarkItem[] = [
    {
      LinkText: `Direct Debit - Bank Account - ${directDebitBankAccount?.Direct_Debit_Bank_Account_Id}`,
      Text: `Direct Debit - System Admin`,
      LinkUrl: location.pathname,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      ProductType_ENUM: PRODUCT_TYPE.DirectDebit,
      RecordType_ENUM: RECORDTYPE.Receipting_Direct_Debit_Bank_Account,
      Record_ID: directDebitBankAccount?.Direct_Debit_Bank_Account_Id,
    },
  ];

  const listPanelBar: ITabProps[] = [
    {
      title: "Comments",
      tabType: TabTableType.Comments,
      component: (
        <Comments
          id={directDebitBankAccount?.Direct_Debit_Bank_Account_Id}
          recordType={RECORDTYPE.CORE_Contact}
        />
      ),
    },
  ];

  return (
    <>
      <FormTitle
        title={directDebitBankAccount?.Description ?? ""}
        badge={
          directDebitBankAccount &&
          generateBadgeDBRowState(directDebitBankAccount?.Sys_DBRowState)
        }
      />
      <CCManagePageActionBar
        leftActions={[
          <SaveButtonDirectDebitBankAccount
            disabled={!checkPermissions(FormAction.CORE_ALLOW_EDIT)}
          />,
          <DeleteDirectDebitBankAccountButton
            disabled={!checkPermissions(FormAction.CORE_ALLOW_DELETE)}
          />,
        ]}
        rightActions={[
          <CCNavButton
            title={"Slide Bar"}
            iconClass="fal fa-info-circle"
            isActive={showSlideBar}
            onClick={() => {
              setShowSlideBar(!showSlideBar);
            }}
          />,
          <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
        ]}
      />
      <div className="cc-manage-container">
        <div className="cc-manage-left">
          <YieldNotificationPortal placeId={TypePlaceId.Main} />
          <div className="cc-manage-form-body">
            {directDebitBankAccount && <DirectDebitBankAccountForm />}
            <TabTable
              initialPanels={listPanelBar}
              recordType={RECORDTYPE.CORE_Contact}
              id={directDebitBankAccount?.Direct_Debit_Bank_Account_Id}
            />
          </div>
        </div>
        {showSlideBar && (
          <div className="cc-manage-right">
            <ReferenceSideBar
              components={[
                {
                  title: "History",
                  component: <SysAdminBankAccountHistoryTab />,
                },
              ]}
            />
          </div>
        )}
      </div>
    </>
  );
});
