import { eventEmitter } from "@/App";
import { ConfirmDelete } from "@app/core/delete/dialogs/_index";
import { DBRowState } from "@app/products/crms/[id]/model";
import {
  DirectDebitStatusCode,
  DirectDebitStatusCodeView,
} from "@app/products/direct-debit/system-admin/model";
import { Svc_RecordDetails } from "@app/products/town-planning/ppr/permit-referrals/components/action-bar/buttons/delete/model";
import { RECORDTYPE } from "@common/constants/recordtype";
import {
  fetchApiByAlias,
  useFlexibleFetchData,
} from "@common/hooks/flexible-fetch-data/useFlexibleFetchData";
import { usePageType } from "@common/hooks/usePageType";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { CCGridEventType } from "@components/cc-grid/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";
import { deleteStatusCode } from "./api";

export const DeleteDirectDebitStatusCodeButton = observer(
  ({ disabled }: { disabled?: boolean }) => {
    //Store
    const { gridSelectedRows, setGridSelectedRows, setGridSelectedIds } =
      useCCProductListViewStore();
    const { pushNotificationPortal } = useNotificationPortalStore();

    //Hooks
    const { dataForms } = useFlexibleFormStore();
    const { isProductListView } = usePageType();
    const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] =
      useState<boolean>(false);

    const [isDeleting, setIsDeleting] = useState<boolean>(false);
    const statusCodesFromStore =
      dataForms?.GeneralForm as DirectDebitStatusCode;

    const deleteDDStatusCodeSlider = useFlexibleFetchData({
      slides: [
        {
          fetch: ({ initialData }) => {
            const recordDetails: Svc_RecordDetails[] = initialData;
            return deleteStatusCode(recordDetails);
          },
          handleSuccess: async () => {
            if (isProductListView) {
              eventEmitter.emit(CCGridEventType.RefreshOData);
              setGridSelectedRows([]);
              setGridSelectedIds([]);
              pushNotificationPortal({
                title: "Record(s) deleted successfully.",
                type: "success",
              });
            } else {
              setShowConfirmDeleteDialog(false);
              await fetchApiByAlias("manageDirectDebitStatusCodeSlider");
              pushNotificationPortal({
                title: "Record(s) deleted successfully.",
                type: "success",
              });
            }
          },
          handleError: ({ errorFromApi }) => {
            pushNotificationPortal({
              title: "Delete record(s) failed.",
              description: errorFromApi.error,
              type: "error",
              autoClose: false,
            });
          },
        },
      ],
    });

    const handleOnSubmit = async () => {
      setIsDeleting(true);
      let payload: Svc_RecordDetails[] = [];
      if (isProductListView) {
        const selectedRow: DirectDebitStatusCodeView[] =
          gridSelectedRows.filter(
            (row) => row.Sys_DBRowState === DBRowState.Active
          );
        payload = selectedRow.map((row) => ({
          ID: row.Direct_Debit_Status_Code_ID,
          RecordType_ENUM: RECORDTYPE.Receipting_DirectDebit_Status_Code,
        }));
      } else {
        payload = [
          {
            ID: statusCodesFromStore.Direct_Debit_Status_Code_ID,
            RecordType_ENUM: RECORDTYPE.Receipting_DirectDebit_Status_Code,
          },
        ];
      }
      await deleteDDStatusCodeSlider.fetchApi({ initialData: payload });
      setShowConfirmDeleteDialog(false);
      setIsDeleting(false);
    };

    const isDisabled = useMemo(() => {
      if (disabled === true) return disabled;
      if (isProductListView) {
        return (
          !gridSelectedRows?.length ||
          gridSelectedRows.every(
            (row) => row.Sys_DBRowState === DBRowState.Inactive
          )
        );
      } else {
        return statusCodesFromStore?.Sys_DBRowState === DBRowState.Inactive;
      }
    }, [
      gridSelectedRows,
      isProductListView,
      statusCodesFromStore?.Sys_DBRowState,
      disabled,
    ]);

    return (
      <>
        <CCNavButton
          title="Delete"
          disabled={isDisabled}
          isLoading={isDeleting}
          onClick={() => {
            setShowConfirmDeleteDialog(true);
          }}
        />
        {showConfirmDeleteDialog && (
          <ConfirmDelete
            onClose={() => setShowConfirmDeleteDialog(false)}
            handleSubmit={handleOnSubmit}
            isDeleting={isDeleting}
            header={"Confirm Deletion"}
          />
        )}
      </>
    );
  }
);
