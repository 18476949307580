import { DD_CREATE_ACCOUNT } from "@app/products/direct-debit/accounts/components/dialogs/create-account/constant";
import {
  PropertyLookupPickerMessage,
  colDDAssessmentLookup,
  propertyLookupPickerStatuses,
} from "@app/products/direct-debit/accounts/components/fields/property-lookup-field/config";
import { AddAssessmentLookupDialog } from "@app/products/property/components/dialogs/add-assessment-lookup/_index";
import { getSearchAssessmentLookup } from "@app/products/property/components/dialogs/add-assessment-lookup/api";
import { eOptionSearchAssessmentLookup } from "@app/products/property/components/dialogs/add-assessment-lookup/config";
import {
  DTO_Assessment,
  fnt_Assessment_LookupResult,
} from "@app/products/property/components/dialogs/add-assessment-lookup/model";
import { isSuccessResponse } from "@common/apis/util";
import { useDebounce } from "@common/hooks/useDebounce";
import { sanitizeHtml } from "@common/utils/sanitized-parser";
import { CCComboBox } from "@components/cc-combo-box/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { ICCInputPickerProps } from "@components/cc-input-picker/_index";
import { isHTML } from "@components/cc-input-picker/util";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import { Button } from "@progress/kendo-react-buttons";
import {
  ComboBoxChangeEvent,
  ComboBoxFilterChangeEvent,
  ListItemProps,
} from "@progress/kendo-react-dropdowns";
import { Error } from "@progress/kendo-react-labels";
import axios, { CancelTokenSource } from "axios";
import { isArray, isNull, isUndefined } from "lodash";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "./_index.scss";

export const PropertyLookupPicker = (props: ICCInputPickerProps) => {
  const {
    validationMessage,
    visited,
    className,
    value,
    textField,
    textProduce,
    valueField,
    valueProduce,
    onInputChange,
    onChange,
    disabled,
    customDialog,
    onButtonClick,
    isLoading,
    onLoadData,
    showClearButton,
    ...others
  } = props;

  const cancelRequest = useRef<CancelTokenSource>();
  const [searchKey, setSearchKey] = useState("");
  const [resultsSearch, setResultsSearch] = useState<DTO_Assessment[]>([]);
  const { pushNotificationPortal } = useNotificationPortalStore();
  const [isSearching, setIsSearching] = useState(false);
  const [isLoadingLookup, setIsLoadingLookup] = useState(false);
  const debouncedSearch = useDebounce(searchKey, 500);

  const [showDialog, setShowDialog] = useState(false);

  const inputValue = useMemo(() => {
    if (isUndefined(value) || isNull(value)) return "";
    if (textProduce) return textProduce(value);
    if (textField && !isArray(value) && textField in value)
      return value[textField];
    return value ?? "";
  }, [value, textField, textProduce]);

  const handleOnChange = useCallback(
    (value: any) => {
      setShowDialog(false);
      if (!onChange) return;
      if (valueProduce) return onChange({ value: valueProduce(value) });

      if (valueField && !isArray(value) && valueField in value)
        return onChange({ value: value[valueField] });

      return onChange({ value });
    },
    [onChange, valueField, valueProduce]
  );
  const handleOnClickButton = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    if (onButtonClick) return onButtonClick(event);
    setSearchKey("");
    setShowDialog(true);
    if (onLoadData) return onLoadData();
  };
  const handleAddAssessments = async (
    assessmentList: fnt_Assessment_LookupResult[]
  ) => {
    handleOnChange(null);
    setShowDialog(false);
    setIsLoadingLookup(true);
    const listAssessmentId = assessmentList.map((item) => item.Assessment_Id);
    const response = await getSearchAssessmentLookup({
      LookupKey: eOptionSearchAssessmentLookup.AssessmentId,
      LookupValue: listAssessmentId.toString(),
      Statuses: [0], //Fixed now (0: Active)
    });
    if (isSuccessResponse(response) && response?.data) {
      const resAssessment = response?.data?.Assessments ?? [];
      if (resAssessment?.length) {
        handleOnChange(resAssessment?.[0]);
      }
    } else {
      pushNotificationPortal({
        title: `Load property lookup failed`,
        type: "error",
        autoClose: false,
        placeId: DD_CREATE_ACCOUNT,
      });
    }
    setIsLoadingLookup(false);
  };

  useEffect(() => {
    (async () => {
      if (debouncedSearch) {
        setResultsSearch([]);
        cancelRequest.current?.cancel(PropertyLookupPickerMessage);
        cancelRequest.current = axios.CancelToken.source();
        setIsSearching(true);
        const response = await getSearchAssessmentLookup(
          {
            LookupKey: eOptionSearchAssessmentLookup.Name,
            LookupValue: debouncedSearch,
            // use default Statuses
            Statuses: propertyLookupPickerStatuses,
          },
          cancelRequest.current
        );

        if (isSuccessResponse(response) && response.data) {
          setIsSearching(false);
          setResultsSearch(response.data.Assessments ?? []);
        } else if (response.error !== PropertyLookupPickerMessage) {
          setIsSearching(false);
          pushNotificationPortal({
            title: response.error ?? `Property lookup search failed`,
            type: "error",
            autoClose: false,
            placeId: DD_CREATE_ACCOUNT,
          });
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch]);

  const ItemRender = (
    li: ReactElement<HTMLLIElement>,
    itemProps: ListItemProps
  ) => {
    const { dataItem } = itemProps;
    const itemChildren = (
      <div className="cc-search-item">
        {colDDAssessmentLookup.map((col: IColumnFields) => (
          <div key={col.field} style={{ width: col.width }}>
            {sanitizeHtml(dataItem[col.field] ?? "")}
          </div>
        ))}
      </div>
    );
    return React.cloneElement(li, li.props, itemChildren);
  };

  const handleSearch = (event: ComboBoxFilterChangeEvent) => {
    const searchText = event.filter.value;
    setSearchKey(searchText);
  };
  return (
    <>
      <div
        className={`${className ?? ""} cc-input-picker-new ${
          !others.valid ? "cc-input-picker-invalid" : ""
        }`}
      >
        {isHTML(inputValue) ? (
          <div className="cc-input-picker-html k-textbox">
            {sanitizeHtml(inputValue)}
          </div>
        ) : (
          <CCComboBox
            disabled={disabled || isLoadingLookup}
            required={!others.valid}
            filterable
            suggest
            data={resultsSearch ?? []}
            dataItemKey={textField}
            textField={textField}
            loading={isSearching || isLoadingLookup}
            value={value}
            onFilterChange={handleSearch}
            itemRender={ItemRender}
            header={
              <div className="cc-search-header">
                {colDDAssessmentLookup.map((col: IColumnFields) => (
                  <div key={col.field} style={{ width: col.width }}>
                    {col.title}
                  </div>
                ))}
              </div>
            }
            onChange={(e: ComboBoxChangeEvent) => {
              if (e.nativeEvent.type === "blur") return;
              if (e.value) {
                handleOnChange(e.value);
              } else {
                handleOnChange(undefined);
              }
            }}
            popupSettings={{ className: "cc-contact-picker-search" }}
          />
        )}
        <Button
          disabled={disabled}
          className="cc-input-picker-button"
          iconClass="fa fa-ellipsis-h"
          onClick={handleOnClickButton}
        />
      </div>
      {visited && validationMessage && <Error>{validationMessage}</Error>}
      {showDialog && (
        <AddAssessmentLookupDialog
          onClose={() => setShowDialog(false)}
          handleAddAssessment={handleAddAssessments}
          customTitle="Property Lookup"
          isUseDefaultLabel
        />
      )}
    </>
  );
};
